import React, { forwardRef } from 'react'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
    100% {
        transform: rotate(360deg);
    }
`

const ReffedSpinner = forwardRef(function BareSpinner(
    { r = 6, strokeWidth = 2, strokeColor = '#59799C', ...props },
    ref,
) {
    const d = 2 * Math.PI * r

    const size = (r + strokeWidth) * 2

    return (
        <div
            {...props}
            ref={ref}
            style={{
                width: `${size}px`,
                height: `${size}px`,
            }}
        >
            <Svg viewBox={`0 0 ${size} ${size}`}>
                <circle
                    cx={size / 2}
                    cy={size / 2}
                    r={r}
                    fill="none"
                    strokeWidth={strokeWidth}
                    stroke={strokeColor}
                    strokeLinecap="round"
                    strokeDasharray={`${0.6 * d}, ${0.4 * d}`}
                    strokeDashoffset="0"
                />
            </Svg>
        </div>
    )
})

const Spinner = styled(ReffedSpinner)``

const Svg = styled.svg`
    animation: ${rotate} 1s linear infinite;
`

export default Spinner

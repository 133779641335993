import { Color, ShaderMaterial, Vector3 } from 'three'
import MaskShader from '../shaders/MaskShader'

// eslint-disable-next-line import/no-unused-modules
export default function Mask({ fsQuad, renderer }) {
    const uniforms = MaskShader.getUniforms()

    const material = new ShaderMaterial({
        vertexShader: MaskShader.vertexShader,
        fragmentShader: MaskShader.fragmentShader,
        uniforms,
        transparent: true,
    })

    this.setMaskColor = (color) => {
        const { r, g, b } = new Color(color)
        uniforms.u_color.value = new Vector3(r, g, b)
    }

    this.setMaskColor('#091331')

    this.setBackdropColor = (color) => {
        const { r, g, b } = new Color(color)
        uniforms.u_backdrop_color.value = new Vector3(r, g, b)
    }

    this.setBackdropColor('#091331')

    this.setBackdropIntensity = (intensity) => {
        uniforms.u_backdrop_intensity.value = intensity
    }

    this.setBackdropIntensity(0.1)

    this.setScreenSize = (newSize) => {
        uniforms.u_resolution.value = newSize
    }

    this.render = (phase, { coverage = 0.8, curvature = 0.12 } = {}) => {
        const { x, y } = uniforms.u_resolution.value

        uniforms.u_offset.value = phase * coverage * y

        uniforms.u_height.value = phase * curvature * x * 0.5

        uniforms.u_phase.value = phase

        fsQuad.material = material

        fsQuad.render(renderer)
    }
}

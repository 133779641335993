import { Color, Vector2 } from 'three'
import { FullScreenQuad } from 'three-stdlib/postprocessing/Pass'
import Ids from './Ids'
import Mask from './materials/Mask'
import Gradients from './materials/Gradients'
import Dots from './materials/Dots'
import SpotLight from './materials/SpotLight'
import Coverall from './materials/Coverall'

// eslint-disable-next-line import/no-unused-modules
export default function Cloak(renderer) {
    const fsQuad = new FullScreenQuad()

    let screenSize = new Vector2(0, 0)

    const mask = new Mask({
        fsQuad,
        renderer,
    })

    const gradients = new Gradients({
        fsQuad,
        renderer,
    })

    const dots = new Dots({
        fsQuad,
        renderer,
    })

    const spotlight = new SpotLight({
        fsQuad,
        renderer,
    })

    const coverall = new Coverall({
        fsQuad,
        renderer,
    })

    const maskConfig = {
        coverage: 0.8,
        curvature: 0.12,
    }

    const spotlight1 = {
        enabled: true,
        x0: 0,
        y0: 0,
        x1: 0,
        y1: 0,
        color: '#0ea5ff',
        intensity0: 1,
        intensity1: 0.5,
        radius0: 1,
        radius1: 0,
    }

    const spotlight2 = {
        enabled: true,
        x0: 0,
        y0: 0,
        x1: 0,
        y1: 0,
        color: '#0ea5ff',
        intensity0: 1,
        intensity1: 0.5,
        radius0: 1,
        radius1: 0,
    }

    const spotlight3 = {
        enabled: true,
        x0: 0,
        y0: 0,
        x1: 0,
        y1: 0,
        color: '#0ea5ff',
        intensity0: 1,
        intensity1: 0.5,
        radius0: 1,
        radius1: 0,
    }

    const spotlights = [spotlight1, spotlight2, spotlight3]

    this.uncover = () => {
        coverall.fade()
    }

    this.render = (phase, time = 0) => {
        if (phase > 0) {
            mask.render(phase, maskConfig)
            gradients.render(phase, maskConfig)
        }

        spotlights.forEach((sl) => {
            if (!sl.enabled) {
                return
            }

            spotlight.setColor(sl.color)
            spotlight.render({
                x: sl.x1 + (sl.x0 - sl.x1) * phase,
                y: sl.y1 + (sl.y0 - sl.y1) * phase,
                opacity: sl.intensity1 + (sl.intensity0 - sl.intensity1) * phase,
                radius: (sl.radius1 + (sl.radius0 - sl.radius1) * phase) * screenSize.x,
            })
        })

        if (phase > 0) {
            dots.render(phase, time, maskConfig)
        }

        coverall.render(0)
    }

    this.setParam = (key, value) => {
        switch (key) {
            case Ids.MASK_COLOR:
                mask.setMaskColor(value)
                break
            case Ids.MASKBACKDROP_COLOR:
                mask.setBackdropColor(value)
                break
            case Ids.MASKBACKDROP_INTENSITY:
                mask.setBackdropIntensity(value)
                break
            case Ids.OUTERGLOW_COLOR:
                gradients.setOuterColor(value)
                break
            case Ids.INNERGLOW_COLOR:
                gradients.setInnerColor(value)
                break
            case Ids.OUTERGLOW_WIDTH:
                gradients.setOuterWidth(value)
                break
            case Ids.INNERGLOW_WIDTH:
                gradients.setInnerWidth(value)
                break
            case Ids.SPOTLIGHT1_COLOR:
                spotlight1.color = value
                break
            case Ids.SPOTLIGHT1_ENABLE:
                spotlight1.enabled = value
                break
            case Ids.SPOTLIGHT1_INTENSITYA:
                spotlight1.intensity0 = value
                break
            case Ids.SPOTLIGHT1_INTENSITYB:
                spotlight1.intensity1 = value
                break
            case Ids.SPOTLIGHT1_RADIUSA:
                spotlight1.radius0 = value
                break
            case Ids.SPOTLIGHT1_RADIUSB:
                spotlight1.radius1 = value
                break
            case Ids.SPOTLIGHT1_XA:
                spotlight1.x0 = value
                break
            case Ids.SPOTLIGHT1_XB:
                spotlight1.x1 = value
                break
            case Ids.SPOTLIGHT1_YA:
                spotlight1.y0 = value
                break
            case Ids.SPOTLIGHT1_YB:
                spotlight1.y1 = value
                break
            case Ids.SPOTLIGHT2_ENABLE:
                spotlight2.enabled = value
                break
            case Ids.SPOTLIGHT2_COLOR:
                spotlight2.color = value
                break
            case Ids.SPOTLIGHT2_INTENSITYA:
                spotlight2.intensity0 = value
                break
            case Ids.SPOTLIGHT2_INTENSITYB:
                spotlight2.intensity1 = value
                break
            case Ids.SPOTLIGHT2_RADIUSA:
                spotlight2.radius0 = value
                break
            case Ids.SPOTLIGHT2_RADIUSB:
                spotlight2.radius1 = value
                break
            case Ids.SPOTLIGHT2_XA:
                spotlight2.x0 = value
                break
            case Ids.SPOTLIGHT2_XB:
                spotlight2.x1 = value
                break
            case Ids.SPOTLIGHT2_YA:
                spotlight2.y0 = value
                break
            case Ids.SPOTLIGHT2_YB:
                spotlight2.y1 = value
                break
            case Ids.SPOTLIGHT3_ENABLE:
                spotlight3.enabled = value
                break
            case Ids.SPOTLIGHT3_COLOR:
                spotlight3.color = value
                break
            case Ids.SPOTLIGHT3_INTENSITYA:
                spotlight3.intensity0 = value
                break
            case Ids.SPOTLIGHT3_INTENSITYB:
                spotlight3.intensity1 = value
                break
            case Ids.SPOTLIGHT3_RADIUSA:
                spotlight3.radius0 = value
                break
            case Ids.SPOTLIGHT3_RADIUSB:
                spotlight3.radius1 = value
                break
            case Ids.SPOTLIGHT3_XA:
                spotlight3.x0 = value
                break
            case Ids.SPOTLIGHT3_XB:
                spotlight3.x1 = value
                break
            case Ids.SPOTLIGHT3_YA:
                spotlight3.y0 = value
                break
            case Ids.SPOTLIGHT3_YB:
                spotlight3.y1 = value
                break
            case Ids.DOTS_COLOR:
                dots.setColor(new Color(value))
                break
            case Ids.DOTS_INTENSITY:
                dots.setIntensity(value)
                break
            case Ids.DOTS_SIZE:
                dots.setSize(value)
                break
            case Ids.DOTS_GLITTER_SPEED:
                dots.setGlitterSpeed(value)
                break
            case Ids.DOTS_GLITTER_INTENSITY:
                dots.setGlitterIntensity(value)
                break
            case Ids.DOTS_TILE_SIZE:
                dots.setTileSize(value)
                break
            case Ids.MASK_COVERAGE:
                maskConfig.coverage = value
                break
            case Ids.MASK_CURVATURE:
                maskConfig.curvature = value
                break
            default:
        }
    }

    this.setScreenSize = (width, height) => {
        screenSize = new Vector2(width, height)

        mask.setScreenSize(screenSize)
        gradients.setScreenSize(screenSize)
        dots.setScreenSize(screenSize)
        spotlight.setScreenSize(screenSize)
    }
}

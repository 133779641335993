import { Vector2, Vector3 } from 'three'

const MaskShader = {
    vertexShader: `
        varying vec2 vUv;

        void main() {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform vec2 u_resolution;
        uniform float u_offset;
        uniform float u_height;
        uniform vec3 u_color;
        uniform vec3 u_backdrop_color;
        uniform float u_backdrop_intensity;
        uniform float u_phase;

        #define PI 3.1415926538

        float radius(float w, float h) {
            float W = w * 0.5;
            float X = sqrt(W * W + h * h) * 0.5;
            float L = (W * X) / h;

            return sqrt(L * L + X * X);
        }

        void main() {
            float vw = u_resolution.x;
            float vh = u_resolution.y;
            float r = radius(vw, u_height);

            vec2 origin = vec2(vw / 2.0, vh - u_offset - r + u_height);

            float dist = distance(gl_FragCoord.xy, origin);

            if (dist > r) {
                gl_FragColor = vec4(u_color, 1.0);
                return;
            }

            gl_FragColor = vec4(u_backdrop_color, u_phase * u_backdrop_intensity);
        }
    `,
    getUniforms() {
        return {
            u_resolution: {
                type: 'v2',
                value: new Vector2(0, 0),
            },
            u_height: {
                type: 'f',
                value: 200,
            },
            u_offset: {
                type: 'f',
                value: 100,
            },
            u_color: {
                type: 'v3',
                value: new Vector3(),
            },
            u_backdrop_color: {
                type: 'v3',
                value: new Vector3(),
            },
            u_backdrop_intensity: {
                type: 'f',
                value: 0.1,
            },
            u_phase: {
                type: 'f',
                value: 1,
            },
        }
    },
}

export default MaskShader

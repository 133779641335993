export default function expect(condition, message) {
    const met = (() => {
        if (typeof condition === 'function') {
            return condition()
        }

        return !!condition
    })()

    if (!met) {
        throw new Error(message)
    }
}

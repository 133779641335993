export default function postpone(delay, fn) {
    let rejectPromise

    let timeout

    function cancel({ reject = true } = {}) {
        if (typeof timeout === 'undefined') {
            // No postponed in progress. Ignore.
            return
        }

        clearTimeout(timeout)
        timeout = undefined

        if (reject && rejectPromise) {
            rejectPromise()
        }

        rejectPromise = undefined
    }

    async function postponed() {
        if (typeof timeout !== 'undefined') {
            throw new Error('`postponed` has already been called.')
        }

        return new Promise((resolve, reject) => {
            rejectPromise = reject

            timeout = setTimeout(() => {
                cancel({
                    reject: false,
                })
                resolve(fn())
            }, delay)
        })
    }

    return [cancel, postponed]
}

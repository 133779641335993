import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import Spinner from '../Spinner'
import { useSwipeable } from 'react-swipeable'
import NewSliderNav, { Root as SliderNavRoot } from '../NewSliderNav'

const Desktop = 1176

const Tablet = 768

/**
 * Each entry contains a tweet id and a <Layout, Column> pair where
 * Layout is simply the number of columns.
 */
const tweets = [
    ['1644370704547168263'],
    ['1651892564394475523'],
    ['1646333092976533505'],
    ['1643669704475652096'],
    ['1651633493397446669'],
    ['1643642048669446145'],
    ['1650820953260298240'],
    ['1641480647419080708', { 5: 3 }],
    ['1639647528898506754', { 5: 2 }],
    ['1629624770017861632', { 3: 1 }],
]

function twttr(onReady) {
    window.twttr = (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0]

        const t = window.twttr || {}

        if (d.getElementById(id)) return t

        const js = d.createElement(s)

        js.id = id

        js.src = 'https://platform.twitter.com/widgets.js'

        fjs.parentNode.insertBefore(js, fjs)

        t._e = []

        t.ready = function (f) {
            t._e.push(f)
        }

        return t
    })(document, 'script', 'twitter-wjs')

    window.twttr.ready(() => {
        onReady(window.twttr.widgets)
    })
}

function Tweet({ id }) {
    const parentRef = useRef(null)

    useEffect(() => {
        twttr(async (widgets) => {
            let { current: parent } = parentRef

            if (!parent) {
                return
            }

            /**
             * We make twitter script inject tweets into a new div and after each
             * injection we make sure it's the sole element of `parent` element.
             *
             * Injecting tweets into `parent` directly makes the loading indicator
             * disapper instantly without a chance to be seen.
             */
            const div = document.createElement('div')

            parent.appendChild(div)

            await widgets.createTweet(id, div)

            /**
             * We re-assign `parent` because after the above `await` we may be
             * in an entirely different world (unmounted or gone completely).
             */
            parent = parentRef.current

            if (!parent) {
                /**
                 * No `parent` means there's nothing to update, whatever happened.
                 */
                return
            }

            const children = [...parent.children]

            children.forEach((child) => {
                if (child.parentNode === parent && child !== div) {
                    parent.removeChild(child)
                }
            })
        })
    }, [id])

    return (
        <TweetRoot>
            <BorderCloak />
            <div ref={parentRef}>
                <LoadingIndicator>
                    <Spinner strokeWidth={3} r={3} strokeColor="#6240AF" />
                </LoadingIndicator>
            </div>
        </TweetRoot>
    )
}

function group(numColumns) {
    const result = [...Array(numColumns)].map((_, i) => ({ id: `${numColumns}:${i}`, ids: [] }))

    for (let i = 0; i < tweets.length; i++) {
        const [id, { [numColumns]: col = i % numColumns } = {}] = tweets[i]

        result[col].ids.push(id)
    }

    return result
}

export default function TweetWall({ children }) {
    const isDesktop = useMediaQuery({ minWidth: Desktop })

    const isTablet = useMediaQuery({ minWidth: Tablet })

    const n = isDesktop ? 3 : 5

    const columns = useMemo(() => group(n), [n])

    const [tabletSlide, setTabletSlide] = useState(0)

    const [mobileSlide, setMobileSlide] = useState(0)

    const handlers = useSwipeable({
        onSwipedLeft() {
            if (isDesktop) {
                return
            }

            if (isTablet) {
                return void setTabletSlide((c) => Math.min(2, c + 1))
            }

            setMobileSlide((c) => Math.min(4, c + 1))
        },
        onSwipedRight() {
            if (isDesktop) {
                return
            }

            if (isTablet) {
                return void setTabletSlide((c) => Math.max(0, c - 1))
            }

            setMobileSlide((c) => Math.max(0, c - 1))
        },
    })

    const dx = isDesktop ? 0 : isTablet ? tabletSlide : mobileSlide

    const [ready, setReady] = useState(false)

    useEffect(() => void setReady(true), [])

    if (!ready) {
        return <></>
    }

    return (
        <Root {...handlers}>
            {children(
                <Container
                    style={{
                        transform: `translateX(${-dx}rem) translateX(${-100 * dx}%)`,
                    }}
                >
                    <List $numColumns={n} $columnWidth={17.5}>
                        {columns.map(({ id, ids }) => (
                            <li key={id}>
                                {ids.map((tweetId) => (
                                    <Tweet key={tweetId} id={tweetId} />
                                ))}
                            </li>
                        ))}
                    </List>
                </Container>,
                isDesktop ? null : (
                    <div style={{ padding: `0 1.5rem`, maxWidth: '24rem', margin: '0 auto' }}>
                        <NewSliderNav
                            continuous={!isTablet}
                            size={isTablet ? 3 : 5}
                            onClick={(slide) => {
                                if (isDesktop) {
                                    return
                                }

                                if (isTablet) {
                                    return void setTabletSlide(slide)
                                }

                                setMobileSlide(slide)
                            }}
                            current={dx}
                        />
                    </div>
                ),
            )}
        </Root>
    )
}

const LoadingIndicator = styled.div`
    align-items: center;
    display: flex;
    height: 10rem;
    justify-content: center;
`

const Container = styled.div`
    max-width: 17.5rem;
    transition: 0.5s transform ease-in-out;

    @media (min-width: ${Tablet}px) {
        margin: 0 auto;
        max-width: 45rem;
    }

    @media (min-width: ${Desktop}px) {
        max-width: 81rem;
    }
`

const List = styled.ul`
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(${({ $numColumns = 5 }) => $numColumns}, 1fr);
    list-style: none;
    margin: 0;
    padding: 0;
    width: ${({ $numColumns = 5, $columnWidth = 22 }) => $numColumns * ($columnWidth + 1) - 1}rem;

    @media (min-width: ${Tablet}px) {
        width: 114rem;
    }

    @media (min-width: ${Desktop}px) {
        gap: 1.5rem;
        width: auto;
    }
`

const BorderCloak = styled.div`
    border-radius: 16px;
    border: 2px solid white;
    box-sizing: border-box;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: 200ms border-color;
    width: 100%;
`

const TweetRoot = styled.div`
    background: #ffffff;
    border-radius: 16px;
    min-height: 10rem;
    overflow: hidden;
    position: relative;
    transition: 0.35s height;

    :hover ${BorderCloak} {
        border-color: rgb(247, 249, 249);
    }

    .twitter-tweet {
        margin: 0 !important;
        display: block !important;
        width: 100% !important;
    }

    iframe {
        width: 100% !important;
    }

    & + & {
        margin-top: 1rem;
    }

    @media (min-width: ${Desktop}px) {
        & + & {
            margin-top: 1.5rem;
        }
    }
`

const Root = styled.div`
    padding: 0 1.5rem;

    @media (min-width: ${Tablet}px) {
        padding: 0;

        ${SliderNavRoot} {
            margin: 0 auto;
            width: 15rem;
        }
    }

    @media (min-width: ${Desktop}px) {
        padding: 0 1.5rem;
    }
`

import { AmbientLight, Object3D, DirectionalLight, Group } from 'three'
import gsap from 'gsap'

const INITIAL_AL_INTENSITY = 1.9

const AL_INTENSITY = 1.25

const INITIAL_DL_INTENSITY = 0.0

const DL_INTENSITY = 0.55

export default function Lights() {
    const group = new Group()

    const ambientLight = new AmbientLight(0xffffff, INITIAL_AL_INTENSITY)

    const target = new Object3D()

    const directLight = new DirectionalLight(0xffffff, INITIAL_DL_INTENSITY)

    const d = 3.5

    target.position.set(0, 0, 0)

    directLight.position.set(2, 2, -2)
    directLight.castShadow = true
    directLight.shadow.mapSize.width = 512 * 3
    directLight.shadow.mapSize.height = 512 * 3
    directLight.shadow.camera.left = -d
    directLight.shadow.camera.right = d
    directLight.shadow.camera.top = d
    directLight.shadow.camera.bottom = -d
    directLight.shadow.camera.far = 8.5
    directLight.shadow.camera.near = 1
    directLight.shadow.bias = -0.0005
    directLight.shadow.radius = 1
    directLight.shadow.autoUpdate = false
    directLight.shadow.needsUpdate = true
    directLight.target = target

    group.add(ambientLight, target, directLight)

    this.setCastShadow = (value) => {
        directLight.castShadow = value
    }

    this.update = () => {
        directLight.shadow.needsUpdate = true
    }

    this.mount = (scene) => {
        scene.add(group)
    }

    this.intro = () => {
        gsap.to(
            {},
            {
                delay: 1,
                duration: 1,
                ease: 'linear',
                onUpdate() {
                    const t = this.progress()

                    ambientLight.intensity =
                        INITIAL_AL_INTENSITY + (AL_INTENSITY - INITIAL_AL_INTENSITY) * t
                    directLight.intensity =
                        INITIAL_DL_INTENSITY + (DL_INTENSITY - INITIAL_DL_INTENSITY) * t
                },
            },
        )
    }
}

const noop = () => {}

export default function ViewerHuman(
    canvas,
    { onGrab = noop, onHover = noop, onDrag = noop, onDrop = noop } = {},
) {
    let downOrigin

    let recentPos

    let enabled = false

    let dist = 0

    let dragged = false

    function onCanvasHover({ offsetX: x, offsetY: y }) {
        if (!downOrigin && enabled) {
            onHover({
                x,
                y,
            })
        }
    }

    this.distance = () => dist

    this.dragged = () => dragged

    function updateDistance() {
        if (!downOrigin || !recentPos) {
            return
        }

        const dx = recentPos.x - downOrigin.offsetX

        const dy = recentPos.y - downOrigin.offsetY

        dist = Math.sqrt(dx * dx + dy * dy)
    }

    function onWindowHover({ clientX, clientY }) {
        const offsetX = downOrigin.clientX - clientX

        const offsetY = downOrigin.clientY - clientY

        recentPos = {
            x: downOrigin.offsetX - offsetX,
            y: downOrigin.offsetY - offsetY,
        }

        updateDistance()

        dragged = true

        onDrag({
            x: recentPos.x,
            y: recentPos.y,
            offsetX,
            offsetY,
            distance: dist,
        })
    }

    function onWindowUp() {
        window.removeEventListener('pointermove', onWindowHover, {
            passive: true,
        })

        window.removeEventListener('pointerup', onWindowUp)

        if (!downOrigin) {
            return
        }

        onDrop(dist)

        downOrigin = undefined

        recentPos = undefined

        dist = 0

        dragged = false
    }

    function onCanvasDown({ clientX, clientY, offsetX, offsetY }) {
        if (!enabled) {
            return
        }

        if (downOrigin) {
            // We're already down, somehow (should NOT happen, but hey).
            return
        }

        downOrigin = {
            clientX,
            clientY,
            offsetX,
            offsetY,
        }

        recentPos = {
            x: offsetX,
            y: offsetY,
        }

        dragged = false

        onGrab(recentPos)

        window.addEventListener('pointermove', onWindowHover, {
            passive: true,
        })

        window.addEventListener('pointerup', onWindowUp)
    }

    canvas.addEventListener('pointermove', onCanvasHover, {
        passive: true,
    })

    canvas.addEventListener('pointerdown', onCanvasDown)

    this.disable = () => {
        onWindowUp()

        enabled = false
    }

    this.enable = () => {
        enabled = true
    }

    this.isDragging = () => !!downOrigin
}

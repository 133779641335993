import { ShaderMaterial } from 'three'

export default function LineMaterial() {
    const mat = new ShaderMaterial()
    mat.transparent = true
    let shader = null

    mat.setTime = (time) => {
        if (shader) {
            shader.uniforms.u_time.value = time
        }
    }

    mat.doOnAfterBeforeCompile = true
    mat.onAfterBeforeCompile = null

    mat.onBeforeCompile = (_shader) => {
        shader = _shader

        shader.vertexShader = `
          uniform float u_time;

          attribute vec4 startPos;
          attribute vec4 endPos;
          attribute vec4 lineColor;

          varying float vtime;
          varying vec3 line_color;
          varying float line_length;

          void main() {
            float mv = (u_time - startPos.w) * endPos.w;
            float ease = 1.0 - clamp(pow(2.0, -5.0 * mv), 0.0, 1.0); // ease in

            vtime = u_time;
            line_length = length(startPos.xz - endPos.xz);
            vec3 pos = startPos.xyz + (endPos.xyz - startPos.xyz) * ease;
            gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);

            line_color = lineColor.xyz;
          }
        `

        shader.fragmentShader = `
          varying float vtime;

          varying vec3 line_color;
          varying float line_length;

          //<inject_frag_uniforms>

          void main() {
            float x_stripe = (sin(line_length * 500.0 - vtime * 10.0) + 0.5);
            gl_FragColor = vec4(line_color, x_stripe);

            //<inject_fragment>
          }
        `

        shader.uniforms.u_time = {
            value: 0.0,
        }

        if (mat.onAfterBeforeCompile) {
            mat.onAfterBeforeCompile(_shader)
        }
    }

    return mat
}

import React from 'react'
import { MqTablet } from 'streamr-ui/css/consts'
import styled from 'styled-components'

export default function LearnStreamrNav({
    slideNo = 0,
    count = 0,
    onPrev,
    onNext,
    invisibleOnTabletAndAbove = false,
}) {
    return (
        <Root $mobileOnly={invisibleOnTabletAndAbove}>
            <div $appear>
                <ArrowWrap onClick={() => void onPrev()} disabled={slideNo === 0}>
                    <Border />
                    <Arrow>
                        <svg
                            width="12"
                            height="20"
                            viewBox="0 0 12 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.7948 0.808518C11.1853 1.19904 11.1853 1.83221 10.7948 2.22273L3.01187 10.0056L10.79 17.7838C11.1806 18.1743 11.1806 18.8075 10.79 19.198C10.3995 19.5886 9.76635 19.5886 9.37583 19.198L0.890549 10.7127C0.500025 10.3222 0.500025 9.68906 0.890549 9.29853L9.38056 0.808518C9.77109 0.417994 10.4043 0.417994 10.7948 0.808518Z"
                                fill="currentColor"
                            />
                        </svg>
                    </Arrow>
                </ArrowWrap>
            </div>
            <div $appear>
                <ArrowWrap onClick={() => void onNext()} disabled={slideNo >= count - 1}>
                    <Border />
                    <Arrow>
                        <svg
                            width="12"
                            height="20"
                            viewBox="0 0 12 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M1.205 0.808518C1.59553 0.417994 2.22869 0.417994 2.61922 0.808518L11.1092 9.29853C11.2968 9.48607 11.4021 9.74042 11.4021 10.0056C11.4021 10.2709 11.2968 10.5252 11.1092 10.7127L2.62395 19.198C2.23342 19.5886 1.60026 19.5886 1.20973 19.198C0.81921 18.8075 0.81921 18.1743 1.20973 17.7838L8.98791 10.0056L1.205 2.22273C0.814478 1.83221 0.814478 1.19904 1.205 0.808518Z"
                                fill="currentColor"
                            />
                        </svg>
                    </Arrow>
                </ArrowWrap>
            </div>
        </Root>
    )
}

export const LearnStreamrNavMobileWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;
    padding-right: 2rem;

    @media ${MqTablet} {
        display: none;
    }
`

const Root = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    gap: 1rem;
    width: 7rem;

    svg {
        display: block;
        transform: translateX(-10%);
    }

    > div + div svg {
        transform: translateX(10%);
    }
`

const Border = styled.div`
    width: 100%;
    height: 100%;
    border: 2px solid white;
    box-sizing: border-box;
    position: absolute;
    border-radius: 100%;
    transition: 200ms ease-in-out transform;
    transform: translateZ(0) scale(1);
`

const ArrowWrap = styled.button`
    appearance: none;
    background: none;
    border: 0;
    color: white;
    cursor: pointer;
    height: 48px;
    outline: 0;
    padding: 0;
    position: relative;
    width: 48px;
    transition: 350ms opacity;

    :disabled {
        opacity: 0.5;
        cursor: default;
    }

    :hover ${Border} {
        transform: translateZ(0) scale(1.05);
    }

    :disabled ${Border} {
        transform: translateZ(0) scale(1);
    }
`

const Arrow = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

import { Clock } from 'three'

export default function Timer() {
    const clock = new Clock(false)

    let currentTime = 0

    let pauseTime = 0

    let pauseTimeStart = 0

    let previousTime = 0

    let time = 0

    this.tick = () => {
        const delta = clock.getDelta()

        currentTime = clock.elapsedTime + previousTime

        time = currentTime - pauseTime

        return delta
    }

    this.getTime = () => time

    this.pause = () => {
        pauseTimeStart = currentTime
    }

    this.resume = () => {
        pauseTime = pauseTime + currentTime - pauseTimeStart
    }

    this.start = () => {
        clock.start()
    }

    this.stop = () => {
        previousTime = currentTime
        clock.stop()
    }
}

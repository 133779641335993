import { ShaderMaterial, MeshDepthMaterial, RGBADepthPacking } from 'three'

export default function NodeMaterial() {
    const mat = new ShaderMaterial()
    let shader = null
    let shaderDepth = null

    mat.setTime = (time) => {
        if (shader) {
            shader.uniforms.u_time.value = time
            if (shaderDepth) {
                shaderDepth.uniforms.u_time.value = time
            }
        }
    }

    mat.doOnAfterBeforeCompile = true

    mat.onAfterBeforeCompile = null

    mat.depthMaterial = new MeshDepthMaterial({
        depthPacking: RGBADepthPacking,
    })

    mat.depthMaterial.onBeforeCompile = (_shader) => {
        shaderDepth = _shader
        shaderDepth.vertexShader = `
          uniform float u_time;

          attribute vec4 startPos;
          attribute vec4 endPos;
          attribute vec4 extra;
          attribute vec4 color;

          varying vec2 vHighPrecisionZW;

          void main() {
            float mv = (u_time - startPos.w) * endPos.w;
            float ease = 1.0 - clamp(pow(2.0, -5.0 * mv), 0.0, 1.0); // ease in
            float mv_scale = clamp((u_time-extra.w) * endPos.w, 0.0, 1.0);
            float scale = extra.y + (extra.x - extra.y) * mv_scale;
            vec3 pos = position.xyz*scale + (startPos.xyz + (endPos.xyz - startPos.xyz) * ease);
            gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
            vHighPrecisionZW = gl_Position.zw;
          }
        `

        shaderDepth.fragmentShader = `
          #include <packing>
          varying vec2 vHighPrecisionZW;

          void main() {
            float fragCoordZ = (0.5 * vHighPrecisionZW[0] / vHighPrecisionZW[1] + 0.5);
            gl_FragColor = packDepthToRGBA( fragCoordZ );
          }
        `

        shaderDepth.uniforms.u_time = {
            value: 0.0,
        }
    }

    mat.onBeforeCompile = (_shader) => {
        shader = _shader

        shader.vertexShader = `
          uniform float u_time;

          attribute vec4 startPos;
          attribute vec4 endPos;
          attribute vec4 extra;
          attribute vec4 color;

          varying vec3 pcolor;

          void main() {
            float mv = (u_time - startPos.w) * endPos.w;
            float ease = -pow(2.0, -5.0 * mv) + 1.0; // ease in
            float mv_scale = clamp((u_time - extra.w) * endPos.w, 0.0, 1.0);
            float scale = extra.y + (extra.x - extra.y) * mv_scale;
            vec3 pos = position.xyz * scale + (startPos.xyz + (endPos.xyz - startPos.xyz) * ease);
            vec4 pos4 = modelViewMatrix * vec4(pos, 1.0);

            gl_Position = projectionMatrix * pos4;

            vec3 vpos = vec3(pos4.xyz) / pos4.w;
            vec3 normalInterp = normal + vec3(0, -0.5, 0);

            pcolor = color.xyz;

            float shininessVal = 10.0; // Shininess
            vec3 ambientColor = pcolor;
            vec3 diffuseColor = pcolor;
            vec3 specularColor = pcolor;
            vec3 lightPos = vec3(1.25, 1.25, -0.5); // -1.25);

            vec3 N = normalize(normalInterp);
            vec3 L = normalize(lightPos); // - vpos);

            // Lambert's cosine law
            float lambertian = max(dot(N, L), 0.0);
            float specular = 0.0;

            if(lambertian > 0.0) {
              vec3 R = reflect(-L, N); // Reflected light vector
              vec3 V = normalize(-vpos); // Vector to viewer
              // Compute the specular term
              float specAngle = max(dot(R, V), 0.0);
              specular = pow(specAngle, shininessVal);
            }

            pcolor = vec3(ambientColor +
                          lambertian * diffuseColor +
                          specular * specularColor);
          }
        `
        shader.fragmentShader = `
          //<inject_frag_uniforms>
          varying vec3 pcolor;

          void main() {
            gl_FragColor = vec4(pcolor, 1.0);
            //<inject_fragment>
          }
        `

        shader.uniforms.u_time = {
            value: 0.0,
        }

        if (mat.onAfterBeforeCompile) {
            mat.onAfterBeforeCompile(_shader)
        }
    }

    return mat
}

import React from 'react'
import Display from '~shared/Display'

export default function Br({ mobile, tablet, desktop, ...props }) {
    return (
        <>
            <Display
                {...props}
                as="br"
                desktop={desktop ? 'block' : 'none'}
                mobile={mobile ? 'block' : 'none'}
                tablet={tablet ? 'block' : 'none'}
            />{' '}
        </>
    )
}

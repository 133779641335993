import { ShaderMaterial, MeshDepthMaterial, RGBADepthPacking } from 'three'

export default function NodeMirrorMaterial() {
    const mat = new ShaderMaterial()
    let shader = null
    let shaderDepth = null

    mat.setTime = (time) => {
        if (shader) {
            shader.uniforms.u_time.value = time
            if (shaderDepth) {
                shaderDepth.uniforms.u_time.value = time
            }
        }
    }

    mat.depthMaterial = new MeshDepthMaterial({
        depthPacking: RGBADepthPacking,
    })

    mat.depthMaterial.onBeforeCompile = (_shader) => {
        shaderDepth = _shader
        shaderDepth.vertexShader = `

      uniform float u_time;

      attribute vec4 startPos;
      attribute vec4 endPos;
      attribute vec4 extra;
      attribute vec4 color;

      varying vec2 vHighPrecisionZW;

      void main() {
        float mv = (u_time - startPos.w) * endPos.w;
        float ease = 1.0 - clamp(pow(2.0, -5.0 * mv), 0.0, 1.0); // ease in
        float mv_scale = clamp((u_time - extra.w) * endPos.w, 0.0, 1.0);
        float scale = extra.y + (extra.x - extra.y) * mv_scale;
        vec3 pos = position.xyz * scale + (startPos.xyz + (endPos.xyz - startPos.xyz) * ease);
        gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);

        vHighPrecisionZW = gl_Position.zw;
      }
    `

        shaderDepth.fragmentShader = `
          #include <packing>

          varying vec2 vHighPrecisionZW;

          void main() {
            vec4 diffuseColor = vec4(1.0);

            float fragCoordZ = (0.5 * vHighPrecisionZW[0] / vHighPrecisionZW[1] + 0.5);

            gl_FragColor = packDepthToRGBA(fragCoordZ);
          }
        `

        shaderDepth.uniforms.u_time = {
            value: 0.0,
        }
    }

    mat.onBeforeCompile = (_shader) => {
        shader = _shader

        shader.vertexShader = `
          uniform float u_time;

          attribute vec4 startPos;
          attribute vec4 endPos;
          attribute vec4 extra;
          attribute vec4 color;

          varying vec3 pcolor;
          varying vec3 opos;

          void main() {
            float mv = (u_time - startPos.w) * endPos.w;
            float ease = -pow(2.0, -5.0 * mv) + 1.0; // ease in
            float mv_scale = clamp((u_time - extra.w) * endPos.w, 0.0, 1.0);
            float scale = extra.y + (extra.x - extra.y) * mv_scale;

            opos = position.xyz;

            vec3 pos = position.xyz * scale + (startPos.xyz + (endPos.xyz - startPos.xyz) * ease);
            vec4 pos4 = modelViewMatrix * vec4(pos, 1.0);

            gl_Position = projectionMatrix * pos4;

            float isActive = extra.z;
            float substract = 1.0 - isActive;

            pcolor = color.xyz;
          }
        `

        shader.fragmentShader = `
          varying vec3 pcolor;
          varying vec3 opos;

          void main() {
            // gl_FragColor = vec4(pcolor * (1.0 - (opos.y + 1.0)), 1);
            gl_FragColor = vec4(pcolor * (1.0 - (opos.y + 1.35)), 1.0);
          }
        `

        shader.uniforms.u_time = {
            value: 0.0,
        }
    }

    return mat
}

import { WebGLRenderTarget, RGBAFormat, LinearFilter } from 'three'

export default function getRenderTarget(name, { x = 2, y = 2 } = {}) {
    const target = new WebGLRenderTarget(x, y, {
        format: RGBAFormat,
        magFilter: LinearFilter,
        minFilter: LinearFilter,
    })

    if (name != null) {
        target.texture.name = name
    }

    return target
}

import { Vector2, UniformsUtils, ShaderMaterial } from 'three'
import { FullScreenQuad } from 'three-stdlib/postprocessing/Pass'
import { GradientShader } from '../post/GradientShader'
import getRenderTarget from './getRenderTarget'

export default function getTiltTexture(renderer, { fullBlur, offsetTilt, shift }) {
    const gradientSize = new Vector2(128, 128)

    const renderTargetGradient = getRenderTarget('TiltTexture', {
        x: gradientSize.x,
        y: gradientSize.y,
    })

    const gradientShader = GradientShader
    const gradientUniforms = UniformsUtils.clone(gradientShader.uniforms)
    gradientUniforms.u_resolution.value = gradientSize
    gradientUniforms.u_offset.value = offsetTilt
    gradientUniforms.u_shift.value = shift
    gradientUniforms.u_full_blur.value = fullBlur

    const materialGradient = new ShaderMaterial({
        defines: Object.assign({}, gradientShader.defines), // eslint-disable-line prefer-object-spread
        uniforms: gradientUniforms,
        vertexShader: gradientShader.vertexShader,
        fragmentShader: gradientShader.fragmentShader,
    })

    const fsQuad = new FullScreenQuad(materialGradient)

    renderer.setRenderTarget(renderTargetGradient)
    renderer.clear()
    fsQuad.render(renderer)
    // fsQuad.dispose()

    return renderTargetGradient.texture
}

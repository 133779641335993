const MAPBOX_TOKEN =
    'pk.eyJ1IjoibWF0dGlubmVzIiwiYSI6ImNrNWhrN2FubDA0cGgzam1ycHV6Nmg2dHoifQ.HC5_Wu1R-OqRLza1u6P3Ig'

export async function getReversedGeocodedLocation({ longitude, latitude }) {
    let region

    let bbox

    try {
        const resp = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${MAPBOX_TOKEN}`,
            { method: 'GET' },
        )

        const result = await resp.json()

        const feature =
            result.features.find(({ place_type: type }) => type.includes('region')) || {}

        region = feature.place_name

        bbox = feature.bbox
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Failed to reverse geocode ${longitude},${latitude}`)
    }

    return {
        region,
        bbox,
    }
}

export function getTopology(point, { metadata = new WeakMap(), nodeCoordinates = {} } = {}) {
    const { connections } = metadata.get(point)

    const topology = []

    for (const [nodeId0, nodeId1] of connections) {
        const vectorA = nodeCoordinates[nodeId0]

        const vectorB = nodeCoordinates[nodeId1]

        if (!vectorA || !vectorB) {
            continue
        }

        topology.push([vectorA, vectorB])
    }

    return topology
}

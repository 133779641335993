import gsap from 'gsap'
import { ShaderMaterial } from 'three'
import CoverallShader from '../shaders/CoverallShader'

export default function Coverall({ fsQuad, renderer }) {
    const uniforms = CoverallShader.getUniforms()

    const material = new ShaderMaterial({
        vertexShader: CoverallShader.vertexShader,
        fragmentShader: CoverallShader.fragmentShader,
        uniforms,
        transparent: true,
    })

    let phase = 0

    this.fade = () => {
        gsap.to(
            {},
            {
                duration: 1,
                ease: 'power3.out',
                onUpdate() {
                    phase = this.progress()
                },
            },
        )
    }

    this.render = () => {
        if (phase === 1) {
            return
        }

        uniforms.u_phase.value = phase

        fsQuad.material = material

        fsQuad.render(renderer)
    }
}

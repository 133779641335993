import { ShaderMaterial } from 'three'

export default function GlowMaterial() {
    const mat = new ShaderMaterial()
    let shader = null
    let glowScale = 1.0

    mat.setTime = (time) => {
        if (shader) {
            shader.uniforms.u_time.value = time
        }
    }

    mat.setGlowScale = (value) => {
        if (shader) {
            shader.uniforms.u_glow_scale.value = value
        }
        glowScale = value
    }

    mat.onBeforeCompile = (_shader) => {
        shader = _shader

        shader.vertexShader = `
          uniform float u_time;
          uniform float u_glow_scale;

          attribute vec4 startPos;
          attribute vec4 endPos;
          attribute vec4 extra;
          attribute vec4 color;

          varying vec3 pcolor;
          varying float isActive;

          void main() {
            float mv = (u_time - startPos.w) * endPos.w;
            float ease = 1.0 - clamp(pow(2.0, -5.0 * mv), 0.0, 1.0); // ease in

            float scale = min(1.0, ease * ease) * extra.x;
            vec3 pos = position.xyz*scale*u_glow_scale + (startPos.xyz + (endPos.xyz - startPos.xyz) * ease);

            gl_Position = projectionMatrix * modelViewMatrix * vec4( pos, 1.0 );

            isActive = extra.z;
            pcolor = color.xyz;
          }
        `

        shader.fragmentShader = `
          varying vec3 pcolor;
          varying float isActive;

          void main() {
            gl_FragColor = vec4(pcolor*clamp(isActive, 0.25, 0.6), 1.0);
          }
        `

        shader.uniforms.u_time = {
            value: 0.0,
        }

        shader.uniforms.u_glow_scale = {
            value: glowScale,
        }
    }

    return mat
}

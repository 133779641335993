const Ids = {
    MAP_OCEAN: 'map/ocean',
    MAP_LAND: 'map/land',
    DOTS_TILE_SIZE: 'dots/tileSize',
    DOTS_SIZE: 'dots/size',
    DOTS_COLOR: 'dots/color',
    DOTS_INTENSITY: 'dots/intensity',
    DOTS_GLITTER_SPEED: 'dots/glitterSpeed',
    DOTS_GLITTER_INTENSITY: 'dots/glitterIntensity',
    LINES_COLOR: 'lines/color',
    LINES_SHADOW_COLOR: 'lines/shadowColor',
    NODES_COLOR: 'nodes/color',
    NODES_HIGHLIGHTED_COLOR: 'nodes/highlightedColor',
    NODES_SELECTED_COLOR: 'nodes/selectedColor',
    NODES_CONNECTED_COLOR: 'nodes/connectedColor',
    MASK_COLOR: 'mask/color',
    MASK_CURVATURE: 'mask/curvature',
    MASK_COVERAGE: 'mask/coverage',
    MASKBACKDROP_COLOR: 'maskBackdrop/color',
    MASKBACKDROP_INTENSITY: 'maskBackdrop/intensity',
    OUTERGLOW_COLOR: 'outerGlow/color',
    OUTERGLOW_WIDTH: 'outerGlow/width',
    INNERGLOW_COLOR: 'innerGlow/color',
    INNERGLOW_WIDTH: 'innerGlow/width',
    SPOTLIGHT1_ENABLE: 'spotlight1/enable',
    SPOTLIGHT1_COLOR: 'spotlight1/color',
    SPOTLIGHT1_INTENSITYA: 'spotlight1/intensityA',
    SPOTLIGHT1_INTENSITYB: 'spotlight1/intensityB',
    SPOTLIGHT1_RADIUSA: 'spotlight1/radiusA',
    SPOTLIGHT1_RADIUSB: 'spotlight1/radiusB',
    SPOTLIGHT1_XA: 'spotlight1/xA',
    SPOTLIGHT1_YA: 'spotlight1/yA',
    SPOTLIGHT1_XB: 'spotlight1/xB',
    SPOTLIGHT1_YB: 'spotlight1/yB',
    SPOTLIGHT2_ENABLE: 'spotlight2/enable',
    SPOTLIGHT2_COLOR: 'spotlight2/color',
    SPOTLIGHT2_INTENSITYA: 'spotlight2/intensityA',
    SPOTLIGHT2_INTENSITYB: 'spotlight2/intensityB',
    SPOTLIGHT2_RADIUSA: 'spotlight2/radiusA',
    SPOTLIGHT2_RADIUSB: 'spotlight2/radiusB',
    SPOTLIGHT2_XA: 'spotlight2/xA',
    SPOTLIGHT2_YA: 'spotlight2/yA',
    SPOTLIGHT2_XB: 'spotlight2/xB',
    SPOTLIGHT2_YB: 'spotlight2/yB',
    SPOTLIGHT3_ENABLE: 'spotlight3/enable',
    SPOTLIGHT3_COLOR: 'spotlight3/color',
    SPOTLIGHT3_INTENSITYA: 'spotlight3/intensityA',
    SPOTLIGHT3_INTENSITYB: 'spotlight3/intensityB',
    SPOTLIGHT3_RADIUSA: 'spotlight3/radiusA',
    SPOTLIGHT3_RADIUSB: 'spotlight3/radiusB',
    SPOTLIGHT3_XA: 'spotlight3/xA',
    SPOTLIGHT3_YA: 'spotlight3/yA',
    SPOTLIGHT3_XB: 'spotlight3/xB',
    SPOTLIGHT3_YB: 'spotlight3/yB',
}

export default Ids

import React, { useEffect, useRef } from 'react'
import { ButtonSize, PrimaryButton } from 'streamr-ui'
import { useInViewport } from 'streamr-ui/hooks'
import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled, { css } from 'styled-components'
import { withAssetPrefix } from 'gatsby'
import Topic from '~shared/Topic'
import getVideoURL from '~utils/getVideoURL'
import poster from './assets/launch_video_poster.jpg'

export default function Tatum() {
    return (
        <Root>
            <h2 $appear>
                <TagGroup>
                    <LeadingTag>Streamr 1.0</LeadingTag>
                </TagGroup>
                <TagGroup>
                    <div>is</div>
                    <div>Here</div>
                </TagGroup>
            </h2>
            <p $appear>
                The complete DATA tokenomics have now been released with &quot;Streamr 1.0&quot; -
                the final milestone in the Streamr Roadmap. Streamr node operators secure
                and&nbsp;relay data on streams associated to Sponsorships. Delegators delegate
                to&nbsp;Operators and earn a revenue share of the Operator&apos;s work
                in&nbsp;return.
            </p>
            <Cta variant="mobile" />
            <LaunchVideo />
            <Cta variant="tablet" />
        </Root>
    )
}

function Cta({ variant }) {
    return (
        <CtaRoot $appear $mobile={variant === 'mobile'}>
            <PrimaryButton
                tag="a"
                size={ButtonSize.Moose}
                paint={CustomPaint}
                href="https://blog.streamr.network/streamr-token-economics/"
            >
                Learn about Streamr 1.0
            </PrimaryButton>
        </CtaRoot>
    )
}

const CtaRoot = styled.div`
    ${({ $mobile = false }) =>
        $mobile
            ? css`
                  margin: 40px 0 0;

                  @media ${MqTablet} {
                      display: none;
                  }
              `
            : css`
                  display: none;

                  @media ${MqTablet} {
                      display: block;
                      margin-top: 48px;
                  }

                  @media ${MqDesktop} {
                      margin-top: 80px;
                  }
              `}
`

const Root = styled.div`
    margin: 0 auto;
    max-width: 992px;
    padding: 0 32px;
    text-align: center;

    h2 {
        align-items: center;
        display: flex;
        font-weight: 600;
        justify-content: center;
        flex-wrap: wrap;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        margin: 56px auto 0;
        max-width: 100%;
        width: 496px;
    }

    @media ${MqTablet} {
        p {
            font-size: 20px;
            line-height: 32px;
            margin-top: 48px;
            width: 720px;
        }
    }
`

const TagGroup = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    div {
        align-items: center;
        background: #dde0f6;
        border-radius: 44px;
        box-sizing: border-box;
        color: #0324ff;
        display: flex;
        flex-shrink: 0;
        font-size: 40px;
        height: 88px;
        justify-content: center;
        min-width: 88px;
        padding: 0 20px;
    }

    @media ${MqDesktop} {
        div {
            font-size: 60px;
        }
    }
`

const LeadingTag = styled.div`
    && {
        background: #0324ff;
        color: white;
    }
`

const CustomPaint = css`
    --radius: 8px;
`

export function TatumTopic() {
    return (
        <Topic pad head="6rem,9rem,12rem" tail backgroundColor="#ffffff">
            <Tatum />
        </Topic>
    )
}

function LaunchVideo() {
    const videoRef = useRef(null)

    const [attach, inViewport] = useInViewport()

    const playedOnceRef = useRef(false)

    useEffect(
        function playOnNotice() {
            void (async () => {
                if (!inViewport || playedOnceRef.current) {
                    return
                }

                try {
                    await videoRef?.current?.play()

                    playedOnceRef.current = true
                } catch (e) {
                    console.warn('Failed to start the Launch video', e)
                }
            })()
        },
        [inViewport],
    )

    return (
        <LaunchVideoRoot $appear ref={attach}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video muted ref={videoRef} poster={poster}>
                <source
                    src={withAssetPrefix(getVideoURL('streamr_v1_0_minified.mp4'))}
                    type="video/mp4"
                />
            </video>
        </LaunchVideoRoot>
    )
}

const LaunchVideoRoot = styled.div`
    margin-top: 40px;
    position: relative;
    width: auto;

    video {
        aspect-ratio: 985 / 554;
        border-radius: 16px;
        display: block;
        overflow: hidden;
        width: 100%;
    }

    @media ${MqTablet} {
        margin-top: 80px;
    }

    @media ${MqDesktop} {
        margin-top: 72px;
    }
`

import now from './now'

export default function loop(onFrame, { fps = 60 } = {}) {
    let lastRenderAt

    let frameRequest

    function fn() {
        if (lastRenderAt != null && now() - lastRenderAt < 1000 / fps) {
            frameRequest = window.requestAnimationFrame(fn)
            return
        }

        onFrame(frameRequest)

        lastRenderAt = now()

        frameRequest = window.requestAnimationFrame(fn)
    }

    return () => {
        fn()

        return () => {
            cancelAnimationFrame(frameRequest)
            frameRequest = undefined
        }
    }
}

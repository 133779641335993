import gsap from 'gsap'

const Ratios = {
    Manual: 0.1,
    Extra: 0.5, // 50% vh
}

export const MinHeight = 720

const Speed = 0.7

function noop() {}

// eslint-disable-next-line import/no-unused-modules
export default function ScrollPhaseManager({
    onUpdate = noop,
    onComplete = noop,
    onBeforeStart = noop,
    onThresholdUpdate = noop,
} = {}) {
    const Auto = 1 - Ratios.Manual

    const scrollPhase = {
        manual: Ratios.Manual,
        auto: Auto,
    }

    let recentPhase

    let recentDonePhase

    function getCurrentPhase() {
        return scrollPhase.manual + scrollPhase.auto
    }

    function apply() {
        const currentPhase = getCurrentPhase()

        if (recentPhase === currentPhase) {
            return
        }

        onUpdate(currentPhase)

        recentPhase = currentPhase
    }

    let tweenIn

    let tweenOut

    function transitionIn() {
        if (tweenOut) {
            tweenOut.kill()
            tweenOut = undefined
        }

        if (tweenIn) {
            return
        }

        onBeforeStart(Auto)

        tweenIn = gsap.to(scrollPhase, {
            duration: (1 - Math.min(1, scrollPhase.auto / Auto)) * Speed,
            auto: Auto,
            onUpdate() {
                apply()
            },
            onComplete() {
                const donePhase = getCurrentPhase()

                if (donePhase !== recentDonePhase) {
                    onComplete(donePhase)
                    recentDonePhase = donePhase
                }
            },
        })
    }

    function transitionOut() {
        if (tweenIn) {
            tweenIn.kill()
            tweenIn = undefined
        }

        if (tweenOut) {
            return
        }

        onBeforeStart(0)

        tweenOut = gsap.to(scrollPhase, {
            duration: Math.min(1, scrollPhase.auto / Auto) * Speed,
            auto: 0,
            onUpdate() {
                apply()
            },
            onComplete() {
                const donePhase = getCurrentPhase()

                if (donePhase !== recentDonePhase) {
                    onComplete(donePhase)
                    recentDonePhase = donePhase
                }
            },
        })
    }

    let mounted = false

    function touchScrollPhase() {
        const scrollY = Math.max(0, window.scrollY - Math.max(0, MinHeight - window.innerHeight))

        const phase = Math.min(1, scrollY / (window.innerHeight * Ratios.Extra))

        const { manual: previous } = scrollPhase

        scrollPhase.manual = Ratios.Manual * (1 - Math.min(1, phase / Ratios.Manual))

        if (scrollPhase.manual === 0 && (previous !== 0 || !mounted)) {
            transitionOut()
        }

        if (scrollPhase.manual > 0 && (previous === 0 || !mounted)) {
            transitionIn()
        }

        apply()
    }

    function onResize() {
        touchScrollPhase()

        const threshold =
            Math.max(0, MinHeight - window.innerHeight) +
            Ratios.Manual * (window.innerHeight * Ratios.Extra)

        onThresholdUpdate(threshold)
    }

    this.mount = () => {
        if (mounted) {
            return
        }

        onResize()

        mounted = true

        window.addEventListener('scroll', touchScrollPhase, {
            passive: true,
        })

        window.addEventListener('resize', onResize, {
            passive: true,
        })
    }

    this.unmount = () => {
        if (!mounted) {
            return
        }

        mounted = false

        if (tweenIn) {
            tweenIn.kill()
        }

        if (tweenOut) {
            tweenOut.kill()
        }

        window.removeEventListener('scroll', touchScrollPhase, {
            passive: true,
        })

        window.removeEventListener('resize', onResize, {
            passive: true,
        })
    }
}

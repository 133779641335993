import { Color } from 'three'

const CoverallShader = {
    vertexShader: `
        varying vec2 vUv;

        void main() {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform vec3 u_color;
        uniform float u_phase;

        void main() {
            gl_FragColor = vec4(u_color, 1.0 - u_phase);
        }
    `,
    getUniforms() {
        return {
            u_color: {
                type: 'v3',
                value: new Color('#0a1433'),
            },
            u_phase: {
                type: 'f',
                value: 0,
            },
        }
    },
}

export default CoverallShader

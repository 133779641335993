import { withAssetPrefix } from 'gatsby'
import React, { useRef, useState } from 'react'
import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import styled from 'styled-components'
import PlayButton from '~shared/PlayButton'
import VideoModal from '~shared/VideoModal'
import getVideoURL from '~utils/getVideoURL'
import poster from './poster.png'

const VideoWrap = styled.div`
    video {
        aspect-ratio: 16 / 9;
        display: block;
        max-width: 100%;
        width: 1376px;
    }

    @media (min-width: 640px) {
        padding: 0 32px;

        video {
            border-radius: 8px;
        }
    }
`

const Inner = styled.div`
    box-sizing: border-box;
    height: 464px;
    margin: 0 auto;
    max-width: 100%;
    padding: 0 32px;

    > div {
        border-radius: 8px;
        height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
    }

    img {
        display: block;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 920px;
    }

    @media ${MqTablet} {
        img {
            width: 1200px;
        }
    }

    @media ${MqDesktop} {
        height: 472px;
        width: 1224px;

        img {
            width: 100%;
        }
    }
`

export default function IntroVideo() {
    const [showVideo, setShowVideo] = useState(false)

    const videoRef = useRef(null)

    return (
        <>
            {!!showVideo && (
                <VideoModal
                    onCloseClick={() => {
                        setShowVideo(false)
                    }}
                >
                    <VideoWrap>
                        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                        <video autoPlay controls ref={videoRef}>
                            <source
                                src={withAssetPrefix(getVideoURL('streamr_promo_202301.mp4'))}
                                type="video/mp4"
                            />
                        </video>
                    </VideoWrap>
                </VideoModal>
            )}
            <Inner $appear>
                <div>
                    <img src={poster} alt="" />
                    <PlayButton
                        monolith
                        onClick={() => {
                            setShowVideo(true)
                        }}
                    />
                </div>
            </Inner>
        </>
    )
}

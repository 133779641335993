import React from 'react'
import styled, { css } from 'styled-components'

function UnstyledToggleIcon(props) {
    return (
        <svg {...props} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                // eslint-disable-next-line max-len
                d="M1.92324 5.0625C1.38531 5.0625 0.949219 5.48223 0.949219 6C0.949219 6.51777 1.38531 6.9375 1.92324 6.9375L5.62456 6.9375V10.3388C5.62456 10.8566 6.06065 11.2763 6.59859 11.2763C7.13653 11.2763 7.57261 10.8566 7.57262 10.3388V6.9375H11.2739C11.8118 6.9375 12.2479 6.51777 12.2479 6C12.2479 5.48223 11.8118 5.0625 11.2739 5.0625L7.57262 5.0625L7.57261 1.5C7.57261 0.982233 7.13653 0.5625 6.59859 0.5625C6.06065 0.5625 5.62456 0.982233 5.62456 1.5V5.0625L1.92324 5.0625Z"
                fill="currentColor"
            />
        </svg>
    )
}

const ToggleIcon = styled(UnstyledToggleIcon)``

export default ToggleIcon

export const ToggleIconWrap = styled.div`
    svg {
        display: block;
        transition: 250ms ease-in-out transform;
        width: 13px;
    }

    ${({ $rotation = '0deg', $scale = 1 }) => css`
        svg {
            transform: rotate(${$rotation}) scale(${$scale});
        }
    `}
`

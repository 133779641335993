import { Vector4, Mesh } from 'three'
import { mergeBufferGeometries } from 'three-stdlib/utils/BufferGeometryUtils'
import { getIcosphereGeometry, Attribute } from './geometry'

export default function getParticles(
    quantity,
    particleMaterial,
    mirrorMaterial,
    { highQuality = false } = {},
) {
    const geometries = []

    const geometry = getIcosphereGeometry(
        1.0,
        0.0,
        0.0,
        [
            new Attribute('startPos'),
            new Attribute('endPos'),
            new Attribute('extra'),
            new Attribute('color', new Vector4(0, 0, 1, 0)),
        ],
        1,
    )

    const verticeSize = geometry.attributes.position.count * 4

    for (let i = 0; i < quantity; i++) {
        geometries.push(geometry.clone())
    }

    const mergedGeometry = mergeBufferGeometries(geometries, false)

    const instance = new Mesh(mergedGeometry, particleMaterial)

    instance.customDepthMaterial = particleMaterial.depthMaterial
    instance.castShadow = true
    instance.receiveShadow = false
    instance.position.set(0, 0, 0)
    instance.frustumCulled = false
    instance.quality = highQuality ? 'high_quality' : 'low_quality'

    const instanceMirror = instance.clone()

    instanceMirror.material = mirrorMaterial
    instanceMirror.scale.set(1, 1, -1)
    instanceMirror.position.set(0, -0.01, 0)
    instanceMirror.rotation.x = Math.PI

    return {
        instance,
        instanceMirror,
        piece: geometry,
        quantity,
        verticeSize,
    }
}

import { Vector2, Vector4 } from 'three'

const DotsShader = {
    vertexShader: `
        varying vec2 vUv;

        void main() {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        #define PI 3.1415926538

        uniform vec2 u_resolution;
        uniform vec4 u_color;
        uniform float u_tile_size;
        uniform float u_dot_size;
        uniform float u_time;
        uniform float u_glitter_speed;
        uniform float u_glitter_intensity;
        uniform float u_offset;
        uniform float u_height;

        float radius(float w, float h) {
            float W = w * 0.5;
            float X = sqrt(W * W + h * h) * 0.5;
            float L = (W * X) / h;

            return sqrt(L * L + X * X);
        }

        float easeInSine(float x) {
            return 1.0 - cos((x * PI) * 0.5);
        }

        float rand(vec2 co) {
            return fract(sin(dot(co, vec2(12.9898, 78.233))) * 43758.5453);
        }

        float ease(float x) {
            return x < 0.5
                ? (1.0 - sqrt(1.0 - pow(2.0 * x, 2.0))) * 0.5
                : (sqrt(1.0 - pow(-2.0 * x + 2.0, 2.0)) + 1.0) * 0.5;
        }

        void main() {
            float vw = u_resolution.x;
            float vh = u_resolution.y;
            float r = radius(vw, u_height);

            vec2 mask_origin = vec2(vw / 2.0, vh - u_offset - r + u_height);

            float mask_dist = distance(gl_FragCoord.xy, mask_origin);

            if (mask_dist <= r) {
                gl_FragColor = vec4(u_color.rgb, 0.0);
                return;
            }

            float x = gl_FragCoord.x - vw * 0.5;
            float y = vh - (gl_FragCoord.y + u_tile_size * 0.5 + 0.5);
            vec2 origin_base = vec2(round(x / u_tile_size), round(y / u_tile_size));
            vec2 origin = origin_base * u_tile_size;
            float dist = distance(vec2(x, y), origin);

            if (dist <= u_dot_size) {
                float phase = rand(origin_base) * 10.0;
                float intensity = u_color.a
                    * ((1.0 - u_glitter_intensity) + u_glitter_intensity * (cos(phase + u_time * u_glitter_speed) + 1.0) * 0.5);

                gl_FragColor = vec4(
                    u_color.rgb,
                    ease(1.0 - dist / u_dot_size) * intensity * easeInSine(0.5 + 0.5 * (gl_FragCoord.y / vh))
                );
                return;
            }

            gl_FragColor = vec4(u_color.rgb, 0.0);
        }
    `,
    getUniforms() {
        return {
            u_glitter_speed: {
                type: 'f',
                value: 2,
            },
            u_glitter_intensity: {
                type: 'f',
                value: 0.75,
            },
            u_time: {
                type: 'f',
                value: 0,
            },
            u_resolution: {
                type: 'v2',
                value: new Vector2(0, 0),
            },
            u_color: {
                type: 'v4',
                value: new Vector4(1, 1, 1, 0.85),
            },
            u_tile_size: {
                type: 'f',
                value: 50,
            },
            u_dot_size: {
                type: 'f',
                value: 1.5,
            },
            u_height: {
                type: 'f',
                value: 200,
            },
            u_offset: {
                type: 'f',
                value: 100,
            },
        }
    },
}

export default DotsShader

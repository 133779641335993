import { Vector2, Vector4 } from 'three'

const SpotLightShader = {
    vertexShader: `
        varying vec2 vUv;

        void main() {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform vec2 u_resolution;
        uniform vec4 u_color;
        uniform vec2 u_pos; // normalized
        uniform float u_radius;

        #define PI 3.1415926538

        float easeInSine(float x) {
            return 1.0 - cos((x * PI) * 0.5);
        }

        void main() {
            vec2 center = vec2(u_pos.x * u_resolution.x, u_resolution.y * (1.0 - u_pos.y));

            float dist = distance(gl_FragCoord.xy, center);

            if (dist > u_radius) {
                gl_FragColor = vec4(u_color.rgb, 0.0);
                return;
            }

            float sand = (fract(sin(dot(gl_FragCoord.xy, vec2(12.9898, 78.233))) * 43758.5453) - 0.5) / 17.0;

            vec4 color = vec4(u_color.rgb, u_color.a * easeInSine(1.0 - dist / u_radius));

            gl_FragColor = vec4(color.r + sand, color.g + sand, color.b + sand, color.a);
        }
    `,
    getUniforms() {
        return {
            u_resolution: {
                type: 'v2',
                value: new Vector2(),
            },
            u_color: {
                type: 'v4',
                value: new Vector4(1, 1, 1, 1),
            },
            u_pos: {
                type: 'v2',
                value: new Vector2(),
            },
            u_radius: {
                type: 'f',
                value: 250,
            },
        }
    },
}

export default SpotLightShader

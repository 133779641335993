/**
 * @author simppafi / http://www.simppa.fi/
 *
 * Bloom Ray Bokeh Shader
 */

import { Vector2, Vector4 } from 'three'

const BokehTiltShiftShader = {
    uniforms: {
        tColor: {
            value: null,
        },
        u_radius: {
            value: 0.5,
        },
        u_resolution: {
            value: new Vector2(512, 512),
        },
        u_offset: {
            value: new Vector4(1.1, 1.0, 1.1, 1.0),
        },
        u_shift: {
            value: new Vector4(4.0, 1000.0, 4.0, 1000.0),
        },
        u_full_blur: {
            value: 0.0,
        },
        u_aspect: {
            value: 0.0,
        },
        u_tilt: {
            value: null,
        },
    },

    vertexShader: `
        varying vec2 vUv;

        void main() {
           vUv = uv;
           gl_Position = vec4(position, 1.0);
        }
    `,

    fragmentShader: `
        precision mediump float;

        #include <packing>

        varying vec2 vUv;

        uniform float u_radius;
        uniform vec2 u_resolution;
        
        uniform float u_full_blur;
        uniform float u_aspect;

        uniform vec4 u_offset;
        uniform vec4 u_shift;

        uniform sampler2D tColor;
        uniform sampler2D u_tilt;

        void main() {
          vec2 pixelPos = gl_FragCoord.xy / u_resolution;
          float shift = texture2D(u_tilt, pixelPos).r;
          vec2 aspectcorrect = vec2(1.0, u_aspect);
          float factor = shift * 0.01 * u_radius; 
          vec2 blur = vec2 (factor);
          vec2 blur9 = blur * 0.9;
          vec2 blur7 = blur * 0.7;
          vec2 blur4 = blur * 0.4;
          vec4 col = vec4(0.0);

          col += texture2D(tColor, vUv.xy);
          col += texture2D(tColor, vUv.xy + (vec2( 0.0,   0.4 ) * aspectcorrect) * blur);
          col += texture2D(tColor, vUv.xy + (vec2( 0.15,  0.37) * aspectcorrect) * blur);
          col += texture2D(tColor, vUv.xy + (vec2( 0.29,  0.29) * aspectcorrect) * blur);
          col += texture2D(tColor, vUv.xy + (vec2(-0.37,  0.15) * aspectcorrect) * blur);
          col += texture2D(tColor, vUv.xy + (vec2( 0.40,  0.0 ) * aspectcorrect) * blur);
          col += texture2D(tColor, vUv.xy + (vec2( 0.37, -0.15) * aspectcorrect) * blur);
          col += texture2D(tColor, vUv.xy + (vec2( 0.29, -0.29) * aspectcorrect) * blur);
          col += texture2D(tColor, vUv.xy + (vec2(-0.15, -0.37) * aspectcorrect) * blur);
          col += texture2D(tColor, vUv.xy + (vec2( 0.0,  -0.4 ) * aspectcorrect) * blur);
          col += texture2D(tColor, vUv.xy + (vec2(-0.15,  0.37) * aspectcorrect) * blur);
          col += texture2D(tColor, vUv.xy + (vec2(-0.29,  0.29) * aspectcorrect) * blur);
          col += texture2D(tColor, vUv.xy + (vec2( 0.37,  0.15) * aspectcorrect) * blur);
          col += texture2D(tColor, vUv.xy + (vec2(-0.4,   0.0 ) * aspectcorrect) * blur);
          col += texture2D(tColor, vUv.xy + (vec2(-0.37, -0.15) * aspectcorrect) * blur);
          col += texture2D(tColor, vUv.xy + (vec2(-0.29, -0.29) * aspectcorrect) * blur);
          col += texture2D(tColor, vUv.xy + (vec2( 0.15, -0.37) * aspectcorrect) * blur);

          col += texture2D(tColor, vUv.xy + (vec2( 0.15,  0.37) * aspectcorrect) * blur9);
          col += texture2D(tColor, vUv.xy + (vec2(-0.37,  0.15) * aspectcorrect) * blur9);
          col += texture2D(tColor, vUv.xy + (vec2( 0.37, -0.15) * aspectcorrect) * blur9);
          col += texture2D(tColor, vUv.xy + (vec2(-0.15, -0.37) * aspectcorrect) * blur9);
          col += texture2D(tColor, vUv.xy + (vec2(-0.15,  0.37) * aspectcorrect) * blur9);
          col += texture2D(tColor, vUv.xy + (vec2( 0.37,  0.15) * aspectcorrect) * blur9);
          col += texture2D(tColor, vUv.xy + (vec2(-0.37, -0.15) * aspectcorrect) * blur9);
          col += texture2D(tColor, vUv.xy + (vec2( 0.15, -0.37) * aspectcorrect) * blur9);
          col += texture2D(tColor, vUv.xy + (vec2( 0.29,  0.29) * aspectcorrect) * blur7);
          col += texture2D(tColor, vUv.xy + (vec2( 0.40,  0.0 ) * aspectcorrect) * blur7);
          col += texture2D(tColor, vUv.xy + (vec2( 0.29, -0.29) * aspectcorrect) * blur7);
          col += texture2D(tColor, vUv.xy + (vec2( 0.0,  -0.4 ) * aspectcorrect) * blur7);
          col += texture2D(tColor, vUv.xy + (vec2(-0.29,  0.29) * aspectcorrect) * blur7);
          col += texture2D(tColor, vUv.xy + (vec2(-0.4,   0.0 ) * aspectcorrect) * blur7);
          col += texture2D(tColor, vUv.xy + (vec2(-0.29, -0.29) * aspectcorrect) * blur7);
          col += texture2D(tColor, vUv.xy + (vec2( 0.0,   0.4 ) * aspectcorrect) * blur7);

          col += texture2D(tColor, vUv.xy + (vec2( 0.29,  0.29) * aspectcorrect) * blur4);
          col += texture2D(tColor, vUv.xy + (vec2( 0.4,   0.0 ) * aspectcorrect) * blur4);
          col += texture2D(tColor, vUv.xy + (vec2( 0.29, -0.29) * aspectcorrect) * blur4);
          col += texture2D(tColor, vUv.xy + (vec2( 0.0,  -0.4 ) * aspectcorrect) * blur4);
          col += texture2D(tColor, vUv.xy + (vec2(-0.29,  0.29) * aspectcorrect) * blur4);
          col += texture2D(tColor, vUv.xy + (vec2(-0.4,   0.0 ) * aspectcorrect) * blur4);
          col += texture2D(tColor, vUv.xy + (vec2(-0.29, -0.29) * aspectcorrect) * blur4);
          col += texture2D(tColor, vUv.xy + (vec2( 0.0,   0.4 ) * aspectcorrect) * blur4);

          gl_FragColor = col / 41.0;
          gl_FragColor.a = 1.0;
        }
    `,
}

export default BokehTiltShiftShader

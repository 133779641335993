import { Color, ShaderMaterial } from 'three'
import SpotLightShader from '../shaders/SpotLightShader'

// eslint-disable-next-line import/no-unused-modules
export default function SpotLight({ fsQuad, renderer }) {
    const uniforms = SpotLightShader.getUniforms()

    const material = new ShaderMaterial({
        vertexShader: SpotLightShader.vertexShader,
        fragmentShader: SpotLightShader.fragmentShader,
        uniforms,
        transparent: true,
    })

    this.setColor = (color) => {
        const { r, g, b } = new Color(color)
        uniforms.u_color.value.x = r
        uniforms.u_color.value.y = g
        uniforms.u_color.value.z = b
    }

    this.setColor('#0ea5ff')

    this.setScreenSize = (newSize) => {
        uniforms.u_resolution.value = newSize
    }

    this.render = ({ x = 0, y = 0, opacity = 1, radius = 100 }) => {
        uniforms.u_pos.value.x = x

        uniforms.u_pos.value.y = y

        uniforms.u_color.value.w = opacity

        uniforms.u_radius.value = radius

        fsQuad.material = material

        fsQuad.render(renderer)
    }
}

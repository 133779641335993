const POINTER_AREA = 0.003

export default function getHits(x, y, width, height, coordinates, camera) {
    const pX = (x / width) * 2 - 1

    const pY = -(y / height) * 2 + 1

    const hits = []

    const distances = new WeakMap()

    for (let i = 0; i < coordinates.length; i++) {
        const pos = coordinates[i].clone()

        pos.project(camera)

        const dx = pos.x - pX
        const dy = pos.y - pY
        const dist = dx * dx + dy * dy

        if (dist < POINTER_AREA) {
            const found = coordinates[i]
            distances.set(found, dist)
            hits.push(found)

            if (hits.length > 2) {
                break
            }
        }
    }

    if (hits.length > 1) {
        hits.sort((a, b) => distances.get(a) - distances.get(b))
    }

    return hits
}

import React from 'react'
import styled, { css } from 'styled-components'
import { MqDesktop, MqTablet, Semibold } from 'streamr-ui/css/consts'
import tilesTopDesktop from '../assets/tiles_top_desktop.png'
import tilesTopDesktop2x from '../assets/tiles_top_desktop@2x.png'
import tilesTopTablet from '../assets/tiles_top_tablet.png'
import tilesTopTablet2x from '../assets/tiles_top_tablet@2x.png'

export default function Persona({
    title = 'Untitled persona',
    desc = 'Lorem ipsum dolor sit emat',
    image,
    tabletImage,
    backgroundColors,
    callToAction,
}) {
    return (
        <Root $bgColors={backgroundColors}>
            <ImageWrap $appear>
                <img src={tilesTopDesktop} srcSet={`${tilesTopDesktop2x} 2x`} alt="Tiles" />
                {image}
            </ImageWrap>
            <TabletImageWrap>
                <img src={tilesTopTablet} srcSet={`${tilesTopTablet2x} 2x`} alt="Tiles" />
                {tabletImage}
            </TabletImageWrap>
            <Content>
                <Inner>
                    <h2 $appear>{title}</h2>
                    {typeof desc === 'string' ? <p $appear>{desc}</p> : <>{desc}</>}
                    <CallToAction $appear>{callToAction}</CallToAction>
                </Inner>
            </Content>
        </Root>
    )
}

const Root = styled.div`
    background: ${({ $bgColors: [color0, color1] = ['red', 'red'] }) =>
        `linear-gradient(152.83deg, ${color0} 0%, ${color1} 100%)`};
    overflow: hidden;
    border-radius: 16px;
    margin: 0 auto;
    width: 100%;

    h2 {
        font-size: 1.75rem;
        font-weight: ${Semibold};
        line-height: 2.25rem;
        margin: 0 0 1.25rem;
    }

    p {
        line-height: 1.75rem;
        margin: 0;
    }

    p + p {
        margin-top: 1.75rem;
    }

    @media ${MqTablet} {
        background: ${({ $bgColors: [color0, color1] = ['red', 'red'] }) =>
            `linear-gradient(220deg, ${color0} 0%, ${color1} 100%)`};
    }

    @media ${MqDesktop} {
        background: ${({ $bgColors: [color0, color1] = ['red', 'red'] }) =>
            `linear-gradient(152.83deg, ${color0} 0%, ${color1} 100%)`};
    }
`

const Inner = styled.div``

const ImageWrap = styled.div`
    display: block;
    position: relative;
    height: 238px;

    img {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    @media ${MqTablet} {
        display: none;
    }

    @media ${MqDesktop} {
        display: block;
    }
`

const TabletImageWrap = styled.div`
    display: none;
    position: relative;

    img {
        position: absolute;
        right: -14rem;
        top: -6.5rem;
    }

    @media ${MqTablet} {
        display: block;
    }

    @media ${MqDesktop} {
        display: none;
    }
`

const Content = styled.div`
    padding: 3rem;

    a {
        width: 100%;
    }

    @media ${MqTablet} {
        align-items: center;
        display: flex;
        height: 25.5rem;
        padding: 0 2.75rem;

        a {
            width: 12.5rem;
        }

        ${Inner} {
            width: 272px;
        }
    }

    @media ${MqDesktop} {
        align-items: initial;
        display: block;
        height: auto;
        padding: 3rem;

        a {
            width: 100%;
        }

        ${Inner} {
            width: auto;
        }
    }
`

export const PersonaList = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    list-style: none;
    margin: 0;
    padding: 0;

    @media ${MqTablet} {
        gap: 2rem;
    }

    @media ${MqDesktop} {
        gap: 1.75rem;
        grid-template-columns: 1fr 1fr 1fr;
    }
`

const CallToAction = styled.div`
    margin-top: 3rem;
`

export const PersonaButtonPaint = css`
    --bg: #ffffff;
    --hoverBg: #ffffff;
    --activeBg: #ffffff;
    --text: #0324ff;
    --radius: 8px;
`

export const PersonaListColumn = styled.div`
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 100%;
    padding: 0 2rem;
    width: 24.5rem;

    @media (min-width: 28.5rem) {
        width: 34rem;
        padding: 0 4rem;
    }

    @media ${MqTablet} {
        padding: 0 4rem;
        width: 52.5rem;
    }

    @media ${MqDesktop} {
        width: 75.5rem;
        padding: 0 1.5rem;
    }
`

import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import copy from 'copy-to-clipboard'
import Ids from './Ids'
import keyize from './keyize'
import { lsGet, lsSet } from './storage'

export default function Ui({ onUpdate, params, onResetClick, enable }) {
    const [expand, setExpand] = useState(false)

    const [enabled, setEnabled] = useState(false)

    useEffect(
        /**
         * We have to trick styled-components to generate CSS for things
         * we don't normally show. Normally this effect wouldn't be
         * here at all. Ugly!
         */
        function updateEnabled() {
            setEnabled(!!enable)
        },
        [enable],
    )

    if (!enabled) {
        return null
    }

    return expand ? (
        <ParamsContext.Provider value={params}>
            <Controls
                params={params}
                onUpdate={onUpdate}
                onCloseClick={() => {
                    setExpand(false)
                }}
                onCopyClick={() => {
                    copy(JSON.stringify(params))
                }}
                onResetClick={onResetClick}
            />
        </ParamsContext.Provider>
    ) : (
        <ToggleWrap>
            <button type="button" onClick={() => setExpand(true)}>
                Customize
            </button>
        </ToggleWrap>
    )
}

const ToggleWrap = styled.div`
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 3;
`

const Form = styled.form`
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 2px 5px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    padding: 1.5rem 0 5.5rem;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.98);
    min-width: 250px;
    max-height: 600px;
    overflow: auto;

    h3 {
        margin: 0;
    }

    hr {
        border: 0;
        height: 1px;
        background: #bbb;
        margin: 0.5rem 0;
    }
`

const Label = styled.label`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;

    & + & {
        margin-top: 0.5em;
    }
`

function useOnChange(id, onChange, fn = (x) => x) {
    return useCallback(
        (e) => {
            const { value } = e.target

            if (typeof onChange === 'function') {
                onChange(id, fn(value))
            }
        },
        [id, onChange, fn],
    )
}

function Control({ label, children }) {
    return (
        <Label>
            <div>{label}</div>
            <div>{children}</div>
        </Label>
    )
}

const ParamsContext = createContext({})

function useValue(id) {
    return useContext(ParamsContext)[id]
}

function Color({ label, id, onChange: onChangeProp }) {
    const onChange = useOnChange(id, onChangeProp)

    const value = useValue(id) || '#ff0000'

    return (
        <Control label={label}>
            <input type="color" value={value} onChange={onChange} />
        </Control>
    )
}

function Number({ label, id, onChange: onChangeProp, min = 0, max = 1, step = 0.05 }) {
    const onChange = useOnChange(id, onChangeProp, (x) => +x)

    const value = useValue(id)

    return (
        <Control label={label}>
            <input
                type="number"
                step={step}
                min={min}
                max={max}
                value={value == null ? 0.5 : value}
                onChange={onChange}
            />
        </Control>
    )
}

function Checkbox({ label, id, onChange: onChangeProp }) {
    const value = useValue(id)

    return (
        <Control label={label}>
            <input
                type="checkbox"
                onChange={() => {
                    onChangeProp(id, !value)
                }}
                checked={value}
            />
        </Control>
    )
}

const GroupWrap = styled.div`
    padding: 0 1.5rem;
`

const GroupToggle = styled.button`
    appearance: none;
    background: none;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    text-align: left;
    font-weight: bold;
    position: relative;

    & + div {
        padding: 1rem 0;
    }

    > span {
        display: inline-block;
        width: 0.5rem;
        position: absolute;
        left: -0.9rem;
    }
`

function Group({ label, children }) {
    const lsKey = keyize(label)

    const [expand, setExpand] = useState(Boolean(lsGet(lsKey, false)))

    useEffect(() => {
        lsSet(lsKey, expand ? true : undefined)
    }, [expand, lsKey])

    return (
        <GroupWrap>
            <GroupToggle
                type="button"
                onClick={() => {
                    setExpand((c) => !c)
                }}
            >
                <span>{expand ? '-' : '+'}</span> {label}
            </GroupToggle>
            {expand && <div>{children}</div>}
        </GroupWrap>
    )
}

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1rem;
    padding: 0 1.5rem;
`

const Wrap = styled.div`
    color: black;
    position: fixed;
    width: 260px;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
`

function Controls({ onCloseClick, onUpdate, onCopyClick, onResetClick }) {
    return (
        <Wrap>
            <Form onSubmit={(e) => e.preventDefault()}>
                <Header>
                    <h3>Colors</h3>
                    <button type="button" onClick={onCloseClick}>
                        &times;
                    </button>
                </Header>
                <Group label="Map">
                    <Color label="Ocean" id={Ids.MAP_OCEAN} onChange={onUpdate} />
                    <Color label="Land" id={Ids.MAP_LAND} onChange={onUpdate} />
                </Group>
                <hr />
                <Group label="Lines">
                    <Color label="Color" id={Ids.LINES_COLOR} onChange={onUpdate} />
                    <Color label="Shadow color" id={Ids.LINES_SHADOW_COLOR} onChange={onUpdate} />
                </Group>
                <hr />
                <Group label="Nodes">
                    <Color label="Default color" id={Ids.NODES_COLOR} onChange={onUpdate} />
                    <Color
                        label="Hightlight color"
                        id={Ids.NODES_HIGHLIGHTED_COLOR}
                        onChange={onUpdate}
                    />
                    <Color
                        label="Selected color"
                        id={Ids.NODES_SELECTED_COLOR}
                        onChange={onUpdate}
                    />
                    <Color
                        label="Connected color"
                        id={Ids.NODES_CONNECTED_COLOR}
                        onChange={onUpdate}
                    />
                </Group>
                <hr />
                <Group label="Grid">
                    <Number
                        id={Ids.DOTS_TILE_SIZE}
                        label="Tile size"
                        max={1000}
                        min={5}
                        onChange={onUpdate}
                        step={5}
                    />
                    <Number
                        id={Ids.DOTS_SIZE}
                        label="Size"
                        max={20}
                        min={0.6}
                        onChange={onUpdate}
                        step={0.1}
                    />
                    <Color id={Ids.DOTS_COLOR} label="Color" onChange={onUpdate} />
                    <Number
                        id={Ids.DOTS_INTENSITY}
                        label="Intensity"
                        max={1}
                        min={0.01}
                        onChange={onUpdate}
                        step={0.01}
                    />
                    <Number
                        id={Ids.DOTS_GLITTER_SPEED}
                        label="Glitter speed"
                        max={100}
                        min={0.5}
                        onChange={onUpdate}
                        step={1}
                    />
                    <Number
                        id={Ids.DOTS_GLITTER_INTENSITY}
                        label="Glitter intensity"
                        max={1}
                        min={0}
                        onChange={onUpdate}
                        step={0.01}
                    />
                </Group>
                <hr />
                <Group label="Mask">
                    <Color label="Color" id={Ids.MASK_COLOR} onChange={onUpdate} />
                    <Number
                        label="Coverage"
                        id={Ids.MASK_COVERAGE}
                        onChange={onUpdate}
                        min={0}
                        max={1}
                        step={0.01}
                    />
                    <Number
                        label="Curvature"
                        id={Ids.MASK_CURVATURE}
                        onChange={onUpdate}
                        min={0}
                        max={1}
                        step={0.01}
                    />
                </Group>
                <hr />
                <Group label="Mask backdrop">
                    <Color label="Color" id={Ids.MASKBACKDROP_COLOR} onChange={onUpdate} />
                    <Number label="Intensity" id={Ids.MASKBACKDROP_INTENSITY} onChange={onUpdate} />
                </Group>
                <hr />
                <Group label="Outer glow">
                    <Color label="Color" id={Ids.OUTERGLOW_COLOR} onChange={onUpdate} />
                    <Number label="Width" id={Ids.OUTERGLOW_WIDTH} onChange={onUpdate} max={5} />
                </Group>
                <hr />
                <Group label="Inner glow">
                    <Color label="Color" id={Ids.INNERGLOW_COLOR} onChange={onUpdate} />
                    <Number label="Width" id={Ids.INNERGLOW_WIDTH} onChange={onUpdate} max={5} />
                </Group>
                <hr />
                <Group label="Spotlight 1">
                    <Checkbox label="Enabled" id={Ids.SPOTLIGHT1_ENABLE} onChange={onUpdate} />
                    <Color label="Color" id={Ids.SPOTLIGHT1_COLOR} onChange={onUpdate} />
                    <Number
                        label="Intensity (initial)"
                        id={Ids.SPOTLIGHT1_INTENSITYA}
                        onChange={onUpdate}
                    />
                    <Number
                        label="Intensity (final)"
                        id={Ids.SPOTLIGHT1_INTENSITYB}
                        onChange={onUpdate}
                    />
                    <Number
                        label="Radius (initial)"
                        id={Ids.SPOTLIGHT1_RADIUSA}
                        onChange={onUpdate}
                    />
                    <Number
                        label="Radius (final)"
                        id={Ids.SPOTLIGHT1_RADIUSB}
                        onChange={onUpdate}
                    />
                    <Number
                        label="x (initial)"
                        id={Ids.SPOTLIGHT1_XA}
                        onChange={onUpdate}
                        min={-2}
                        max={2}
                    />
                    <Number
                        label="y (initial)"
                        id={Ids.SPOTLIGHT1_YA}
                        onChange={onUpdate}
                        min={-2}
                        max={2}
                    />
                    <Number
                        label="x (final)"
                        id={Ids.SPOTLIGHT1_XB}
                        onChange={onUpdate}
                        min={-2}
                        max={2}
                    />
                    <Number
                        label="y (final)"
                        id={Ids.SPOTLIGHT1_YB}
                        onChange={onUpdate}
                        min={-2}
                        max={2}
                    />
                </Group>
                <hr />
                <Group label="Spotlight 2">
                    <Checkbox label="Enabled" id={Ids.SPOTLIGHT2_ENABLE} onChange={onUpdate} />

                    <Color label="Color" id={Ids.SPOTLIGHT2_COLOR} onChange={onUpdate} />
                    <Number
                        label="Intensity (initial)"
                        id={Ids.SPOTLIGHT2_INTENSITYA}
                        onChange={onUpdate}
                    />
                    <Number
                        label="Intensity (final)"
                        id={Ids.SPOTLIGHT2_INTENSITYB}
                        onChange={onUpdate}
                    />
                    <Number
                        label="Radius (initial)"
                        id={Ids.SPOTLIGHT2_RADIUSA}
                        onChange={onUpdate}
                    />
                    <Number
                        label="Radius (final)"
                        id={Ids.SPOTLIGHT2_RADIUSB}
                        onChange={onUpdate}
                    />
                    <Number label="x (initial)" id={Ids.SPOTLIGHT2_XA} onChange={onUpdate} />
                    <Number label="y (initial)" id={Ids.SPOTLIGHT2_YA} onChange={onUpdate} />
                    <Number label="x (final)" id={Ids.SPOTLIGHT2_XB} onChange={onUpdate} />
                    <Number label="y (final)" id={Ids.SPOTLIGHT2_YB} onChange={onUpdate} />
                </Group>
                <hr />
                <Group label="Spotlight 3">
                    <Checkbox label="Enabled" id={Ids.SPOTLIGHT3_ENABLE} onChange={onUpdate} />

                    <Color label="Color" id={Ids.SPOTLIGHT3_COLOR} onChange={onUpdate} />
                    <Number
                        label="Intensity (initial)"
                        id={Ids.SPOTLIGHT3_INTENSITYA}
                        onChange={onUpdate}
                    />
                    <Number
                        label="Intensity (final)"
                        id={Ids.SPOTLIGHT3_INTENSITYB}
                        onChange={onUpdate}
                    />
                    <Number
                        label="Radius (initial)"
                        id={Ids.SPOTLIGHT3_RADIUSA}
                        onChange={onUpdate}
                    />
                    <Number
                        label="Radius (final)"
                        id={Ids.SPOTLIGHT3_RADIUSB}
                        onChange={onUpdate}
                    />
                    <Number label="x (initial)" id={Ids.SPOTLIGHT3_XA} onChange={onUpdate} />
                    <Number label="y (initial)" id={Ids.SPOTLIGHT3_YA} onChange={onUpdate} />
                    <Number label="x (final)" id={Ids.SPOTLIGHT3_XB} onChange={onUpdate} />
                    <Number label="y (final)" id={Ids.SPOTLIGHT3_YB} onChange={onUpdate} />
                </Group>
            </Form>
            <CopyWrap>
                <div>
                    <button type="button" onClick={onCopyClick}>
                        Copy
                    </button>
                    <button type="button" onClick={onResetClick}>
                        Reset
                    </button>
                </div>
                <p>Press Ctrl+V or Cmd+V to paste.</p>
            </CopyWrap>
        </Wrap>
    )
}

const CopyWrap = styled.div`
    box-sizing: border-box;
    background: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: 1px solid #bbb;
    padding: 1.5rem 1.5rem;
    width: 100%;
    bottom: 0;
    position: absolute;

    > div {
        display: flex;
    }

    button {
        height: 2rem;
        flex-basis: 50%;
    }

    button + button {
        margin-left: 1rem;
    }

    p {
        opacity: 0.7;
        position: absolute;
        font-size: 11px;
        margin: 0.5rem 0 0;
        text-align: center;
        width: 100%;
        padding: 0;
        left: 0;
        bottom: 0.35rem;
    }
`

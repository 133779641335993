import React from 'react'
import { MqDesktop } from 'streamr-ui/css/consts'
import styled from 'styled-components'

export default function ExternalLink({ href, children }) {
    return (
        <Root href={href} rel="noopener noreferrer" target="_blank">
            {children}
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.375 8.03906C9.375 7.7284 9.62684 7.47656 9.9375 7.47656H15.9375C16.2482 7.47656 16.5 7.7284 16.5 8.03906V14.0391C16.5 14.3497 16.2482 14.6016 15.9375 14.6016C15.6268 14.6016 15.375 14.3497 15.375 14.0391V8.60156H9.9375C9.62684 8.60156 9.375 8.34972 9.375 8.03906Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.3352 7.64131C16.5549 7.86098 16.5549 8.21714 16.3352 8.43681L8.08525 16.6868C7.86558 16.9065 7.50942 16.9065 7.28975 16.6868C7.07008 16.4671 7.07008 16.111 7.28975 15.8913L15.5398 7.64131C15.7594 7.42165 16.1156 7.42165 16.3352 7.64131Z"
                    fill="white"
                />
            </svg>
        </Root>
    )
}

const Root = styled.a`
    align-items: center;
    display: inline-flex;
    overflow: visible;
    text-decoration: none;
    font-size: 0, 875rem;

    > span {
        display: none;
        white-space: pre-wrap;
    }

    svg {
        display: block;
        margin-left: 0.4em;
    }

    @media ${MqDesktop} {
        font-size: 1.125rem;

        > span {
            display: inline;
        }
    }
`

import { Color } from 'three'
import NodeMaterial from './material/NodeMaterial'
import NodeMirrorMaterial from './material/NodeMirrorMaterial'
import getParticles from './utils/getParticles'
import expect from './utils/expect'
import defaultTheme from './defaultTheme'
import Ids from './Cloak/Ids'

export const POINT_SCALE = 0.009

const HIGHLIGHT_SCALE = 1.25

export const Y_POS = 0.0125

const HIGHLIGHT_Y = 0.01

const ACTIVE_VALUE = 1

const DEACTIVE_VALUE = 0

export default function Particles({
    onBeforeQualityChange,
    onAfterQualityChange,
    onIntroDone,
    timer,
}) {
    let defaultColor = new Color(defaultTheme[Ids.NODES_COLOR])

    let selectColor = new Color(defaultTheme[Ids.NODES_SELECTED_COLOR])

    let highlightColor = new Color(defaultTheme[Ids.NODES_HIGHLIGHTED_COLOR])

    let connectedColor = new Color(defaultTheme[Ids.NODES_CONNECTED_COLOR])

    let introDone = false

    const particleMaterial = new NodeMaterial()

    this.getParticleMaterial = () => particleMaterial

    const mirrorMaterial = new NodeMirrorMaterial()

    const activePoints = new WeakMap()

    const highlightPoints = new WeakMap()

    const selectPoints = new WeakMap()

    let currentTime = 0

    let introTime = 100

    let coordinates = []

    let qualityLevel

    let highQualityParticle = null

    let lowQualityParticle = null

    let particle = null

    let positions

    const updatePoint = (point, attrNames, eachFn, { needsUpdate = true } = {}) => {
        expect(particle, 'Expected `particle` to be defined. Forgot to call `setQuality`?')

        const { piece, instance, verticeSize } = particle

        const attrs = attrNames.map((n) => instance.geometry.attributes[n])

        if (typeof eachFn === 'function') {
            const c = positions.get(point) * verticeSize
            const attribCount = piece.attributes.position.count

            for (let i = 0; i < attribCount; i++) {
                const cx = c + i * 4

                eachFn(cx, attrs)
            }
        }

        if (needsUpdate) {
            attrs.forEach((attr) => {
                attr.needsUpdate = true
            })
        }
    }

    this.setDefaultColor = (newColor) => {
        defaultColor = newColor

        coordinates.forEach((point) => {
            if (activePoints.get(point) || highlightPoints.get(point) || selectPoints.get(point)) {
                return
            }

            updatePoint(point, ['color'], (i, [color]) => {
                color.array[i] = newColor.r
                color.array[i + 1] = newColor.g
                color.array[i + 2] = newColor.b
                color.array[i + 3] = 0
            })
        })
    }

    this.setHighlightColor = (newColor) => {
        highlightColor = newColor

        coordinates.forEach((point) => {
            if (!highlightPoints.get(point) || selectPoints.get(point)) {
                return
            }

            updatePoint(point, ['color'], (i, [color]) => {
                color.array[i] = newColor.r
                color.array[i + 1] = newColor.g
                color.array[i + 2] = newColor.b
                color.array[i + 3] = 0
            })
        })
    }

    this.setSelectColor = (newColor) => {
        selectColor = newColor

        coordinates.forEach((point) => {
            if (!selectPoints.get(point)) {
                return
            }

            updatePoint(point, ['color'], (i, [color]) => {
                color.array[i] = newColor.r
                color.array[i + 1] = newColor.g
                color.array[i + 2] = newColor.b
                color.array[i + 3] = 0
            })
        })
    }

    this.setConnectedColor = (newColor) => {
        connectedColor = newColor

        coordinates.forEach((point) => {
            if (!activePoints.get(point)) {
                return
            }

            updatePoint(point, ['color'], (i, [color]) => {
                color.array[i] = newColor.r
                color.array[i + 1] = newColor.g
                color.array[i + 2] = newColor.b
                color.array[i + 3] = 0
            })
        })
    }

    const resetAttributes = () => {
        coordinates.forEach((point) => {
            let c

            switch (true) {
                case selectPoints.get(point):
                    c = selectColor
                    break
                case activePoints.get(point):
                    c = connectedColor
                    break
                case highlightPoints.get(point):
                    c = highlightColor
                    break
                default:
                    c = defaultColor
            }

            updatePoint(
                point,
                ['startPos', 'endPos', 'extra', 'color'],
                (i, [startPos, endPos, extra, color]) => {
                    startPos.array[i] = point.x
                    startPos.array[i + 1] = Y_POS
                    startPos.array[i + 2] = point.z
                    startPos.array[i + 3] = currentTime

                    endPos.array[i] = point.x
                    endPos.array[i + 1] = Y_POS
                    endPos.array[i + 2] = point.z
                    endPos.array[i + 3] = 2.0 // speed

                    extra.array[i] = POINT_SCALE
                    extra.array[i + 1] = POINT_SCALE
                    extra.array[i + 2] = DEACTIVE_VALUE
                    extra.array[i + 3] = currentTime

                    color.array[i] = c.r
                    color.array[i + 1] = c.g
                    color.array[i + 2] = c.b
                    color.array[i + 3] = 0
                },
            )
        })
    }

    this.setQuality = (level, { reset = true } = {}) => {
        if (level === qualityLevel) {
            return
        }

        if (typeof onBeforeQualityChange === 'function') {
            onBeforeQualityChange(particle)
        }

        if (level > 0) {
            if (!highQualityParticle) {
                highQualityParticle = getParticles(
                    coordinates.length,
                    particleMaterial,
                    mirrorMaterial,
                    {
                        highQuality: true,
                    },
                )
            }

            particle = highQualityParticle
        } else {
            if (!lowQualityParticle) {
                lowQualityParticle = getParticles(
                    coordinates.length,
                    particleMaterial,
                    mirrorMaterial,
                    {
                        highQuality: false,
                    },
                )
            }

            particle = lowQualityParticle
        }

        if (reset) {
            resetAttributes()
        }

        if (typeof onAfterQualityChange === 'function') {
            onAfterQualityChange(particle)
        }

        qualityLevel = level
    }

    this.dehighlight = (point) => {
        if (activePoints.get(point)) {
            return
        }

        const time = timer.getTime()

        highlightPoints.delete(point)

        updatePoint(
            point,
            ['color', 'startPos', 'endPos', 'extra'],
            (i, [color, startPos, endPos, extra]) => {
                color.array[i] = defaultColor.r
                color.array[i + 1] = defaultColor.g
                color.array[i + 2] = defaultColor.b
                color.array[i + 3] = 0

                startPos.array[i + 1] = Y_POS + HIGHLIGHT_Y
                startPos.array[i + 3] = time

                endPos.array[i + 1] = Y_POS
                endPos.array[i + 3] = 5.0

                extra.array[i] = POINT_SCALE
                extra.array[i + 1] = POINT_SCALE * HIGHLIGHT_SCALE
                extra.array[i + 3] = time
            },
        )
    }

    this.highlight = (point) => {
        if (activePoints.get(point)) {
            return false
        }

        const time = timer.getTime()

        highlightPoints.set(point, true)

        updatePoint(
            point,
            ['color', 'startPos', 'endPos', 'extra'],
            (i, [color, startPos, endPos, extra]) => {
                color.array[i] = highlightColor.r
                color.array[i + 1] = highlightColor.g
                color.array[i + 2] = highlightColor.b
                color.array[i + 3] = 0

                startPos.array[i + 1] = Y_POS
                startPos.array[i + 3] = time

                endPos.array[i + 1] = Y_POS + HIGHLIGHT_Y
                endPos.array[i + 3] = 5.0

                extra.array[i] = POINT_SCALE * HIGHLIGHT_SCALE
                extra.array[i + 1] = POINT_SCALE
                extra.array[i + 3] = time
            },
        )

        return true
    }

    this.down = (point) => {
        const time = timer.getTime()

        updatePoint(point, ['color', 'startPos', 'endPos'], (i, [color, startPos, endPos]) => {
            color.array[i] = highlightColor.r
            color.array[i + 1] = highlightColor.g
            color.array[i + 2] = highlightColor.b

            startPos.array[i + 1] = Y_POS + HIGHLIGHT_Y
            startPos.array[i + 3] = time

            endPos.array[i + 1] = Y_POS - 0.005
            endPos.array[i + 3] = 5.0
        })
    }

    this.select = (point) => {
        const time = timer.getTime()

        selectPoints.set(point, true)

        highlightPoints.delete(point)

        updatePoint(point, ['color', 'endPos', 'extra'], (i, [color, endPos, extra]) => {
            endPos.array[i + 1] = Y_POS
            endPos.array[i + 3] = 8.0

            extra.array[i] = POINT_SCALE * 1.0
            extra.array[i + 1] = POINT_SCALE * 0.5
            extra.array[i + 2] = DEACTIVE_VALUE
            extra.array[i + 3] = time

            color.array[i] = selectColor.r
            color.array[i + 1] = selectColor.g
            color.array[i + 2] = selectColor.b
            color.array[i + 3] = 0
        })
    }

    this.deselect = (point) => {
        const time = timer.getTime()

        selectPoints.delete(point)

        updatePoint(point, ['extra'], (i, [extra]) => {
            extra.array[i] = POINT_SCALE * 0.75
            extra.array[i + 1] = POINT_SCALE * 1.0
            extra.array[i + 2] = DEACTIVE_VALUE
            extra.array[i + 3] = time
        })

        setTimeout(() => {
            const isActive = activePoints.get(point)

            updatePoint(point, ['color', 'extra'], (i, [color, extra]) => {
                extra.array[i] = POINT_SCALE * 1.0
                extra.array[i + 1] = POINT_SCALE * 0.75
                extra.array[i + 2] = Number(!!isActive)
                extra.array[i + 3] = currentTime

                color.array[i] = defaultColor.r
                color.array[i + 1] = defaultColor.g
                color.array[i + 2] = defaultColor.b
                color.array[i + 3] = 0
            })
        }, 350)
    }

    this.active = (point) => {
        activePoints.set(point, true)

        highlightPoints.delete(point)

        updatePoint(point, ['color', 'extra'], (i, [color, extra]) => {
            extra.array[i + 2] = ACTIVE_VALUE

            color.array[i] = connectedColor.r
            color.array[i + 1] = connectedColor.g
            color.array[i + 2] = connectedColor.b
            color.array[i + 3] = 0
        })
    }

    this.deactive = (point) => {
        activePoints.delete(point)

        updatePoint(point, ['color', 'extra'], (i, [color, extra]) => {
            extra.array[i + 2] = DEACTIVE_VALUE

            color.array[i] = defaultColor.r
            color.array[i + 1] = defaultColor.g
            color.array[i + 2] = defaultColor.b
            color.array[i + 3] = 0
        })
    }

    let ready = false

    let introTimes

    this.setCoordinates = (_coordinates, timing) => {
        ready = false

        coordinates = [..._coordinates]

        introTimes = new WeakMap()

        positions = new WeakMap()

        this.setQuality(1, {
            reset: false,
        })

        const { quantity } = particle

        coordinates.forEach((point, position) => {
            introTime = currentTime + 1.0 + timing.get(point)

            introTimes.set(point, introTime)

            positions.set(point, position % quantity)

            updatePoint(
                point,
                ['color', 'startPos', 'endPos', 'extra'],
                (i, [color, startPos, endPos, extra]) => {
                    startPos.array[i] = point.x
                    startPos.array[i + 1] = 0.15
                    startPos.array[i + 2] = point.z
                    startPos.array[i + 3] = introTime

                    endPos.array[i] = point.x
                    endPos.array[i + 1] = Y_POS
                    endPos.array[i + 2] = point.z
                    endPos.array[i + 3] = 2.0 // speed

                    extra.array[i] = POINT_SCALE
                    extra.array[i + 1] = 0
                    extra.array[i + 2] = DEACTIVE_VALUE
                    extra.array[i + 3] = introTime

                    color.array[i] = defaultColor.r
                    color.array[i + 1] = defaultColor.g
                    color.array[i + 2] = defaultColor.b
                    color.array[i + 3] = 0
                },
                {
                    // Postpone the update till we got all the points in.
                    needsUpdate: false,
                },
            )
        })

        introTime += 0.6

        // Sort to get better hover and click
        coordinates.sort((a, b) => b.z - a.z)

        updatePoint(null, ['color', 'startPos', 'endPos', 'extra'], null)

        ready = true
    }

    this.getCoordinates = () => coordinates

    this.update = () => {
        const time = timer.getTime()

        currentTime = time

        if (!ready) {
            // `update` can happend before all the points are in. Let's prevent that.
            return
        }

        particleMaterial.setTime(time)
        mirrorMaterial.setTime(time)

        if (!introDone && time > introTime) {
            onIntroDone()
            introDone = true
        }
    }

    this.isIntroDone = (point = null) => {
        const time = timer.getTime()

        if (point) {
            // +1s because we take the estimated duration into account.
            return (introTimes.get(point) || Number.POSITIVE_INFINITY) + 1.0 < time
        }

        return introTime < time
    }
}

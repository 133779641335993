import React from 'react'
import styled from 'styled-components'

// $appear does not work on buttons. Breaks their on-hover `scale` transformation. Wrap any with the
// wrapper and you're all set.

const UnstyledButtonWrapper = ({ noAppear, ...props }) =>
    noAppear ? <div {...props} /> : <div {...props} $appear />

const ButtonWrapper = styled(UnstyledButtonWrapper)`
    display: inline-flex;
`

export default ButtonWrapper

import { Vector4, Color, Mesh } from 'three'
import { mergeBufferGeometries } from 'three-stdlib/utils/BufferGeometryUtils'
import LineMaterial from './material/LineMaterial'
import { Attribute, getQuadGeometry } from './utils/geometry'
import injectRenderPipeline from './utils/injectRenderPipeline'

const Y_POS = 0.015

const LINE_WIDTH = 0.002

function getAngle(x0, y0, x1, y1) {
    return Math.atan2(y1 - y0, x1 - x0)
}

function getLineInstance(geometry, material, totalLines) {
    const mergedGeometry = []

    for (let i = 0; i < totalLines; i++) {
        mergedGeometry.push(geometry.clone())
    }

    const line = new Mesh(mergeBufferGeometries(mergedGeometry, false), material)

    line.castShadow = false
    line.receiveShadow = false
    line.position.set(0, 0, 0)
    line.frustumCulled = false

    return line
}

export default function Lines(
    scene,
    renderPipeline,
    maxLines,
    _lineColor = new Color(0xffa530),
    _shadowColor = new Color(0xbfc2c9),
) {
    let connections

    let lineColor = _lineColor

    let shadowColor = _shadowColor

    const geometry = getQuadGeometry(
        1.0,
        0.0,
        0.0,
        [
            new Attribute('startPos'),
            new Attribute('endPos'),
            new Attribute('extra'),
            new Attribute('lineColor', new Vector4(0, 0, 1, 0)),
            new Attribute('color'),
        ],
        false,
    )

    const geometryVerticeSize = geometry.attributes.position.count * 4

    const material = new LineMaterial()

    injectRenderPipeline(renderPipeline, material)

    const lineInstance = getLineInstance(geometry, material, maxLines * 2)

    scene.add(lineInstance)

    const addLineAt = (index, time) => {
        const [A, B] = connections[index]
        const angle = getAngle(A.x, A.z, B.x, B.z)
        const sinWidth = LINE_WIDTH * Math.sin(angle)
        const cosWidth = LINE_WIDTH * Math.cos(angle)
        const speed = 1.0
        const attribCount = geometry.attributes.position.count
        const x = [A.x + sinWidth, A.x - sinWidth, B.x + sinWidth, B.x - sinWidth]
        const z = [A.z - cosWidth, A.z + cosWidth, B.z - cosWidth, B.z + cosWidth]
        const xFrom = [A.x + sinWidth, A.x - sinWidth, A.x + sinWidth, A.x - sinWidth]
        const zFrom = [A.z - cosWidth, A.z + cosWidth, A.z - cosWidth, A.z + cosWidth]
        const attribStartPos = lineInstance.geometry.attributes.startPos
        const attribEndPos = lineInstance.geometry.attributes.endPos
        const attribColor = lineInstance.geometry.attributes.lineColor
        const Y = [0.0001, Y_POS]

        Y.forEach((y, j) => {
            const color = j === 0 ? shadowColor : lineColor
            const c = (index * 2 + (1 - j)) * geometryVerticeSize

            for (let i = 0; i < attribCount; i++) {
                const cx = c + i * 4

                attribStartPos.array[cx] = xFrom[i]
                attribStartPos.array[cx + 1] = y
                attribStartPos.array[cx + 2] = zFrom[i]
                attribStartPos.array[cx + 3] = time

                attribEndPos.array[cx] = x[i]
                attribEndPos.array[cx + 1] = y
                attribEndPos.array[cx + 2] = z[i]
                attribEndPos.array[cx + 3] = speed

                attribColor.array[cx] = color.r
                attribColor.array[cx + 1] = color.g
                attribColor.array[cx + 2] = color.b
                attribColor.array[cx + 3] = j // 0 => shadow
            }
        })
    }

    const updateGeom = () => {
        lineInstance.geometry.attributes.startPos.needsUpdate = true
        lineInstance.geometry.attributes.endPos.needsUpdate = true
        lineInstance.geometry.attributes.lineColor.needsUpdate = true
    }

    const removeLineAt = (index, time) => {
        const [A, B] = connections[index]
        const speed = 2.0
        const attribCount = geometry.attributes.position.count
        const angle = getAngle(A.x, A.z, B.x, B.z)
        const sinWidth = LINE_WIDTH * Math.sin(angle)
        const cosWidth = LINE_WIDTH * Math.cos(angle)

        const x = [B.x + sinWidth, B.x - sinWidth, A.x + sinWidth, A.x - sinWidth]
        const z = [B.z - cosWidth, B.z + cosWidth, A.z - cosWidth, A.z + cosWidth]

        const xFrom = [B.x + sinWidth, B.x - sinWidth, B.x + sinWidth, B.x - sinWidth]
        const zFrom = [B.z - cosWidth, B.z + cosWidth, B.z - cosWidth, B.z + cosWidth]

        const attribStartPos = lineInstance.geometry.attributes.startPos
        const attribEndPos = lineInstance.geometry.attributes.endPos

        for (let j = 0; j < 2; j++) {
            const c = (index * 2 + (1 - j)) * geometryVerticeSize

            for (let i = 0; i < attribCount; i++) {
                const cx = c + i * 4

                attribStartPos.array[cx] = x[i]
                attribStartPos.array[cx + 2] = z[i]
                attribStartPos.array[cx + 3] = time

                attribEndPos.array[cx] = xFrom[i]
                attribEndPos.array[cx + 2] = zFrom[i]
                attribEndPos.array[cx + 3] = speed
            }
        }
    }

    this.setColor = (color, { shadow = false } = {}) => {
        if (shadow) {
            shadowColor = color
        } else {
            lineColor = color
        }

        if (!connections) {
            return
        }

        const attribColor = lineInstance.geometry.attributes.lineColor
        const attribCount = geometry.attributes.position.count
        const j = 1 - Number(shadow)

        for (let i = 0; i < connections.length; i++) {
            const c = (i * 2 + (1 - j)) * geometryVerticeSize

            for (let k = 0; k < attribCount; k++) {
                const cx = c + k * 4

                attribColor.array[cx] = color.r
                attribColor.array[cx + 1] = color.g
                attribColor.array[cx + 2] = color.b
                attribColor.array[cx + 3] = j // 0 => shadow
            }
        }

        attribColor.needsUpdate = true
    }

    this.update = (time) => {
        material.setTime(time)
    }

    this.setTopology = (time, topology) => {
        if (connections) {
            for (let i = 0; i < connections.length; i++) {
                removeLineAt(i, time)
            }

            updateGeom()
        }

        if (!topology) {
            connections = undefined
            return
        }

        connections = topology

        for (let i = 0; i < connections.length; i++) {
            addLineAt(i, time)
        }

        updateGeom()
    }
}

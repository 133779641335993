import {
    Color,
    Group,
    Mesh,
    MeshPhongMaterial,
    OrthographicCamera,
    PlaneGeometry,
    ShaderMaterial,
    TextureLoader,
    Vector2,
    Vector3,
} from 'three'
import gsap from 'gsap'
import { withAssetPrefix } from 'gatsby'
import injectRenderPipeline from './injectRenderPipeline'
import getRenderTarget from './getRenderTarget'

export default function Earth() {
    let lastLandColor

    let lastOceanColor

    const normalScale = new Vector2(0, 0)

    const materials = [
        new MeshPhongMaterial({
            normalScale,
            depthWrite: false,
        }),
        new MeshPhongMaterial({
            normalScale,
            depthWrite: false,
        }),
        new MeshPhongMaterial({
            normalScale,
            depthWrite: false,
        }),
        new MeshPhongMaterial({
            normalScale,
            depthWrite: false,
        }),
    ]

    this.getMaterials = () => materials

    this.inject = (pipeline) => {
        materials.forEach((m) => {
            injectRenderPipeline(pipeline, m)
        })
    }

    const oceanMaterial = new ShaderMaterial({
        vertexShader: `
            varying vec2 vUv;

            void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `,
        fragmentShader: `
            uniform vec3 u_ocean_color;

            uniform vec3 u_land_color;

            uniform sampler2D u_texture;

            varying vec2 vUv;

            float rand(vec2 co){
                return fract(sin(dot(co, vec2(12.9898, 78.233))) * 43758.5453);
            }

            void main() {
                float sand = (rand(gl_FragCoord.xy) - 0.5) / 50.0;
                gl_FragColor = vec4(mix(u_ocean_color, u_land_color, texture2D(u_texture, vUv).r), 1.0) + sand;

            }
        `,
        uniforms: {
            u_ocean_color: {
                type: 'v3',
                value: new Vector3(),
            },
            u_texture: {
                type: 't',
                value: null,
            },
            u_land_color: {
                type: 'v3',
                value: new Vector3(),
            },
        },
    })

    const camera = new OrthographicCamera(-2, 2, 1, -1, 0, 1)

    const geom = new PlaneGeometry(4, 2)

    let textures

    this.loadContours = async ({ anisotropy }) => {
        try {
            textures = await Promise.all(
                materials.map(
                    (_, i) =>
                        // eslint-disable-next-line implicit-arrow-linebreak
                        new Promise((resolve, reject) => {
                            new TextureLoader().load(
                                withAssetPrefix(`/models/globe_texture_color${i + 1}.png`),
                                (texture) => {
                                    texture.flipY = true
                                    texture.anisotropy = anisotropy

                                    resolve(texture)
                                },
                                null,
                                reject,
                            )
                        }),
                ),
            )
        } catch (e) {
            console.warn('Failed to load contours.', e)
        }
    }

    const targets = materials.map((_, i) =>
        getRenderTarget(`texture${i}`, {
            x: 2048,
            y: 1024,
        }),
    )

    const oceanPlane = new Mesh(geom, oceanMaterial)

    const group = new Group()

    function populateGroup() {
        if (group.children.length) {
            return
        }

        const d = [
            [-2, 1],
            [2, 1],
            [-2, -1],
            [2, -1],
        ]

        for (let i = 0; i < d.length; i++) {
            const [dx, dy] = d[i]

            const plane = new Mesh(new PlaneGeometry(4, 2))

            plane.rotateX(-Math.PI / 2)
            plane.translateX(dx)
            plane.translateY(dy)

            plane.receiveShadow = true
            plane.castShadow = false
            plane.material = materials[i]

            group.add(plane)
        }
    }

    this.render = (renderer, { oceanColor = 0x0000aa, landColor = 0x00aa00 }) => {
        if (!textures || oceanColor === lastOceanColor || landColor === lastLandColor) {
            return
        }

        const ocean = new Color(oceanColor)

        oceanMaterial.uniforms.u_ocean_color.value.x = ocean.r
        oceanMaterial.uniforms.u_ocean_color.value.y = ocean.g
        oceanMaterial.uniforms.u_ocean_color.value.z = ocean.b

        const land = new Color(landColor)

        oceanMaterial.uniforms.u_land_color.value.x = land.r
        oceanMaterial.uniforms.u_land_color.value.y = land.g
        oceanMaterial.uniforms.u_land_color.value.z = land.b

        const lastTarget = renderer.getRenderTarget()

        materials.forEach((material, i) => {
            renderer.setRenderTarget(targets[i])

            renderer.clear()

            oceanMaterial.uniforms.u_texture.value = textures[i]

            renderer.render(oceanPlane, camera)

            material.map = targets[i].texture
        })

        populateGroup()

        renderer.setRenderTarget(lastTarget)
    }

    this.mount = (scene) => {
        scene.add(group)
    }

    this.show = (normalScaleX, normalScaleY) => {
        gsap.to(
            {},
            {
                duration: 5,
                delay: 2,
                ease: 'easeIn',
                onUpdate() {
                    const t = this.ratio

                    // Materials share `normalScale`.
                    normalScale.x = normalScaleX * t
                    normalScale.y = normalScaleY * t

                    materials.forEach((material) => {
                        material.needsUpdate = true
                    })
                },
            },
        )
    }

    this.loadNormals = async ({ anisotropy }) => {
        try {
            await Promise.all(
                materials.map(
                    (material, i) =>
                        // eslint-disable-next-line implicit-arrow-linebreak
                        new Promise((resolve, reject) => {
                            material.normalMap = new TextureLoader().load(
                                withAssetPrefix(`/models/earth_normal_${i + 1}.jpg`),
                                (texture) => {
                                    texture.flipY = true
                                    texture.anisotropy = anisotropy
                                    resolve(material)
                                },
                                null,
                                reject,
                            )
                        }),
                ),
            )
        } catch (e) {
            console.warn('Failed to load normals.', e)
        }
    }
}

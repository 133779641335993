import React from 'react'
import styled from 'styled-components'
import { HubSpotFormContainer } from 'streamr-ui'
import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import { openNewsletterConfirmation } from '~utils/index'

export default function StayInformedNewsletterForm() {
    return (
        <Root>
            <HubSpotFormContainer
                targetId="hbf2"
                placeholder
                formId={process.env.GATSBY_HUBSPOT_NEWSLETTER_FORM_ID}
                portalId={process.env.GATSBY_HUBSPOT_NEWSLETTER_PORTAL_ID}
                onFormSubmitted={(mounted) => {
                    if (mounted) {
                        openNewsletterConfirmation()
                    }
                }}
            />
        </Root>
    )
}

const Root = styled.div`
    position: relative;

    ${HubSpotFormContainer} form,
    ${HubSpotFormContainer} {
        height: 56px;
    }

    .hs_error_rollup {
        display: none;
    }

    ul.errors {
        display: block !important;
        font-size: 12px;
        list-style: none;
        margin: 0.3rem 0 0;
        padding: 0 1rem;
    }

    .hs-error-msg {
        white-space: nowrap;
    }

    ${HubSpotFormContainer} form {
        width: auto;
    }

    .field {
        margin: 0;
    }

    .field .hs-input {
        appearance: none;
        border-radius: 0.5rem;
        padding-right: 100px;
    }

    .hs-submit input {
        color: white;
        background: #0324ff !important;
        border-radius: 4px;
        padding: 0 12px;
        font-size: 12px;
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        height: 40px;
        width: auto !important;
    }

    .hs-submit input:focus,
    .hs-submit input:active {
        transform: scale(1);
    }

    @media ${MqTablet} {
        ul.errors {
            margin-top: 0.5rem;
        }
    }

    @media ${MqDesktop} {
        .field .hs-input {
            padding-right: 144px;
        }

        .hs-submit input {
            padding: 0 26px;
            font-size: 14px;
        }

        ul.errors {
            margin-top: 0.75rem;
        }
    }
`

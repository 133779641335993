import React, { useState, useLayoutEffect, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Medium } from 'streamr-ui/css/consts'
import ToggleIcon, { ToggleIconWrap } from './ToggleIcon'

function UnstyledItem({ question, children, action, openOnMount = false, ...props }) {
    const [expand, setExpand] = useState(false)

    const [height, setHeight] = useState(0)

    const ref = useRef(null)

    const openOnMountRef = useRef(openOnMount)

    useEffect(() => {
        openOnMountRef.current = openOnMount
    }, [openOnMount])

    useEffect(() => {
        setExpand(openOnMountRef.current)
    }, [])

    useLayoutEffect(() => {
        if (!ref.current) {
            return () => {}
        }

        if (!expand) {
            setHeight(0)
            return () => {}
        }

        let frameRequest

        function onResize() {
            if (frameRequest) {
                window.cancelAnimationFrame(frameRequest)
            }

            frameRequest = window.requestAnimationFrame(() => {
                setHeight(ref.current.offsetHeight)
                frameRequest = undefined
            })
        }

        window.addEventListener('resize', onResize, false)

        onResize()

        return () => {
            window.removeEventListener('resize', onResize, false)

            if (frameRequest) {
                window.cancelAnimationFrame(frameRequest)
                frameRequest = undefined
            }
        }
    }, [expand])

    return (
        <li {...props} $appear>
            <div>
                <button type="button" onClick={() => setExpand((x) => !x)}>
                    <Question>{question}</Question>
                    <ToggleIconWrap
                        $rotation={expand ? '-135deg' : undefined}
                        $scale={expand ? '0.9' : undefined}
                    >
                        <ToggleIcon />
                    </ToggleIconWrap>
                </button>
                <Body
                    style={{
                        height: `${height}px`,
                    }}
                >
                    <Inner $expanded={expand} ref={ref}>
                        {children}
                        {action != null && <p>{action}</p>}
                    </Inner>
                </Body>
            </div>
        </li>
    )
}

const Item = styled(UnstyledItem)`
    > div {
        border-radius: 8px;
        background: white;
    }

    button {
        -webkit-tap-highlight-color: transparent;
        align-items: center;
        appearance: none;
        background: none;
        cursor: pointer;
        box-sizing: border-box;
        font-family: inherit;
        font-size: 16px;
        font-weight: ${Medium};
        border: 0;
        color: inherit;
        display: flex;
        line-height: 24px;
        outline: 0;
        padding: 20px 24px;
        min-height: 80px;
        text-align: left;
        width: 100%;
    }

    & + & {
        margin-top: 16px;
    }

    @media (min-width: 960px) {
        button {
            font-size: 18px;
        }
    }

    @media (min-width: 1480px) {
        button {
            font-size: 20px;
        }
    }
`

export default Item

const Inner = styled.div`
    padding: 0 24px 24px;
    transition: 100ms ease-out;
    transition-property: visibility, opacity;
    transition-delay: 100ms, 0s;
    opacity: 0;
    visibility: hidden;

    ${({ $expanded = false }) =>
        $expanded &&
        css`
            opacity: 1;
            visibility: visible;
            transition-delay: 0s;
        `}
`

const Question = styled.div`
    flex-grow: 1;
    margin-right: 20px;
`

const Body = styled.div`
    box-sizing: border-box;
    height: 0;
    overflow: hidden;
    transition: 100ms ease-in-out height;

    p {
        line-height: 28px;
        margin: 0;
        word-wrap: break-word;
    }

    p + p {
        margin-top: 28px; /* 1x line-height */
    }

    a {
        color: inherit;
        text-decoration: underline;
    }
`

export const List = styled.ul`
    list-style: none;
    margin: 0 auto;
    padding: 0;
`

/**
 * @author simppafi / http://www.simppa.fi/
 *
 * Very fast gaussian blur
 */

import { Vector2 } from 'three'

const GaussianBlurShader = {
    uniforms: {
        tColor: {
            value: null,
        },
        u_offset: {
            value: new Vector2(1.0, 1.0),
        },
        u_strength: {
            value: 1.0,
        },
    },
    vertexShader: `
      varying vec2 vUv;

      void main() {
        vUv = uv;
        gl_Position = vec4(position, 1.0);
      }
    `,
    fragmentShader: `
        precision mediump float;
        varying vec2 vUv;
        uniform sampler2D tColor;
        uniform vec2 u_offset;
        uniform float u_strength;

        void main() {
          vec4 sum = texture2D(tColor, vUv) * 0.204164;

          sum = sum + texture2D(tColor, vUv + u_offset * 1.407333) * 0.304005;
          sum = sum + texture2D(tColor, vUv - u_offset * 1.407333) * 0.304005;
          sum = sum + texture2D(tColor, vUv + u_offset * 3.294215) * 0.093913;

          gl_FragColor = (sum + texture2D(tColor, vUv - u_offset * 3.294215) * 0.093913) * u_strength;
        }
    `,
}

export default GaussianBlurShader

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { MqTablet } from 'streamr-ui/css/consts'
import { Xxx as UnstyledXxx } from '~shared/Glyph'

const Nav = styled.div`
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    width: 100%;

    button {
        background: transparent;
        border: 0;
        cursor: pointer;
        outline: 0;
        padding: 1.25rem;
    }

    @media ${MqTablet} {
        button {
            padding: 2.5rem 2.25rem;
        }
    }
`

const Xxx = styled(UnstyledXxx)`
    display: block;
    width: 1rem;

    path {
        stroke: white;
    }

    @media ${MqTablet} {
        width: 1.5rem;
    }
`

const Footer = styled.div`
    flex-shrink: 0;
    height: calc(2 * 1.5rem + 1rem);

    @media ${MqTablet} {
        height: calc(2 * 2.5rem + 1.5rem);
    }
`

function UnstyledVideoModal({ className, onCloseClick = () => {}, children }) {
    useEffect(() => {
        function onKeyDown({ key }) {
            if (key !== 'Escape' || typeof onCloseClick !== 'function') {
                return
            }

            onCloseClick()
        }

        window.addEventListener('keydown', onKeyDown)

        return () => {
            window.removeEventListener('keydown', onKeyDown)
        }
    }, [onCloseClick])

    return (
        <div className={className}>
            <Nav>
                <div />
                <button type="button" onClick={onCloseClick}>
                    <Xxx thin />
                </button>
            </Nav>
            {children}
            <Footer />
        </div>
    )
}

const VideoModal = styled(UnstyledVideoModal)`
    align-items: center;
    background-color: black;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
`

export default VideoModal

import styled from 'styled-components'
import { Semibold } from 'streamr-ui/css/consts'

const Header = styled.div`
    box-sizing: border-box;
    margin: 0 auto 80px;
    max-width: 496px;

    h2 {
        font-size: 32px;
        font-weight: ${Semibold};
        line-height: 40px;
        margin: 0;
        text-align: center;
    }

    @media (min-width: 560px) {
        color: white;
        margin-bottom: 16px;
        max-width: none;

        h2 {
            text-align: left;
        }

        > div {
            align-items: center;
            background: linear-gradient(30deg, #cb8be3, #fdaf50);
            border-radius: 8px;
            box-sizing: border-box;
            display: flex;
            height: 208px;
            padding: 0 24px;
            width: 100%;
        }
    }

    @media (min-width: 1216px) {
        flex-shrink: 0;
        margin: 0 16px 0 0;
        padding: 0;
        width: 396px;

        > div {
            display: block;
            padding: 48px;
            height: auto;
        }

        h2 {
            font-size: 40px;
            line-height: 1.2em;
            margin-bottom: 108px;
        }
    }

    @media (min-width: 1480px) {
        margin: 0 24px 0 0;
        width: 496px;

        > div {
            padding-bottom: 56px;
        }

        h2 {
            font-size: 60px;
            line-height: 1em;
            margin-bottom: 148px;
        }
    }
`

export default Header

export const TextWrap = styled.div`
    flex-grow: 1;
`

export const ImageWrap = styled.div`
    display: none;

    img {
        display: block;
        width: 145px;
    }

    @media (min-width: 560px) {
        display: block;
        padding: 0 40px;
    }

    @media (min-width: 1216px) {
        padding: 0;
    }

    @media (min-width: 1480px) {
        img {
            width: 248px;
        }
    }
`

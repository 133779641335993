export default function getArea(coordinates) {
    let areaLeft = Number.POSITIVE_INFINITY
    let areaRight = Number.NEGATIVE_INFINITY
    let areaUp = Number.NEGATIVE_INFINITY
    let areaDown = Number.POSITIVE_INFINITY

    coordinates.forEach(({ x, z }) => {
        if (x < areaLeft) {
            areaLeft = x
        }
        if (x > areaRight) {
            areaRight = x
        }
        if (z > areaUp) {
            areaUp = z
        }
        if (z < areaDown) {
            areaDown = z
        }
    })

    return Math.abs((areaLeft - areaRight) * (areaDown - areaUp))
}

import React from 'react'
import { MqDesktop, MqTablet, Semibold } from 'streamr-ui/css/consts'
import styled from 'styled-components'
import discord from '../assets/discord.png'
import discord2x from '../assets/discord@2x.png'
import streamr from '../assets/streamr.png'
import streamr2x from '../assets/streamr@2x.png'

/**
 * @param props.backgroundColor
 * @param props.title
 * @param props.subtitle
 */
export default function StayInformedTile({
    backgroundColor,
    title = 'Untitled tile',
    subtitle = '',
    children = <div />,
    callToAction = <div />,
}) {
    return (
        <Root $bgColor={backgroundColor} $appear>
            <div>
                <h2>{title}</h2>
                {subtitle && <p>{subtitle}</p>}
            </div>
            <Body>{children}</Body>
            <div>{callToAction}</div>
        </Root>
    )
}

const Root = styled.div`
    background-color: ${({ $bgColor = 'red' }) => $bgColor};
    border-radius: 1rem;
    color: white;
    display: flex;
    flex-direction: column;
    height: 430px;
    padding: 24px;

    h2,
    p {
        margin: 0;
        line-height: 1.4em;
        max-width: 22.25rem;
    }

    h2 {
        font-size: 1.5rem;
        font-weight: ${Semibold};
    }

    p {
        font-size: 1rem;
    }

    h2 + p {
        margin-top: 0.75em;
    }

    img {
        display: block;
    }

    @media ${MqTablet} {
        height: 476px;
        padding: 32px;

        h2 {
            font-size: 1.75rem;
        }

        img {
            max-width: ;
        }
    }

    @media ${MqDesktop} {
        height: 672px;
        padding: 48px;

        h2 {
            font-size: 2.25rem;
        }

        p {
            font-size: 1.25rem;
        }

        h2,
        p {
            max-width: none;
        }
    }
`

const Body = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
`

export const StayInformedGrid = styled.div`
    display: grid;
    gap: 1rem;

    @media ${MqTablet} {
        grid-template-columns: 1fr 1fr;
    }

    @media ${MqDesktop} {
        gap: 1.5rem;
    }
`

const DiscordImage = styled.img`
    max-width: 164px;
    width: 50%;
`

export function DiscordIcon() {
    return <DiscordImage src={discord} srcSet={`${discord2x} 2x`} alt="Discord" />
}

const NewsletterImage = styled.img`
    max-width: 216px;
    width: 50%;
`

export function NewsletterIcon() {
    return <NewsletterImage src={streamr} srcSet={`${streamr2x} 2x`} alt="Newsletter" />
}

export const StayInformedColumn = styled.div`
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 100%;
    padding: 0 2rem;
    width: 26rem;

    @media (min-width: 30rem) {
        width: 36rem;
        padding: 0 4rem;
    }

    @media ${MqTablet} {
        width: 75.5rem;
        padding: 0 1.5rem;
    }
`

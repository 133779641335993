import { Vector2, Vector3 } from 'three'

const GradinetsShader = {
    vertexShader: `
        varying vec2 vUv;

        void main() {
            gl_Position = vec4(position, 1.0);
        }
    `,
    fragmentShader: `
        uniform vec2 u_resolution;
        uniform vec3 u_outer_color;
        uniform float u_outer_width;
        uniform vec3 u_inner_color;
        uniform float u_inner_width;
        uniform float u_offset;
        uniform float u_height;
        uniform float u_phase;

        #define PI 3.1415926538

        float easeInSine(float x) {
            return 1.0 - cos((x * PI) * 0.5);
        }

        float rand(vec2 co) {
            return fract(sin(dot(co, vec2(12.9898, 78.233))) * 43758.5453);
        }

        float easeOutExpo(float x) {
            return x == 1.0 ? 1.0 : 1.0 - pow(2.0, -10.0 * x);
        }

        float radius(float w, float h) {
            float W = w * 0.5;
            float X = sqrt(W * W + h * h) * 0.5;
            float L = (W * X) / h;

            return sqrt(L * L + X * X);
        }

        void main() {
            float vw = u_resolution.x;
            float vh = u_resolution.y;
            float r = radius(vw, u_height);

            vec2 origin = vec2(vw / 2.0, vh - u_offset - r + u_height);

            float dist = distance(gl_FragCoord.xy, origin);

            float outer_ring = 100.0 * u_outer_width;

            float outer_radius = r + outer_ring;

            float phase = easeOutExpo(u_phase);

            vec4 prefinal = vec4(u_inner_color.rgb, 0.0);

            float ring = 100.0 * u_inner_width;

            float inner_radius = r - ring;

            if (dist > r && dist <= outer_radius) {
                prefinal = vec4(u_outer_color.rgb, phase * easeInSine((outer_radius - dist) / outer_ring));
            } else if (dist <= r && dist > inner_radius) {
                prefinal = vec4(u_inner_color.rgb, phase * easeInSine((dist - inner_radius) / ring));
            }

            float sand = (rand(gl_FragCoord.xy) - 0.5) / 20.0;

            gl_FragColor = vec4(prefinal.r + sand, prefinal.g + sand, prefinal.b + sand, prefinal.a);
        }
    `,
    getUniforms() {
        return {
            u_resolution: {
                type: 'v2',
                value: new Vector2(0, 0),
            },
            u_height: {
                type: 'f',
                value: 200,
            },
            u_inner_width: {
                type: 'f',
                value: 1,
            },
            u_outer_width: {
                type: 'f',
                value: 1,
            },
            u_outer_color: {
                type: 'v3',
                value: new Vector3(0, 0, 0),
            },
            u_inner_color: {
                type: 'v3',
                value: new Vector3(0, 0, 0),
            },
            u_offset: {
                type: 'f',
                value: 100,
            },
            u_phase: {
                type: 'f',
                value: 0.5,
            },
        }
    },
}

export default GradinetsShader

import React from 'react'
import styled, { css } from 'styled-components'
import { Tooltip, TooltipPlacement } from 'streamr-ui'
import { Semibold, MqTablet } from 'streamr-ui/css/consts'
import { useGlobalApy, useNetworkSummary } from '~hooks'

const Label = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    transition: 300ms color ease-in-out;
    color: inherit;

    strong {
        font-weight: ${Semibold};
        white-space: nowrap;
    }

    @media ${MqTablet} {
        font-size: 12px;
    }
`

const UnstyledStat = ({ name, children, ...props }) => (
    <div {...props}>
        <Tooltip value={name} placement={TooltipPlacement.Bottom}>
            <Label>{children}</Label>
        </Tooltip>
    </div>
)

const Stat = styled(UnstyledStat)`
    background-color: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.05);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: 200ms color;

    ${Tooltip} {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    strong span {
        color: white;
    }

    ${({ $ready = false }) =>
        $ready &&
        css`
            color: white;
        `}
`

const Root = styled.div``

const formatDataRate = (bytesPerSecond) => {
    if (bytesPerSecond == null || bytesPerSecond === 0) {
        return ['−', '']
    }

    const bytesPerDay = bytesPerSecond * 60 * 60 * 24
    const gbPerDay = bytesPerDay / (1000 * 1000 * 1000)

    const value = gbPerDay.toFixed(2)
    const suffix = 'GB/day'

    return [value, suffix]
}

const UnstyledNetworkStats = ({ className }) => {
    const apy = useGlobalApy()

    const summary = useNetworkSummary()

    const { nodeCount, bytesPerSecond } = summary || {}

    const formattedApy = apy && apy !== '-' ? `${apy}%` : '-'
    const [value, unit] = formatDataRate(bytesPerSecond)

    return (
        <Root className={className}>
            <Stat name="Network size" $ready={typeof summary !== 'undefined'}>
                <strong>
                    {nodeCount || '-'}
                    <span>{nodeCount === 1 ? ' node' : ' nodes'}</span>
                </strong>
            </Stat>
            <Stat name="Data rate" $ready={typeof bytesPerSecond !== 'undefined'}>
                <strong>
                    {value}
                    <span>{unit && ` ${unit}`}</span>
                </strong>
            </Stat>
            <Stat name="Annual percentage yield" $ready={typeof apy !== 'undefined'}>
                <strong>
                    {formattedApy}
                    <span> APY</span>
                </strong>
            </Stat>
        </Root>
    )
}

const NetworkStats = styled(UnstyledNetworkStats)`
    cursor: default;
    display: flex;
    width: fit-content;
    z-index: 3;

    ${Stat} {
        height: 32px;
        flex: 1;
        padding-left: 16px;
    }

    ${Stat} + ${Stat} {
        padding-right: 16px;
    }

    ${Stat}:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    ${Stat}:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    @media ${MqTablet} {
        ${Stat} {
            width: 136px;
        }

        ${Stat} + ${Stat} {
            border-left: 1px solid rgba(255, 255, 255, 0.1);
        }
    }
`

export default NetworkStats

/**
 * @author simppafi / http://www.simppa.fi/
 *
 * Gradient Shader
 */

import { Vector2, Vector4 } from 'three'

const GradientShader = {
    uniforms: {
        u_resolution: {
            value: new Vector2(512, 512),
        },
        u_offset: {
            value: new Vector4(1.6, 1.0, 1.6, 1.0),
        },
        u_shift: {
            value: new Vector4(4.0, 1000.0, 4.0, 1000.0),
        },
        u_full_blur: {
            value: 0.0,
        },
    },
    vertexShader: `
      void main() {
        gl_Position = vec4(position, 1.0);
      }
    `,
    fragmentShader: `
        precision mediump float;

        uniform vec2 u_resolution;
        uniform float u_full_blur;
        uniform vec4 u_offset;
        uniform vec4 u_shift;

        void main() {
           vec2 pixelPos = gl_FragCoord.xy / u_resolution;
           
           float topShift = u_shift.x;
           float top = clamp(pixelPos.y * topShift - (topShift - u_offset.x), 0.0, 1.0);

           float rightShift = u_shift.y;
           float right = clamp(pixelPos.x * rightShift - (rightShift - u_offset.y), 0.0, 1.0);

           float bottomShift = u_shift.z;
           float bottom = clamp((1.0 - pixelPos.y) * bottomShift - (bottomShift - u_offset.z), 0.0, 1.0);
          
           float leftShift = u_shift.w;
           float left = clamp((1.0 - pixelPos.x) * leftShift - (leftShift - u_offset.w), 0.0, 1.0);
           
           float shift = min(1.0, left + right + bottom + top + u_full_blur);

           gl_FragColor = vec4(shift, shift, shift, 1.0);
        }
    `,
}

export { GradientShader }

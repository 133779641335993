export default function Autopilot(coordinates, fn) {
    let timeout = null

    this.isEngaged = () => timeout !== null

    this.disengage = ({ reset = true } = {}) => {
        if (!this.isEngaged()) {
            return
        }

        clearTimeout(timeout)
        timeout = null

        if (reset) {
            fn(undefined)
        }
    }

    this.engage = ({ delay = 11500 } = {}) => {
        if (this.isEngaged()) {
            return
        }

        timeout = setTimeout(async () => {
            this.disengage({
                reset: false,
            })

            try {
                await fn(coordinates[Math.floor(Math.random() * coordinates.length)])
                this.engage()
            } catch (e) {
                console.warn('Autopilot interrupted', e)
            }
        }, delay)
    }
}

import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { Regular, MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import SectionID from '~shared/SectionID'
import Spacer from '~shared/Spacer'
import { Link, LinkLook } from 'streamr-ui'

const UnstyledTitle = forwardRef(function Topic(
    { hash, head = '0,,', tail = '3rem,5rem,9rem', children, ...props },
    ref,
) {
    return (
        <>
            {!!hash && <SectionID value={hash} />}
            <Spacer {...props} as="h2" head={head} tail={tail} ref={ref}>
                {hash ? (
                    <Link href={`#${hash}`} look={[LinkLook.Inherit]}>
                        {children}
                    </Link>
                ) : (
                    children
                )}
            </Spacer>
        </>
    )
})

const Title = styled(UnstyledTitle)`
    font-size: 1.75rem;
    font-weight: ${Regular};
    line-height: 1.5em;
    margin-left: 32px;
    margin-right: 32px;
    text-align: left;

    a {
        color: inherit !important;
        text-decoration: none !important;
    }

    @media ${MqTablet} {
        font-size: 2rem;
        text-align: center;
    }

    @media ${MqDesktop} {
        font-size: 2.5rem;
        text-align: center;
    }
`

const Body = styled.div`
    margin: 0 auto;

    ${({ padded }) =>
        !!padded &&
        css`
            margin: 0 32px;

            @media ${MqTablet} {
                margin: 0 auto;
            }
        `}
`

const Paragraph = styled.p`
    font-size: 1rem;
    line-height: 1.75rem;
    margin: 0;

    & + & {
        margin-top: 1.75rem;
    }

    @media ${MqTablet} {
        font-size: 1.125rem;
        line-height: 1.875rem;

        & + & {
            margin-top: 1.875rem;
        }
    }
`

const UnstyledTopic = forwardRef(function UnstyledTopic(
    { head = '8.5rem 14rem 22rem', ...props },
    ref,
) {
    return <Spacer {...props} head={head} ref={ref} />
})

const Topic = styled(UnstyledTopic)`
    background: ${({ $background }) => $background};
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${({ color }) => color};

    ${({ $cutoff = false }) =>
        $cutoff &&
        css`
            overflow: hidden;
        `}
`

const Narrow = styled(Topic)`
    ${Title} {
        margin-bottom: 32px;
    }

    @media ${MqTablet} {
        ${Title} {
            margin-bottom: 72px;
            text-align: left;
        }

        ${Title},
        ${Body} {
            margin-left: auto;
            margin-right: auto;
            width: 512px;
        }
    }

    @media ${MqDesktop} {
        ${Title} {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 64px;
            text-align: center;
            width: auto;
        }

        ${Body} {
            width: 460px;
        }
    }
`

Object.assign(Topic, {
    Body,
    Narrow,
    Paragraph,
    Title,
})

export default Topic

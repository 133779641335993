import React, { useMemo } from 'react'
import styled from 'styled-components'
import { truncate } from '~utils/streams'

const TooltipBox = styled.div`
    align-items: center;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 4px;
    color: #0c009a;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    padding: 13px 16px;
    text-align: left;
    transform: translate(-50%, -105px) translateZ(0);
    max-width: 280px;

    ::after {
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 7px solid #ffffff;
        bottom: -6px;
        content: '';
        height: 0;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 0;
    }
`

const Container = styled.div`
    align-items: center;
    display: flex;
    margin-top: 0.25em;
`

const Address = styled.div`
    background: #f3f3f3;
    border-radius: 2px;
    color: #adadad;
    font-size: 10px;
    font-weight: 500;
    padding: 0 8px;
    text-align: left;
`

const Location = styled.div`
    color: #adadad;
    font-size: 10px;
    font-weight: 500;
    flex-grow: 1;
    margin: 0 0 0 4px;
    min-width: 0;
    padding: 0;
    text-align: left;

    span {
        display: block;
        overflow: hidden;
        padding-right: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`

const StatusBall = styled.div`
    background-color: #2ac437;
    border-radius: 8px;
    flex-shrink: 0;
    width: 8px;
    height: 8px;
`

const Title = styled.div`
    max-width: 100%;
    overflow: hidden;
    padding-right: 16px;
    text-overflow: ellipsis;
`

const UnstyledTooltip = ({
    className,
    innerRef,
    title,
    address,
    location,
    streamId: streamIdProp,
}) => {
    const truncatedAddress = useMemo(() => (address || '').slice(0, 6), [address])

    const streamId = useMemo(() => truncate(streamIdProp), [streamIdProp])

    return (
        <div ref={innerRef} className={className}>
            <TooltipBox>
                <Title>{truncate(title)}</Title>
                <Container>
                    <Address>{truncatedAddress}</Address>
                    <Location>
                        <span>{location}</span>
                    </Location>
                </Container>
                {!!streamId && (
                    <Container>
                        <StatusBall />
                        <Location>
                            <span>{streamId}</span>
                        </Location>
                    </Container>
                )}
            </TooltipBox>
        </div>
    )
}

const Tooltip = styled(UnstyledTooltip)`
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    visibility: hidden;
`

export default Tooltip

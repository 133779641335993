import React from 'react'
import styled from 'styled-components'
import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'
import ai from './assets/ai.png'
import ai2x from './assets/ai@2x.png'
import comms from './assets/comms.png'
import comms2x from './assets/comms@2x.png'
import depin from './assets/depin.png'
import depin2x from './assets/depin@2x.png'
import liveVideo from './assets/live_video.png'
import liveVideo2x from './assets/live_video@2x.png'
import metaverse from './assets/metaverse.png'
import metaverse2x from './assets/metaverse@2x.png'
import monetisation from './assets/monetisation.png'
import monetisation2x from './assets/monetisation@2x.png'

export function UseCases() {
    return (
        <Root>
            <HeadGrid>
                <li>
                    <h2 $appear>
                        <a href="#use-cases">Explore Streamr use&nbsp;cases</a>
                    </h2>
                </li>
                <li>
                    <SectionDescription $appear>
                        Streamr is a cryptographically secure, open&#8209;source, and decentralized
                        network, capable of streaming any live media
                        or&nbsp;real&#8209;time&nbsp;data at&nbsp;scale.
                    </SectionDescription>
                </li>
            </HeadGrid>
            <Grid>
                <Tile title="AI" imgSrc={ai} img2xSrc={ai2x}>
                    <p>Data delivery to, from &&nbsp;between AI models and AI&nbsp;agents.</p>
                </Tile>
                <Tile title="Live Video" imgSrc={liveVideo} img2xSrc={liveVideo2x}>
                    <p>
                        Stream any media via Streamr&apos;s hyperscalable decentralized
                        live&nbsp;CDN.
                    </p>
                </Tile>
                <Tile title="DePin" imgSrc={depin} img2xSrc={depin2x}>
                    <p>
                        Stream verifiable data from any networked DePIN hardware or IoT&nbsp;device.
                    </p>
                </Tile>
                <Tile title="Communications" imgSrc={comms} img2xSrc={comms2x}>
                    <p>Message and coordinate securely without servers or&nbsp;middlemen.</p>
                </Tile>
                <Tile title="Metaverse" imgSrc={metaverse} img2xSrc={metaverse2x}>
                    <p>Multicast interactive experiences and dynamic environments at&nbsp;scale.</p>
                </Tile>
                <Tile title="Data Monetisation" imgSrc={monetisation} img2xSrc={monetisation2x}>
                    <p>Buy and sell data stream&nbsp;subscriptions.</p>
                </Tile>
            </Grid>
        </Root>
    )
}

const Root = styled.div`
    margin: 0 auto;
    max-width: 1160px;
    padding: 0 32px;

    p,
    h2,
    h4 {
        margin: 0;
    }
`

function Tile({ title, children, imgSrc, img2xSrc }) {
    return (
        <TileRoot>
            <img src={imgSrc} srcSet={`${img2xSrc} 2x`} alt={title} />
            <h4>{title}</h4>
            {children}
        </TileRoot>
    )
}

const TileRoot = styled.li`
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.02);
    background: #fafafa;
    border: 2px solid #ffffff;
    border-radius: 16px;
    overflow: hidden;
    padding: 40px 40px 56px;

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        margin-top: 1em;
        width: 420px;
        max-width: 100%;
    }

    h4 {
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 0em;
        line-height: 1.2em;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    img {
        display: block;
        margin: -48px 0 32px;
        transform: translateX(-20px);
        max-width: 100%;
        width: 384px;
    }

    @media (min-width: 920px) {
        padding: 56px 56px 72px;

        h4 {
            font-size: 34px;
            white-space: nowrap;
        }
    }

    @media (min-width: 1100px) {
        h4 {
            font-size: 40px;
        }
    }
`

const SectionDescription = styled.p`
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0em;
    line-height: 1.75em;
    margin: 0;
    max-width: 100%;
    padding: 0;
    width: 620px;

    && {
        margin-left: auto;
        margin-right: auto;
    }

    @media ${MqDesktop} {
        font-size: 26px;
        line-height: 1.5em;
    }
`

const Grid = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px 24px;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        min-width: 0;
    }

    @media ${MqTablet} {
        grid-template-columns: 1fr 1fr;
    }
`

const HeadGrid = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    gap: 32px 24px;
    list-style: none;
    padding: 0;
    margin: 0 0 72px;
    text-align: center;

    h2 {
        font-size: 28px;
        font-weight: 700;
        line-height: 1.1em;
        letter-spacing: 0em;
    }

    h2 a {
        text-decoration: none !important;
    }

    li {
        min-width: 0;
    }

    @media ${MqTablet} {
        h2 {
            font-size: 40px;
        }
    }

    @media ${MqDesktop} {
        grid-template-columns: 1fr 1fr;
        text-align: left;

        h2 {
            font-size: 60px;
        }
    }
`

import React, { useState, useRef, useEffect, useCallback, useContext } from 'react'
import { Vector3 } from 'three'
import styled from 'styled-components'
import gsap from 'gsap'
import { getFeed } from './utils/feed'
import { getReversedGeocodedLocation } from './utils/getReversedGeocodedLocation'
import Tooltip from './Tooltip'
import VisualizerSetterContext from './contexts/VisualizerSetterContext'
import NetworkVisualizer from '.'
import ScrollPhaseManager from '~utils/ScrollPhaseManager'
import generateMnemonicFromAddress from '~utils/generateMnemonicFromAddress'

function UnstyledNetworkExplorer({
    className,
    offsetFn,
    onIntroDone,
    onLoaded,
    onNodeChange,
    setLoading,
    onPositionCorrection,
}) {
    const setCurrentVisualizer = useContext(VisualizerSetterContext)

    const setLoadingRef = useRef()

    setLoadingRef.current = setLoading

    const [tooltipValue, setTooltipValue] = useState({
        id: undefined,
        title: '',
        address: '',
        location: '',
        url: '',
        streamId: undefined,
    })
    const [locations, setLocations] = useState({})

    const locationsRef = useRef(locations)

    locationsRef.current = locations

    const offsetFnRef = useRef(offsetFn)

    offsetFnRef.current = offsetFn

    const canvasRef = useRef()

    const tooltipRef = useRef()

    const rootRef = useRef()

    const onNodeChangeRef = useRef()

    onNodeChangeRef.current = onNodeChange

    const fetchLocation = useCallback(async ({ id, latitude, longitude }) => {
        if (!locationsRef.current || !!locationsRef.current[id]) {
            return
        }

        const { region } = await getReversedGeocodedLocation({
            latitude,
            longitude,
        })

        if (region) {
            setLocations((prevLocations) => ({
                ...prevLocations,
                [id]: region,
            }))
        }
    }, [])

    useEffect(() => {
        const { current: tooltip } = tooltipRef

        if (!tooltip || tooltipValue.title === '') {
            tooltip.style.visibility = 'hidden'
            return
        }

        tooltip.style.visibility = 'visible'

        gsap.killTweensOf(tooltip.style)

        gsap.to(tooltip.style, {
            duration: 0.25,
            ease: 'quad.out',
            opacity: 1,
        })
    }, [tooltipValue])

    const onIntroDoneRef = useRef(onIntroDone)

    const visRef = useRef(null)

    const onPositionCorrectionRef = useRef(onPositionCorrection)

    useEffect(() => {
        onPositionCorrectionRef.current = onPositionCorrection
    }, [onPositionCorrection])

    const spmRef = useRef(
        new ScrollPhaseManager({
            onUpdate(phase) {
                if (!visRef.current) {
                    return
                }

                visRef.current.setPhase(phase)
            },
            onComplete(phase) {
                if (!visRef.current || phase !== 0) {
                    return
                }

                visRef.current.reselect()
            },
            onBeforeStart(targetPhase) {
                if (!visRef.current) {
                    return
                }

                if (targetPhase === 0) {
                    visRef.current.activate()
                    return
                }

                visRef.current.deactivate()
            },
        }),
    )

    useEffect(() => {
        const { current: spm } = spmRef

        const { current: canvas } = canvasRef

        let selectedPoint = null

        let prevBlur = 0

        let size = {
            width: 2,
            height: 2,
        }

        let mounted = true

        const onHidden = (newPoint, metadata) => {
            const { current: tooltip } = tooltipRef

            if (!tooltip) {
                return
            }

            if (typeof onNodeChangeRef.current === 'function') {
                onNodeChangeRef.current(newPoint)
            }

            selectedPoint = newPoint

            if (!newPoint) {
                setTooltipValue({
                    id: undefined,
                    title: '',
                    address: '',
                    location: '',
                    streamId: undefined,
                })
                return
            }

            const { nodeId: id, longitude, latitude, country, streamPartId: streamId } = metadata

            const address = id.replace(/#.*/, '')

            const title = (() => {
                try {
                    return generateMnemonicFromAddress(`0x${address}`)
                } catch (e) {
                    return address
                }
            })()

            setTooltipValue({
                id,
                title,
                address,
                location: country,
                streamId,
            })

            fetchLocation({
                id,
                latitude,
                longitude,
            })
        }

        const onSelect = (point, { showTooltip = true, metadata }) => {
            const { current: tooltip } = tooltipRef

            if (!tooltip || !showTooltip) {
                return
            }

            gsap.killTweensOf(tooltip.style)

            gsap.to(tooltip.style, {
                duration: tooltip.style.opacity * 0.25,
                ease: 'quad.in',
                opacity: 0,
                onComplete: () => {
                    onHidden(point, metadata)
                },
            })
        }

        const onPan = (camera) => {
            const { width, height } = size

            const { current: tooltip } = tooltipRef

            if (!tooltip || !selectedPoint) {
                return
            }

            const vector = new Vector3(selectedPoint.x, 0, selectedPoint.z)

            vector.project(camera)

            vector.x = ((vector.x + 1) * width) / 2

            vector.y = ((-vector.y + 1) * height) / 2

            const pos = Math.min(1.0, Math.abs((vector.y / height - 0.5) * 2.0))

            tooltip.style.transform = `translate(${vector.x}px, ${
                vector.y - 10 * (1 - tooltip.style.opacity)
            }px)`

            const blur = Math.round(pos * pos * pos * 4)

            if (blur !== prevBlur) {
                const filter = `blur(${blur}px)`
                tooltip.style.webkitFilter = filter
                tooltip.style.mozFilter = filter
                tooltip.style.oFilter = filter
                tooltip.style.msFilter = filter
                tooltip.style.filter = filter
                prevBlur = blur
            }
        }

        if (!canvas) {
            return () => {}
        }

        const vis = new NetworkVisualizer(canvas, {
            offsetFn: offsetFnRef.current,
            setLoading: (value) => {
                if (typeof setLoadingRef.current === 'function') {
                    setLoadingRef.current(value)
                }
            },
            onIntroDone: onIntroDoneRef.current,
            onPan,
            onSelect,
            onPositionCorrection: onPositionCorrectionRef.current,
        })

        visRef.current = vis

        spm.mount()

        setCurrentVisualizer(vis)

        const onResize = () => {
            const { current: root } = rootRef

            if (!root) {
                return
            }

            size = {
                height: root.offsetHeight,
                width: root.offsetWidth,
            }

            vis.resize(size)
        }

        window.addEventListener('resize', onResize)

        const onComplete = () => {
            if (!mounted) {
                return
            }

            onResize()

            vis.start()

            getFeed()
                .then((data) => {
                    if (mounted) {
                        vis.feed(data)
                    }

                    if (typeof onLoaded === 'function') {
                        onLoaded(data)
                    }

                    return null
                })
                .catch((e) => {
                    console.warn('Could not load nodes.', e)

                    if (typeof onLoaded === 'function') {
                        onLoaded()
                    }
                })
        }

        vis.load(onComplete)

        return () => {
            window.removeEventListener('resize', onResize)

            mounted = false

            vis.kill()

            spm.unmount()

            if (visRef.current === vis) {
                setCurrentVisualizer(null)
                visRef.current = null
            }

            const { current: tooltip } = tooltipRef

            if (tooltip) {
                gsap.killTweensOf(tooltip.style)
            }
        }
    }, [fetchLocation, onLoaded, setCurrentVisualizer])

    return (
        <div className={className} ref={rootRef}>
            <Tooltip
                innerRef={tooltipRef}
                {...tooltipValue}
                location={
                    locations && locations[tooltipValue.id]
                        ? locations[tooltipValue.id]
                        : tooltipValue.location
                }
            />
            <canvas ref={canvasRef} />
        </div>
    )
}

const NetworkExplorer = styled(UnstyledNetworkExplorer)`
    height: 100%;
    overflow: hidden;
    user-select: none;
    width: 100%;

    canvas {
        height: 100%;
        touch-action: none;
        width: 100%;
    }
`

// eslint-disable-next-line import/no-unused-modules
export default NetworkExplorer

const UNIFORMS_FRAGMENT = `
    uniform sampler2D u_post_glow;
    uniform sampler2D u_post_mirror;
    uniform sampler2D u_post_bokeh;
    uniform sampler2D u_tilt;
    uniform vec2 u_screen_size;

    uniform vec4 u_offset;
    uniform vec4 u_shift;

    uniform bool u_final;
    uniform bool u_bokeh;
    uniform bool u_reflect;
    uniform bool u_glow;
`

const FRAGMENT = `
    if (u_final) {
        vec2 screen = gl_FragCoord.xy / u_screen_size;
        float a = gl_FragColor.a;

        if(u_reflect) {
            vec4 post_mirror = texture2D(u_post_mirror, screen);
            gl_FragColor = vec4(gl_FragColor.rgb - max(post_mirror.r, post_mirror.b) * 0.5 + post_mirror.rgb, a);
        }

        if(u_bokeh) {
            vec4 post_bokeh = texture2D(u_post_bokeh, screen);
            vec4 tilt = texture2D(u_tilt, screen);
            gl_FragColor = vec4(mix(gl_FragColor.rgb, post_bokeh.rgb, min(1.0, tilt.r * 1.1)), a);
        }

        if(u_glow) {
            vec4 post_glow = texture2D(u_post_glow, screen);
            gl_FragColor = vec4(gl_FragColor.rgb + post_glow.rgb, a);
        }
    }
`

const append = (addon) => (hit) =>
    `
    ${hit}
    ${addon}
`

export default function injectRenderPipeline(renderPipeline, material) {
    const addUniforms = (_shader) => {
        renderPipeline.addUniforms(_shader.uniforms)

        _shader.uniforms.u_post_glow = {
            value: renderPipeline.getGlowTarget().texture,
        }
        _shader.uniforms.u_post_mirror = {
            value: renderPipeline.getMirrorTarget().texture,
        }
        _shader.uniforms.u_post_bokeh = {
            value: renderPipeline.getBokehTarget().texture,
        }
        _shader.uniforms.u_tilt = {
            value: renderPipeline.getTiltTexture(),
        }
        _shader.uniforms.u_screen_size = {
            value: renderPipeline.getScreenSize(),
        }
        _shader.uniforms.u_shift = {
            value: renderPipeline.getShift(),
        }
        _shader.uniforms.u_final = {
            value: false,
        }
        _shader.uniforms.u_bokeh = {
            value: renderPipeline.getBokeh(),
        }
        _shader.uniforms.u_reflect = {
            value: renderPipeline.getReflect(),
        }
        _shader.uniforms.u_glow = {
            value: renderPipeline.getGlow(),
        }
    }

    if (material.doOnAfterBeforeCompile) {
        material.onAfterBeforeCompile = (_shader) => {
            _shader.fragmentShader = _shader.fragmentShader
                .replace('//<inject_fragment>', FRAGMENT)
                .replace('//<inject_frag_uniforms>', UNIFORMS_FRAGMENT)

            addUniforms(_shader)
        }
        return
    }

    material.onBeforeCompile = (_shader) => {
        _shader.fragmentShader = _shader.fragmentShader
            .replace('#include <tonemapping_fragment>', append(FRAGMENT))
            .replace('#include <common>', append(UNIFORMS_FRAGMENT))

        addUniforms(_shader)
    }
}

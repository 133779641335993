import React, { useEffect, useState } from 'react'
import { MqTablet } from 'streamr-ui/css/consts'
import styled, { keyframes } from 'styled-components'

function pluralize(thing, count) {
    return `${thing}${(count !== 1 || '') && 's'}`
}

function DefaultChildren(_, countdown) {
    return countdown
}

export function Countdown({ targetDate, children = DefaultChildren }) {
    const [days, hours, minutes, done] = useCountdown(targetDate)

    const showDays = days > 0

    const showHours = showDays || hours > 0

    const shortMinutes = !showDays && !showHours && minutes === 1

    return children(
        done,
        <>
            <Remaining>
                {showDays && (
                    <>
                        <em>
                            {days} {pluralize('day', days)}
                        </em>{' '}
                        :{' '}
                    </>
                )}
                {showHours && (
                    <>
                        <em>
                            {hours} {pluralize('hour', hours)}
                        </em>{' '}
                        :{' '}
                    </>
                )}
                <em>
                    {shortMinutes ? (
                        <>less than a minute</>
                    ) : (
                        <>
                            {minutes} {pluralize('minute', minutes)}
                        </>
                    )}
                </em>
            </Remaining>
        </>,
    )
}

const fadeInOut = keyframes`
    0% {
        color: rgba(255, 255, 255, 1);
    }

    50% {
        color: rgba(255, 255, 255, 0.9);
    }

    51% {
        color: rgba(255, 255, 255, 0.35);
    }

   100% {
        color: rgba(255, 255, 255, 0);
    }
`

export const CountdownRoot = styled.a`
    align-items: center;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    color: white !important;
    display: flex;
    font-size: 14px;
    padding: 12px 18px;
    position: relative;
    text-align: center;
    text-decoration: none !important;
    transition: 1s background-color;
    user-select: none;
    width: max-content;

    p {
        line-height: 22px;
        margin: 0;
    }

    em {
        color: white;
        font-style: normal;
    }

    :hover {
        background-color: rgba(255, 255, 255, 0.08);
        transition-duration: 100ms;
    }

    @media ${MqTablet} {
        padding: 9px 18px;
    }
`

const Remaining = styled.span`
    animation: 2s infinite ${fadeInOut} linear;
    display: block;
    font-weight: 600;

    @media ${MqTablet} {
        display: inline;
        margin-left: 12px;
    }
`

const DayInSeconds = 86400

const HourInSeconds = 3600

export function useCountdown(targetDate) {
    const [{ days: d, hours: h, minutes: m }, setRemaining] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
    })

    useEffect(() => {
        let mounted = true

        let timeout

        function tick() {
            let diffSeconds = Math.ceil(
                Math.max(0, new Date(targetDate).getTime() - Date.now()) / 1000,
            )

            const lastTick = diffSeconds === 0

            const days = Math.floor(diffSeconds / DayInSeconds)

            diffSeconds = diffSeconds - days * DayInSeconds

            const hours = Math.floor(diffSeconds / HourInSeconds)

            diffSeconds = diffSeconds - hours * HourInSeconds

            const minutes = Math.floor(diffSeconds / 60)

            if (!mounted) {
                return
            }

            setRemaining({
                days,
                hours,
                minutes,
            })

            if (!lastTick) {
                timeout = setTimeout(tick, 1000)
            }
        }

        tick()

        return () => {
            mounted = false

            if (typeof timeout !== 'undefined') {
                clearTimeout(timeout)

                timeout = undefined
            }
        }
    }, [targetDate])

    return [d, h, m, d + h + m === 0]
}

import React from 'react'
import styled from 'styled-components'
import SocialLink, { lineup } from './SocialLink'
import { MqDesktop, MqTablet } from 'streamr-ui/css/consts'

export default function SocialChannels() {
    return (
        <Root>
            {lineup.map((k) => (
                <li key={k} $appear>
                    <SocialLink channelKey={k} />
                </li>
            ))}
        </Root>
    )
}

const Root = styled.ul`
    align-items: center;
    background: white;
    border-radius: 1rem;
    display: grid;
    gap: 0 1rem;
    grid-template-columns: repeat(2, 1fr);
    list-style: none;
    margin: 0;
    padding: 0.75rem 1rem;

    li > * {
        margin: 0 auto;
    }

    @media (min-width: 304px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media ${MqTablet} {
        grid-template-columns: repeat(6, 1fr);
        height: 5.5rem;
        padding: 0 2rem;
    }

    @media ${MqDesktop} {
        box-sizing: border-box;
        height: 120px;
        margin-left: auto;
        margin-right: auto;
        width: 1160px;
    }
`

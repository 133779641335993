import { MqTablet } from 'streamr-ui/css/consts'
import styled from 'styled-components'

export const LearnStreamrColumn = styled.div`
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 100%;
    padding: 0 2rem;
    width: 26rem;

    @media (min-width: 30rem) {
        width: 36rem;
        padding: 0 4rem;
    }

    @media ${MqTablet} {
        width: 75.5rem;
        padding: 0 1.5rem;
    }
`

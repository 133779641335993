/**
 * @author simppafi / http://www.simppa.fi/
 *
 * Copy Shader
 */

const CopyShader = {
    uniforms: {
        tColor: {
            value: null,
        },
    },
    vertexShader: `
      varying vec2 vUv;

      void main() {
        vUv = uv;
        gl_Position = vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      uniform sampler2D tColor;

      varying vec2 vUv;

      void main() {
        gl_FragColor = texture2D(tColor, vUv);
      }
    `,
}

export default CopyShader

import React from 'react'
import styled, { keyframes } from 'styled-components'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'
import Display from '~shared/Display'

const fadeInOut = keyframes`
    0% {
        opacity: 0;
        transform: translate(-50%, 6px);
    }

    25% {
        opacity: 1;
        transform: translate(-50%, 0);
    }

    75% {
        opacity: 1;
        transform: translate(-50%, 0);
    }

    100% {
        opacity: 0;
        transform: translate(-50%, 6px);
    }
`

const EncouragerAttrs = () => ({
    children: (
        <>
            <Display mobile desktop="none">
                Tap a node to start exploring
            </Display>
            <Display mobile="none" desktop>
                Click a node to start exploring
            </Display>
        </>
    ),
})

const InteractionEncourager = styled.div.attrs(EncouragerAttrs)`
    position: absolute;
    top: 142px;
    left: 50%;
    transform: translate(-50%, 0);
    background: rgba(50, 50, 50, 0.35);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    font-size: 0.875rem;
    color: #ffffff;
    padding: 0.5rem 1.5rem;
    white-space: nowrap;
    min-width: 232px;
    text-align: center;
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-name: ${fadeInOut};
    pointer-events: none;

    @media ${MqTablet} {
        top: 72px;
    }

    @media ${MqDesktop} {
        top: 72px;
    }

    @media (min-width: 1470px) {
        top: 20px;
    }
`

export default InteractionEncourager

const localStorage =
    typeof window === 'undefined'
        ? {
              getItem() {},
              setItem() {},
              removeItem() {},
          }
        : window.localStorage

export function lsGet(key, defaultValue) {
    const res = localStorage.getItem(key)

    if (res == null) {
        return defaultValue
    }

    return res
}

export function lsSet(key, value) {
    if (typeof value === 'undefined') {
        localStorage.removeItem(key)
        return
    }

    localStorage.setItem(key, value)
}

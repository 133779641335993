import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import Header, { ImageWrap, TextWrap } from './Header'
import Item, { List } from './Item'
import magnifier from './magnifier.png'
import magnifier2x from './magnifier@2x.png'
import { Link } from 'streamr-ui'

function ExternalLink({ node, ...props }) {
    return <Link {...props} blank />
}

function Md({ children }) {
    return (
        <ReactMarkdown
            components={{
                a: ExternalLink,
            }}
        >
            {children}
        </ReactMarkdown>
    )
}

function UnstyledFaq({ header, ...props }) {
    return (
        <div {...props}>
            <div>
                {header && (
                    <Header $appear>
                        <div>
                            <TextWrap>
                                <h2 $appear>Frequently Asked Questions</h2>
                            </TextWrap>
                            <ImageWrap>
                                <img $appear src={magnifier} srcSet={`${magnifier2x} 2x`} alt="" />
                            </ImageWrap>
                        </div>
                    </Header>
                )}
                <List $appearIntersection>{props.children}</List>
            </div>
        </div>
    )
}

const Faq = styled(UnstyledFaq)`
    box-sizing: border-box;
    color: #0c009a;
    padding: 0 18px;

    > div {
        max-width: 704px;
        margin: 0 auto;
    }

    @media (min-width: 768px) {
        padding: 0 32px;

        > div {
            max-width: 896px;
        }
    }

    @media (min-width: 1216px) {
        padding: 0 160px;

        > div {
            box-sizing: border-box;
            display: flex;
            max-width: 1160px;
        }
    }
`

export { Faq as default, Item as FaqItem, Md as FaqMarkdown }

import React from 'react'
import styled, { keyframes } from 'styled-components/macro'

const animation = keyframes`
    0% {
        transform: translateX(-100%) translateZ(0);
    }

    33% {
        transform: translateX(0%) translateZ(0);
    }

    67% {
        transform: translateX(0%) translateZ(0);
    }

    100% {
        transform: translateX(100%) translateZ(0);
    }
`

const LoadingBar = styled.div`
    width: 100%;
    height: ${({ theme }) => (theme.large ? '4' : '1')}px;
    background-color: transparent;
    will-change: opacity;
    transition: opacity 0.3s ease-out;
    opacity: ${({ theme }) => (theme.loading ? '1' : '0')};

    ::after {
        content: '';
        display: block;
        height: ${({ theme }) => (theme.large ? '4' : '1')}px;
        background: #0c009a;
        position: absolute;
        animation: 1200ms infinite;
        animation-name: ${animation};
        width: 100%;
    }
`

const UnstyledLoadingIndicator = ({ loading, large, className, ...props }) => (
    <div className={className}>
        <LoadingBar
            {...props}
            theme={{
                loading: !!loading,
                large: !!large,
            }}
        />
    </div>
)

const LoadingIndicator = styled(UnstyledLoadingIndicator)`
    overflow: hidden;
    position: relative;
    width: 100%;
`

export default LoadingIndicator

import { BufferGeometry, BufferAttribute, Vector3, Vector4 } from 'three'

export class Attribute {
    constructor(name, value = new Vector4(0, 0, 0, 0)) {
        this.name = name
        this.value = value
    }
}

const getUVFromSphere = (v) => {
    const va = v.normalize()
    return [0.5 + Math.atan2(va.z, va.x) / (Math.PI * 2.0), 0.5 - Math.asin(va.y) / Math.PI]
}

const addAttributes = (attributes, geometry, size) => {
    for (let j = 0; j < attributes.length; j++) {
        const attrVertices = new Float32Array(size * 4)

        for (let i = 0, v = 0; i < attributes.length; i++, v = i * 4) {
            attrVertices[v] = attributes[i].value.x
            attrVertices[v + 1] = attributes[i].value.y
            attrVertices[v + 2] = attributes[i].value.z
            attrVertices[v + 3] = attributes[i].value.w
        }

        geometry.setAttribute(attributes[j].name, new BufferAttribute(attrVertices, 4))
    }
}

export function getIcosphereGeometry(
    uvScale = 1.0,
    uvPosX = 0.0,
    uvPosY = 0.0,
    attributes = null,
    recursionLevel = 0,
    modify = null,
) {
    const scaleUVPosX = uvPosX * uvScale

    const scaleUVPosY = uvPosY * uvScale

    const cache = {}

    let index = 0

    const verts = []

    let faces = []

    const addVertex = (p) => {
        const pt = p.normalize()
        verts[index] = pt
        return index++
    }

    // return index of point in the middle of p1 and p2
    const getMiddlePoint = (p1, p2) => {
        // first check if we have it already
        const [smallerIndex, greaterIndex] = p1 < p2 ? [p1, p2] : [p2, p1]
        const key = (smallerIndex << 16) + greaterIndex // eslint-disable-line no-bitwise

        if (key in cache) {
            return cache[key]
        }

        const point1 = verts[p1]
        const point2 = verts[p2]

        const middle = new Vector3(
            (point1.x + point2.x) / 2.0,
            (point1.y + point2.y) / 2.0,
            (point1.z + point2.z) / 2.0,
        )

        // add vertex makes sure point is on unit sphere
        const vIndex = addVertex(middle)

        // store it, return index
        cache[key] = vIndex

        return vIndex
    }

    const t = (1.0 + Math.sqrt(5.0)) / 2.0

    addVertex(new Vector3(-1.0, t, 0.0))
    addVertex(new Vector3(1.0, t, 0.0))
    addVertex(new Vector3(-1.0, -t, 0.0))
    addVertex(new Vector3(1.0, -t, 0.0))

    addVertex(new Vector3(0.0, -1.0, t))
    addVertex(new Vector3(0.0, 1.0, t))
    addVertex(new Vector3(0.0, -1.0, -t))
    addVertex(new Vector3(0.0, 1.0, -t))

    addVertex(new Vector3(t, 0.0, -1.0))
    addVertex(new Vector3(t, 0.0, 1.0))
    addVertex(new Vector3(-t, 0.0, -1.0))
    addVertex(new Vector3(-t, 0.0, 1.0))

    // 5 faces around point 0
    faces.push(new Vector3(0, 11, 5))
    faces.push(new Vector3(0, 5, 1))
    faces.push(new Vector3(0, 1, 7))
    faces.push(new Vector3(0, 7, 10))
    faces.push(new Vector3(0, 10, 11))

    // 5 adjacent faces
    faces.push(new Vector3(1, 5, 9))
    faces.push(new Vector3(5, 11, 4))
    faces.push(new Vector3(11, 10, 2))
    faces.push(new Vector3(10, 7, 6))
    faces.push(new Vector3(7, 1, 8))

    // 5 faces around point 3
    faces.push(new Vector3(3, 9, 4))
    faces.push(new Vector3(3, 4, 2))
    faces.push(new Vector3(3, 2, 6))
    faces.push(new Vector3(3, 6, 8))
    faces.push(new Vector3(3, 8, 9))

    // 5 adjacent faces
    faces.push(new Vector3(4, 9, 5))
    faces.push(new Vector3(2, 4, 11))
    faces.push(new Vector3(6, 2, 10))
    faces.push(new Vector3(8, 6, 7))
    faces.push(new Vector3(9, 8, 1))

    // refine triangles
    for (let i = 0; i < recursionLevel; i++) {
        const faces2 = []

        for (let j = 0; j < faces.length; j++) {
            const tri = faces[j]

            // replace triangle by 4 triangles
            const a = getMiddlePoint(tri.x, tri.y)
            const b = getMiddlePoint(tri.y, tri.z)
            const c = getMiddlePoint(tri.z, tri.x)

            faces2.push(new Vector3(tri.x, a, c))
            faces2.push(new Vector3(tri.y, b, a))
            faces2.push(new Vector3(tri.z, c, b))
            faces2.push(new Vector3(a, b, c))
        }

        faces = faces2
    }

    const indices = []
    for (let i = 0; i < faces.length; i++) {
        indices.push(faces[i].x, faces[i].y, faces[i].z)
    }

    const geometry = new BufferGeometry()
    let vertices = new Float32Array(verts.length * 3)
    const uvs = new Float32Array(verts.length * 2)

    for (let i = 0, u = 0, v = 0; i < verts.length; i++, u = i * 2, v = i * 3) {
        vertices[v + 0] = verts[i].x // * 2.0;
        vertices[v + 1] = verts[i].y // * 2.0;
        vertices[v + 2] = verts[i].z // * 2.0;

        const uv = getUVFromSphere(verts[i])
        uvs[u + 0] = scaleUVPosX + uv[0] * uvScale
        uvs[u + 1] = scaleUVPosY + uv[1] * uvScale
    }

    if (modify) {
        vertices = modify(vertices)
    }

    geometry.setAttribute('position', new BufferAttribute(vertices, 3))

    if (attributes) {
        addAttributes(attributes, geometry, verts.length)
    }

    geometry.setAttribute('normal', new BufferAttribute(vertices, 3))
    geometry.setAttribute('uv', new BufferAttribute(uvs, 2))
    geometry.setIndex(indices)

    return geometry
}

export function getQuadGeometry(
    uvScale = 1.0,
    uvPosX = 0.0,
    uvPosY = 0.0,
    attributes = null,
    vertical = true,
    modify = null,
) {
    const scaleUVPosX = uvPosX * uvScale
    const scaleUVPosY = uvPosY * uvScale

    const p = [
        [-1, vertical ? -1 : 0, !vertical ? -1 : 0],
        [1, vertical ? -1 : 0, !vertical ? -1 : 0],
        [1, vertical ? 1 : 0, !vertical ? 1 : 0],
        [-1, vertical ? 1 : 0, !vertical ? 1 : 0],
    ]

    const puv = [
        [0, 0],
        [1, 0],
        [1, 1],
        [0, 1],
    ]

    const geometry = new BufferGeometry()
    let vertices = new Float32Array(p.length * 3)
    const uvs = new Float32Array(puv.length * 2)

    for (let i = 0, u = 0, v = 0; i < 4; i++, u = i * 2, v = i * 3) {
        vertices[v + 0] = p[i][0]
        vertices[v + 1] = p[i][1]
        vertices[v + 2] = p[i][2]

        uvs[u + 0] = scaleUVPosX + puv[i][0] * uvScale
        uvs[u + 1] = scaleUVPosY + puv[i][1] * uvScale
    }

    if (modify) {
        vertices = modify(vertices)
    }

    const indices = [0, 1, 2, 0, 2, 3, 0, 3, 1, 2, 1, 3]

    geometry.setAttribute('position', new BufferAttribute(vertices, 3))

    if (attributes) {
        addAttributes(attributes, geometry, p.length)
    }

    geometry.setAttribute('normal', new BufferAttribute(vertices, 3))
    geometry.setAttribute('uv', new BufferAttribute(uvs, 2))
    geometry.setIndex(indices)

    return geometry
}

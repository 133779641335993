const START_DELAY = 5

export default function QA(minQuality = 0, maxQuality = 3, callback, _interval) {
    let deltaCount = 0

    let deltaSum = 0

    let takeTime = 10

    let interval = _interval

    let quality = maxQuality

    const heavyQualities = []

    const adjust = (fps) => {
        if (fps < 30 && quality > minQuality) {
            heavyQualities[quality] = true
            callback(--quality)
        } else if (fps > 50 && quality < maxQuality) {
            if (!heavyQualities[quality + 1]) {
                callback(++quality)
            }
        }
    }

    this.check = (time, delta) => {
        if (time > START_DELAY) {
            if (time > takeTime + interval) {
                const averageDelta = deltaSum / deltaCount
                const fps = Math.round(1.0 / averageDelta)

                adjust(fps)

                takeTime = time
                deltaCount = 0
                deltaSum = 0

                if (interval < 20) {
                    interval++
                }
            }

            deltaCount++
            deltaSum += delta
        } else {
            takeTime = time
        }
    }
}

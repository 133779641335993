import { useEffect, useState } from 'react'
import { NetworkSubgraphUrl, StreamrIndexerUrl } from '../consts'
import { getStakingStats, q } from '../utils/graphs'

export function useGlobalApy() {
    const [apy, setApy] = useState()

    useEffect(function getApy() {
        let mounted = true

        void (async () => {
            try {
                const { apy: result } = await getStakingStats()

                if (mounted) {
                    setApy(result.toFixed(2))
                }
            } catch (e) {
                console.warn('Unable to calculate global average APY', e)

                if (mounted) {
                    setApy(null)
                }
            }
        })()

        return () => {
            mounted = false
        }
    }, [])

    return apy
}

export function useNetworkSummary() {
    const [summary, setSummary] = useState()

    useEffect(function fetchNetworkSummary() {
        let mounted = true

        void (async () => {
            try {
                const { data } = await q(StreamrIndexerUrl, {
                    query: `
                        query GetNetworkSummary {
                            summary {
                                messagesPerSecond
                                bytesPerSecond
                                nodeCount
                            }
                        }
                    `,
                })

                if (mounted) {
                    setSummary(data.summary)
                }
            } catch (e) {
                console.warn('Unable to fetch global node count', e)

                if (mounted) {
                    setSummary(null)
                }
            }
        })()

        return () => {
            mounted = false
        }
    }, [])

    return summary
}

export function useGlobalOperatorCount() {
    const [operatorCount, setOperatorCount] = useState()

    useEffect(function fetchOperatorCount() {
        let mounted = true

        void (async () => {
            try {
                const operatorCount = await q(NetworkSubgraphUrl, {
                    query: `
                        query getNetworkStats {
                            networks(first: 1) {
                                operatorsCount
                            }
                        }
                    `,
                    transform: (x) => {
                        return Number(x.data.networks[0].operatorsCount)
                    },
                })

                if (mounted) {
                    setOperatorCount(operatorCount)
                }
            } catch (e) {
                console.warn('Failed to fetch global operator count', e)
            }
        })()

        return () => {
            mounted = false
        }
    }, [])

    return operatorCount
}

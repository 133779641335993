import React from 'react'
import { useSwipeable } from 'react-swipeable'
import { Medium, MqTablet } from 'streamr-ui/css/consts'
import styled from 'styled-components'
import PlayButton, { DefaultTheme } from '~shared/PlayButton'
import Topic from '~shared/Topic'
import preview from '../assets/preview.png'
import preview2x from '../assets/preview@2x.png'
import gridPattern from '../assets/grid.png'

const PlayButtonTheme = {
    ...DefaultTheme,
    size: '40px',
    mobileSize: '40px',
    position: 'bottomRight',
}

export default function LearnStreamrSlide({
    url = 'https://youtube.com/',
    duration = '0:00',
    category = 'Uncategorized',
    title = 'Untitled slide',
    numViews = '0 views',
    publishedAt = 'Just now',
}) {
    return (
        <Root href={url} target="_blank" rel="noopener noreferrer" $appear>
            <Head>
                <Duration>{duration}</Duration>
                <img src={preview} srcSet={`${preview2x} 2x`} alt="Preview" />
                <PlayButton monolith theme={PlayButtonTheme} contained />
            </Head>
            <Body>
                <p>{category}</p>
                <h2>{title}</h2>
                <p>
                    {numViews} • {publishedAt}
                </p>
            </Body>
        </Root>
    )
}

const Root = styled.a`
    border-radius: 16px;
    display: block;
    overflow: hidden;
    text-decoration: none !important;
`

const Head = styled.div`
    height: 11.5rem;
    background: #eeeeee;
    position: relative;

    img {
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }
`

const Body = styled.div`
    background: white;
    min-height: 4rem;
    padding: 1.75rem;

    p {
        color: #525252;
        font-size: 0.875rem;
        letter-spacing: 0.5px;
        line-height: normal;
        margin: 0;
    }

    h2 {
        font-size: 1.375rem;
        font-weight: ${Medium};
        letter-spacing: 0.3px;
        line-height: 1.75rem;
        margin: 0.875rem 0;
        min-height: 3.5rem;
    }
`

const Duration = styled.div`
    align-items: center;
    background: rgba(37, 36, 48, 0.5);
    border-radius: 0.375rem;
    color: white;
    display: flex;
    font-size: 0.875rem;
    height: 1.5rem;
    left: 0.75rem;
    line-height: normal;
    padding: 0 0.5rem;
    position: absolute;
    top: 0.75rem;
    user-select: none;
    width: max-content;
`

const SliderRoot = styled.ul`
    display: grid;
    gap: 1.5rem;
    list-style: none;
    margin: 0;
    padding: 0;
    transition: 250ms ease-in-out transform;
    max-width: calc((1160px - 48px) / 3);
    width: 100%;

    @media ${MqTablet} {
        width: calc((1160px - 48px) / 3);
    }
`

export function LearnStreamrSlider({ slideNo = 2, children, onSwipedLeft, onSwipedRight }) {
    const n = React.Children.count(children)

    const handlers = useSwipeable({
        onSwipedLeft,
        onSwipedRight,
    })

    return (
        <SliderRoot
            style={{
                gridTemplateColumns: `repeat(${n}, 100%)`,
                transform: `translateX(calc(${-slideNo} * 100% - (${slideNo} * 24px)))`,
            }}
            {...handlers}
        >
            {React.Children.map(children, (child) => (
                <li>{child}</li>
            ))}
        </SliderRoot>
    )
}

export const LearnStreamrTopic = styled(Topic)`
    background: url(${gridPattern}) repeat,
        linear-gradient(101.57deg, #140f88 5.81%, #571cad 100.7%);
`

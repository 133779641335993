import { AnimationMixer } from 'three'
import gsap from 'gsap'
import { withAssetPrefix } from 'gatsby'
import { GLTFLoader } from 'three-stdlib/loaders/GLTFLoader'
import { POINT_SCALE as SCALE } from './Particles'
import NodeSelectMaterial from './material/NodeSelectMaterial'

const POS_X = 0.2

const POS_Y = 0.0125

const POS_Z = -1.0

const ROT_X = 0.0

const ROT_Y = 0.0

const ROT_Z = 0.0

export default function NodeHighlightAnimation() {
    let tween

    const material = new NodeSelectMaterial()

    this.getMaterial = () => material

    this.setColor = (newColor) => {
        material.setColor(newColor)
        material.needsUpdate = true
    }

    let display = null

    let duration = 0

    let mixer = null

    this.load = () =>
        new Promise((resolve, reject) => {
            new GLTFLoader().load(
                withAssetPrefix('/models/node_opening.glb'),
                ({ scene, animations: [animation] }) => {
                    if (display) {
                        reject(new Error('Node highlight animation already loaded.'))
                    }

                    scene.scale.set(SCALE, SCALE, SCALE)
                    scene.position.set(POS_X, 0.1, POS_Z)
                    scene.rotation.set(ROT_X, ROT_Y, ROT_Z)

                    material.castShadow = false
                    material.receiveShadow = false
                    material.skinning = true

                    scene.children[0].children[4].material = material

                    mixer = new AnimationMixer(scene)

                    const action = mixer.clipAction(animation)
                    action.enabled = true
                    action.play()

                    display = scene
                    duration = animation.duration
                    display.visible = false

                    resolve(scene)
                },
                null,
                reject,
            )
        })

    const killMovement = () => {
        if (tween) {
            tween.kill()
            tween = null
        }
    }

    this.show = ({ x, z }) => {
        if (!display) {
            throw new Error('Not ready.')
        }

        display.position.set(x, POS_Y, z)
        display.visible = true
        display.scale.set(SCALE, SCALE, SCALE)

        killMovement()

        const obj = {
            animation: 0,
            scale: SCALE,
        }

        tween = gsap.to(obj, {
            duration: 1,
            ease: 'expo.inout',
            animation: 0.999,
            scale: SCALE + 0.002,
            onUpdate: () => {
                display.scale.set(obj.scale, obj.scale, obj.scale)
                mixer.setTime(duration * obj.animation)
            },
        })
    }

    this.hide = () => {
        if (!display) {
            throw new Error('Not ready.')
        }

        killMovement()

        const obj = {
            animation: 1,
            scale: SCALE,
        }

        tween = gsap.to(obj, {
            duration: 0.5,
            ease: 'quad.out',
            animation: 0,
            scale: SCALE - 0.002,
            onUpdate: () => {
                display.scale.set(obj.scale, obj.scale, obj.scale)
                mixer.setTime(duration * obj.animation)
            },
            onComplete: () => {
                display.visible = false
            },
        })
    }
}

import styled from 'styled-components'
import { MqTablet, MqDesktop } from 'streamr-ui/css/consts'

const Container = styled.div`
    margin: 0 auto;
    padding: 0 2rem;

    @media ${MqTablet} {
        max-width: 40rem;
    }

    @media ${MqDesktop} {
        max-width: 69rem;
    }
`

export default Container

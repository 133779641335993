import { ShaderMaterial } from 'three'
import DotsShader from '../shaders/DotsShader'

// eslint-disable-next-line import/no-unused-modules
export default function Dots({ fsQuad, renderer }) {
    const uniforms = DotsShader.getUniforms()

    const material = new ShaderMaterial({
        vertexShader: DotsShader.vertexShader,
        fragmentShader: DotsShader.fragmentShader,
        uniforms,
        transparent: true,
    })

    this.setScreenSize = (newSize) => {
        uniforms.u_resolution.value = newSize
    }

    this.setColor = (color) => {
        uniforms.u_color.value.x = color.r
        uniforms.u_color.value.y = color.g
        uniforms.u_color.value.z = color.b
    }

    let intensity = 0.85

    this.setIntensity = (newIntensity) => {
        intensity = newIntensity
    }

    this.setSize = (size) => {
        uniforms.u_dot_size.value = size
    }

    this.setGlitterSpeed = (speed) => {
        uniforms.u_glitter_speed.value = speed
    }

    this.setGlitterIntensity = (newIntensity) => {
        uniforms.u_glitter_intensity.value = newIntensity
    }

    let tileSize = 120

    this.setTileSize = (size) => {
        tileSize = size
    }

    this.render = (phase, time, { coverage = 0.8, curvature = 0.12 } = {}) => {
        uniforms.u_tile_size.value = (1 - (1 - phase) * 0.1) * tileSize

        uniforms.u_color.value.w = (1 - Math.sqrt(1 - phase ** 2)) * intensity

        uniforms.u_time.value = time

        const { x, y } = uniforms.u_resolution.value

        uniforms.u_offset.value = phase * coverage * y

        uniforms.u_height.value = phase * curvature * x * 0.5

        fsQuad.material = material

        fsQuad.render(renderer)
    }
}

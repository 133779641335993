import React from 'react'
import styled, { css } from 'styled-components'

const Gap = 0.75

export default function NewSliderNav({ current = 0, continuous = false, size = 3, onClick }) {
    return (
        <Root>
            <Cart $size={size} $continuous={continuous}>
                <div
                    style={{
                        transform: `translateX(${current * (continuous ? 0 : Gap)}rem) translateX(${
                            current * 100
                        }%)`,
                    }}
                />
            </Cart>
            <Track $size={size} $continuous={continuous}>
                {[...Array(size)].map((_, i) => (
                    <button key={`${size}:${i}`} type="button" onClick={() => onClick?.(i)} />
                ))}
            </Track>
        </Root>
    )
}

export const Root = styled.div`
    border-radius: 3px;
    height: 6px;
    overflow: hidden;
    position: relative;
`

const Grid = styled.div`
    height: 100%;
    display: grid;
    gap: ${Gap}rem;
    grid-template-columns: repeat(${({ $size = 3 }) => $size}, 1fr);
    width: 100%;

    ${({ $continuous = false }) =>
        $continuous &&
        css`
            gap: 0;
        `}
`

const Cart = styled(Grid)`
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    > div {
        background: #0324ff;
        border-radius: 3px;
        height: 100%;
        transition: 350ms transform ease-in-out;
    }
`

const Track = styled(Grid)`
    button {
        appearance: none;
        background: #0324ff;
        border: 0;
        border-radius: 3px;
        height: 100%;
        opacity: 0.1;
        outline: 0;
    }

    ${({ $continuous = false }) =>
        $continuous &&
        css`
            background: #0324ff;
            opacity: 0.1;

            button {
                background: transparent;
                opacity: 1;
            }
        `}
`

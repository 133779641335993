/**
 * @author simppafi / http://www.simppa.fi/
 *
 * Render pipeline containing glow, tiltshift bokeh, reflection and rendered with hardware antialiasing using material shader injections.
 */

import { Vector2, ShaderMaterial, UniformsUtils, Vector4 } from 'three'
import { FullScreenQuad } from 'three-stdlib/postprocessing/Pass'
import GlowMaterial from '../material/GlowMaterial'
import getTiltTexture from '../utils/getTiltTexture'
import getRenderTarget from '../utils/getRenderTarget'
import CopyShader from './CopyShader'
import BokehTiltShiftShader from './BokehTiltShiftShader'
import GaussianBlurShader from './GaussianBlurShader'

const HALF = 0.5

const QUAD = 0.25

export default function RenderPipeline(
    renderer,
    scene,
    sceneMirror,
    camera,
    {
        _bokeh = true,
        _glow = true,
        _reflect = true,
        fullBlur = 0.0,
        glowBlurValue = 1.0,
        glowOffset = new Vector2(1.0, 1.0),
        glowScale = 1.0,
        glowStrength = 1.0,
        offsetTilt = new Vector4(1.1, 1.0, 1.1, 1.0),
        radius = 4.5,
        shift = new Vector4(4.0, 1000.0, 4.0, 1000.0),
    } = {},
) {
    let reflect = _reflect
    let bokeh = _bokeh
    let glow = _glow

    const finalUniforms = []

    let screenSize = new Vector2(2, 2)

    // GLOW
    const blurUniforms = UniformsUtils.clone(GaussianBlurShader.uniforms)

    blurUniforms.u_offset.value = glowOffset
    blurUniforms.u_strength.value = glowStrength

    let blurUniformsOffset = new Vector2(1, 1)

    const materialBlur = new ShaderMaterial({
        defines: Object.assign({}, GaussianBlurShader.defines), // eslint-disable-line prefer-object-spread
        uniforms: blurUniforms,
        vertexShader: GaussianBlurShader.vertexShader,
        fragmentShader: GaussianBlurShader.fragmentShader,
    })

    const renderTargetBlur = getRenderTarget('BlurTexture')

    const renderTargetGlow = getRenderTarget('GlowTexture')

    const glowOverrideMaterial = new GlowMaterial()

    glowOverrideMaterial.setGlowScale(glowScale)

    // BOKEH
    const bokehUniforms = UniformsUtils.clone(BokehTiltShiftShader.uniforms)

    bokehUniforms.u_radius.value = radius
    bokehUniforms.u_offset.value = offsetTilt
    bokehUniforms.u_shift.value = shift
    bokehUniforms.u_full_blur.value = fullBlur
    bokehUniforms.u_aspect.value = camera.aspect
    bokehUniforms.u_tilt.value = getTiltTexture(renderer, {
        fullBlur,
        offsetTilt,
        shift,
    })

    const materialBokeh = new ShaderMaterial({
        defines: Object.assign({}, BokehTiltShiftShader.defines), // eslint-disable-line prefer-object-spread
        uniforms: bokehUniforms,
        vertexShader: BokehTiltShiftShader.vertexShader,
        fragmentShader: BokehTiltShiftShader.fragmentShader,
    })

    // COPY THRESHOLD
    const copyUniforms = UniformsUtils.clone(CopyShader.uniforms)

    const materialCopy = new ShaderMaterial({
        defines: Object.assign({}, CopyShader.defines), // eslint-disable-line prefer-object-spread
        uniforms: copyUniforms,
        vertexShader: CopyShader.vertexShader,
        fragmentShader: CopyShader.fragmentShader,
    })

    const renderTargetCopy = getRenderTarget('CopyTexture')

    const renderTargetHalf = getRenderTarget('HalfTexture')

    const renderTargetMirror = getRenderTarget('MirrorTexture')

    const renderTargetBokeh = getRenderTarget('BokehTexture')

    const fsQuad = new FullScreenQuad()

    renderer.autoClear = false

    renderer.setRenderTarget(null)
    renderer.clear(0xffffff)
    renderer.render(scene, camera)

    this.render = () => {
        if (glow) {
            scene.overrideMaterial = glowOverrideMaterial
            renderer.setRenderTarget(renderTargetBlur)
            renderer.clear(
                renderer.autoClearColor,
                renderer.autoClearDepth,
                renderer.autoClearStencil,
            )
            renderer.render(scene, camera)
            scene.overrideMaterial = null

            // CREATE SMALL COPY OF FULL FRAME
            fsQuad.material = materialCopy
            copyUniforms.tColor.value = renderTargetBlur.texture
            renderer.setRenderTarget(renderTargetGlow)
            renderer.clear()
            fsQuad.render(renderer)

            // BLUR DRAW
            blurUniforms.tColor.value = renderTargetGlow.texture
            // GLOW
            fsQuad.material = materialBlur
            const offsetX = blurUniformsOffset.x
            const offsetY = blurUniformsOffset.y
            const steps = 2
            for (let i = 0; i < steps; i++) {
                blurUniforms.u_offset.value.x = offsetX * glowBlurValue
                blurUniforms.u_offset.value.y = 0.0
                blurUniforms.tColor.value = renderTargetGlow.texture
                renderer.setRenderTarget(renderTargetBlur)
                renderer.clear()
                fsQuad.render(renderer)

                blurUniforms.u_offset.value.x = 0.0
                blurUniforms.u_offset.value.y = offsetY * glowBlurValue
                blurUniforms.tColor.value = renderTargetBlur.texture
                renderer.setRenderTarget(renderTargetGlow)
                renderer.clear()
                fsQuad.render(renderer)
            }
        }

        // REFLECTION
        if (reflect) {
            renderer.setRenderTarget(renderTargetMirror)
            renderer.clear()
            renderer.render(sceneMirror, camera)
        }

        if (bokeh) {
            // Render scene in half size
            for (let i = 0; i < finalUniforms.length; i++) {
                finalUniforms[i].u_final.value = false
            }
            renderer.setRenderTarget(renderTargetHalf)
            renderer.clear()
            renderer.render(scene, camera)

            bokehUniforms.u_aspect.value = camera.aspect
            bokehUniforms.tColor.value = renderTargetHalf.texture
            fsQuad.material = materialBokeh
            renderer.setRenderTarget(renderTargetBokeh)
            renderer.clear()
            fsQuad.render(renderer)
        }

        // FULL RESOLUTION GPU ANTIALIAS DRAW
        for (let i = 0; i < finalUniforms.length; i++) {
            finalUniforms[i].u_final.value = true
        }
        renderer.setRenderTarget(null)
        renderer.clear()
        renderer.render(scene, camera)
    }

    this.setQuality = (level) => {
        ;[bokeh = true, glow = true, reflect = true] = [
            /* 0 */ [false, false, false],
            /* 1 */ [false, true, false],
            /* 2 */ [false, true, true],
        ][level]

        for (let i = 0; i < finalUniforms.length; i++) {
            finalUniforms[i].u_glow.value = glow
            finalUniforms[i].u_bokeh.value = bokeh
            finalUniforms[i].u_reflect.value = reflect
        }
    }

    this.setSize = (width, height) => {
        const wi = width * HALF
        const he = height * HALF

        const wi2 = width * QUAD
        const he2 = height * QUAD

        if (renderTargetHalf) {
            renderTargetHalf.setSize(wi, he)
        }

        if (renderTargetBokeh) {
            renderTargetBokeh.setSize(wi, he)
        }

        if (renderTargetCopy) {
            renderTargetCopy.setSize(wi, he)
        }

        if (renderTargetMirror) {
            renderTargetMirror.setSize(wi2, he2)
        }

        if (renderTargetBlur) {
            renderTargetBlur.setSize(wi2, he2)
        }

        if (renderTargetGlow) {
            renderTargetGlow.setSize(wi2, he2)
        }

        screenSize = new Vector2(width, height)

        for (let i = 0; i < finalUniforms.length; i++) {
            finalUniforms[i].u_screen_size.value.x = width
            finalUniforms[i].u_screen_size.value.y = height
        }

        bokehUniforms.u_resolution.value = new Vector2(wi, he)
        bokehUniforms.u_radius.value = Math.max(1.0, 3.0 - width / 768)

        blurUniformsOffset = new Vector2(1 / wi, 1 / he)
    }

    this.update = (time) => {
        glowOverrideMaterial.setTime(time)
    }

    this.getGlow = () => glow

    this.getReflect = () => reflect

    this.getBokeh = () => bokeh

    this.addUniforms = (uniforms) => {
        finalUniforms.push(uniforms)
    }

    this.getScreenSize = () => screenSize

    this.getGlowTarget = () => renderTargetGlow

    this.getTiltTexture = () => bokehUniforms.u_tilt.value

    this.getMirrorTarget = () => renderTargetMirror

    this.getShift = () => shift

    this.getBokehTarget = () => renderTargetBokeh
}

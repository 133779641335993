import gsap from 'gsap'
import isMobileDevice from './utils/isMobile'
import ViewerHuman from './ViewerHuman'

const INERTIA = 6.0 // less is more

const AREA_TOP = -2

const AREA_BOTTOM = 1.19

const AREA_LEFT = -5

const AREA_RIGHT = 5

export default class Controls {
    mobileZoomOut = null

    mobileZoomIn = null

    desktopZoomOut = null

    desktopZoomIn = null

    moveGotoX = 0

    moveGotoY = 0

    lastX = 0

    lastY = 0

    moveChangeX = 0

    moveChangeY = 0

    defaultZoomOut = null

    defaultZoomIn = null

    zoom = 1.0

    moveDragX = 0

    moveDragY = 0

    dragStarted = false

    isDown = false

    setDefaults = () => {
        ;[this.defaultZoomOut, this.defaultZoomIn] = this.isMobile
            ? [this.mobileZoomOut, this.mobileZoomIn]
            : [this.desktopZoomOut, this.desktopZoomIn]
    }

    moveX = 0.2

    moveY = -0.75

    endX = this.moveX

    endY = this.moveY

    constructor(
        container,
        {
            camera,
            lookAtPosition,
            zoomLevels = [0.9, 0.7, 1.0, 0.8],
            onPointerDown,
            onClick,
            onHover,
            onDragStart,
            onDragEnd,
            onPositionCorrection,
        } = {},
    ) {
        ;[this.mobileZoomOut, this.mobileZoomIn, this.desktopZoomOut, this.desktopZoomIn] =
            zoomLevels
        this.container = container
        this.camera = camera
        this.lookAtPosition = lookAtPosition
        this.onPointerDown = onPointerDown
        this.onClick = onClick
        this.onHover = onHover
        this.onDragStart = onDragStart
        this.onDragEnd = onDragEnd
        this.isMobile = isMobileDevice()
        this.setDefaults()

        this.moveX = lookAtPosition.x

        this.moveY = lookAtPosition.z

        this.humanViewer = new ViewerHuman(container, {
            onHover: ({ x, y }) => {
                if (typeof this.onHover === 'function') {
                    this.onHover({
                        target: this,
                        x,
                        y,
                    })
                }
            },
            onGrab: ({ x, y }) => {
                this.isMobile = isMobileDevice()

                this.setDefaults()

                this.moveChangeX = 0
                this.moveChangeY = 0

                this.killMovement()
                this.killZoomMovement()

                this.endX = this.moveX
                this.endY = this.moveY
                this.lastX = x
                this.lastY = y

                this.container.style.cursor = 'move'

                if (typeof this.onPointerDown === 'function') {
                    this.onPointerDown({
                        target: this,
                        x,
                        y,
                    })
                }
            },
            onDrag: ({ offsetX: dx, offsetY: dy, distance }) => {
                const deltaX = dx * 0.001
                const deltaY = dy * 0.001

                const mul = this.isMobile ? 2.0 : 1.25

                this.moveX = this.endX + deltaX * mul
                this.moveY = this.endY + deltaY * mul

                if (this.dragStarted || distance <= 0.1) {
                    return
                }

                this.dragStarted = true

                if (typeof this.onDragStart === 'function') {
                    this.onDragStart()
                }

                this.killMovement()
                this.killZoomMovement()
            },
            onDrop: (distance) => {
                this.endX = this.moveX
                this.endY = this.moveY

                this.container.style.cursor = ''

                if (typeof this.onClick === 'function' && distance < 50) {
                    this.onClick({
                        target: this,
                        x: this.lastX,
                        y: this.lastY,
                    })
                }

                if (this.dragStarted && typeof this.onDragEnd === 'function') {
                    this.onDragEnd({
                        target: this,
                    })
                }

                this.dragStarted = false

                if (
                    this.moveX < AREA_LEFT ||
                    this.moveX > AREA_RIGHT ||
                    this.moveY < AREA_TOP ||
                    this.moveY > AREA_BOTTOM
                ) {
                    if (typeof onPositionCorrection !== 'function') {
                        return
                    }

                    onPositionCorrection({
                        top: this.moveY < AREA_TOP,
                        right: this.moveX > AREA_RIGHT,
                        bottom: this.moveY > AREA_BOTTOM,
                        left: this.moveX < AREA_LEFT,
                    })
                }
            },
        })
    }

    target = null

    panTween = null

    zoomTween = null

    killMovement() {
        if (this.panTween) {
            this.panTween.kill()
            this.panTween = null
        }
    }

    killZoomMovement() {
        if (this.zoomTween) {
            this.zoomTween.kill()
            this.zoomTween = null
        }
    }

    zoomTo(zoom, duration) {
        this.killZoomMovement()

        const obj = {
            zoom: this.zoom,
        }

        this.zoomTween = gsap.to(obj, {
            duration,
            ease: 'expo.inout',
            zoom,
            onUpdate: () => {
                this.zoom = obj.zoom
            },
        })
    }

    moveTo(point, area, duration = 1, { x: offsetX = 0, y: offsetY = 0 } = {}) {
        const { x, z } = point

        if (area != null) {
            this.zoomTo(this.defaultZoomIn + area * 0.2, duration)
        }

        if (this.target === point) {
            return
        }

        this.target = point
        this.moveChangeX = 0
        this.moveChangeY = 0

        this.killMovement()

        const obj = {
            moveX: this.moveX,
            moveY: this.moveY,
        }

        this.panTween = gsap.to(obj, {
            duration,
            ease: 'expo.inout',
            moveX: x + offsetX,
            moveY: z + offsetY,
            onUpdate: () => {
                this.moveX = obj.moveX
                this.moveY = obj.moveY
            },
        })
    }

    resetZoom() {
        this.zoomTo(this.defaultZoomOut, 1)
    }

    resize() {
        this.setDefaults()
        this.zoom = this.defaultZoomOut
    }

    adjustExtremes(delta) {
        const springDelta = Math.min(delta * 5, 1.0)

        if (this.moveY < AREA_TOP) {
            this.moveY += (AREA_TOP - this.moveY) * springDelta
        } else if (this.moveY > AREA_BOTTOM) {
            this.moveY += (AREA_BOTTOM - this.moveY) * springDelta
        }

        if (this.moveX < AREA_LEFT) {
            this.moveX += (AREA_LEFT - this.moveX) * springDelta
        } else if (this.moveX > AREA_RIGHT) {
            this.moveX += (AREA_RIGHT - this.moveX) * springDelta
        }
    }

    applyDrag() {
        this.moveChangeX = this.moveX - this.moveDragX
        this.moveChangeY = this.moveY - this.moveDragY

        this.moveDragX = this.moveX
        this.moveDragY = this.moveY
    }

    applyIdle(delta) {
        const ease = 1.0 - delta * INERTIA

        this.moveChangeX *= ease
        this.moveChangeY *= ease

        this.moveX += this.moveChangeX
        this.moveY += this.moveChangeY
    }

    isTransitioningInIdle() {
        return Math.abs(this.moveChangeY) > 0.0001 || Math.abs(this.moveChangeX) > 0.0001
    }

    livenessIntensity = 0

    applyLiveness(time) {
        if (this.livenessIntensity === 0) {
            return
        }

        const speed = 10

        this.lookAtPosition.x += Math.sin(time * 0.01 * speed) * 0.1 * this.livenessIntensity

        this.lookAtPosition.z += Math.sin(time * 0.01 * speed * 2) * 0.02 * this.livenessIntensity
    }

    update(time, delta) {
        const isDragging = this.humanViewer.isDragging()

        if (!isDragging) {
            this.adjustExtremes(delta)
        }

        if (isDragging && this.humanViewer.dragged()) {
            this.applyDrag()
        } else if (this.isTransitioningInIdle()) {
            this.applyIdle(delta)
        }

        const dragDelta = Math.min(delta * 50, 1.0)

        this.moveGotoX += (this.moveX - this.moveGotoX) * dragDelta
        this.moveGotoY += (this.moveY - this.moveGotoY) * dragDelta

        this.lookAtPosition.x = this.moveGotoX

        this.lookAtPosition.z = this.moveGotoY

        this.applyLiveness(time)

        this.camera.position.set(
            this.lookAtPosition.x,
            this.lookAtPosition.y + 1.25 * this.zoom,
            this.lookAtPosition.z + 0.75 * this.zoom,
        )

        this.camera.lookAt(this.lookAtPosition)
    }

    livenessTween = undefined

    enableLiveness() {
        if (this.livenessTween) {
            this.livenessTween.kill()
        }

        const obj = {
            val: this.livenessIntensity,
        }

        this.livenessTween = gsap.to(obj, {
            duration: (1 - this.livenessIntensity) * 10,
            ease: 'power2.inOut',
            val: 1.0,
            onUpdate: () => {
                this.livenessIntensity = obj.val
            },
        })
    }

    disableLiveness() {
        if (this.livenessTween) {
            this.livenessTween.kill()
        }

        const obj = {
            val: this.livenessIntensity,
        }

        this.livenessTween = gsap.to(obj, {
            duration: this.livenessIntensity * 2.5,
            ease: 'power2.inOut',
            val: 0.0,
            onUpdate: () => {
                this.livenessIntensity = obj.val
            },
        })
    }
}
